.range {
  .numberDivider {
    width: 20%;
    border-left: 0;
    pointer-events: none;
    background: transparent !important;
  }

  .number {
    width: 40%;
    background: transparent !important;

    &:last-child {
      border-left: 0;
    }
  }
}

@border-color-base: #D9D9DE;@text-color: #050A13;@primary-color: #14c4a2;@select-border-color: @border-color-base;@select-dropdown-bg: #EFF6FA;@select-dropdown-font-size: 14;@select-item-selected-bg: #EFF6FA;@select-item-active-bg: #EFF6FA;@select-selection-item-bg: #EFF6FA;@select-selection-item-border-color: @border-color-base;