
.dataDrillPanel {
  position: absolute;
  color: rgba(0,0,0,.54);
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.1), 0 10px 12px 0 rgba(170,182,206,.36); }
.drill-dropdown {
  transform: scaleY(1)!important;
  opacity: 1!important; }
.items {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center; }
.drill-menu {
  display: block;
  .ant-menu {
    padding-top: 40px;
    height: 292px;
    overflow: auto;
    .ant-menu-item {
        padding: 0 8px 0 16px;
        &:not(:first-child):hover {
            background-color: #EFF6FA;
            color: rgba(0, 0, 0, 0.65)!important; }
        .search-content {
            height: 100%;
            padding: 0 16px;
            margin-right: 6px;
            background: #ffffff;
            border-bottom: 1px solid #e7e7eb;
            .ant-input {
                height: 28px;
                padding-right: 36px; } }
        &:first-child {
            background-color: transparent!important;
            height: 40px!important;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            padding: 0; } } } }
