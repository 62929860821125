.ant-drawer-wrapper-body {
    position: relative;
    padding-top: 60px;
    .ant-drawer-header {
        height: 60px;
        width: 100%;
        padding: 12px 30px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        .ant-drawer-title {
            line-height: 32px;
            .title {
                width: 80%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }

            &>div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 33px;
                &>div {
                    // line-height: 32px
                    i {
                        font-size: 18px;
                        display: inline-block;
                        line-height: 32px; } } } } }
    div.ant-drawer-body {
        padding: 24px;
        height: calc(100vh - 60px);
        position: relative;
        overflow: auto;
        .uxs-drawer-content {
            padding-bottom: 75px;
            height: 100%;
            overflow: auto;
            .exclusion-form-wrapper {}
            .drawer-footer {
                width: 100%;
                height: 75px;
                padding: 24px 0;
                text-align: center;
                background: #f3f6fc;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 3;
                button:not(:first-of-type) {
                    margin-left: 20px; }
                .focus-input {
                    z-index: 7;
                    position: relative; } } }
        .account-tabs {
            margin-bottom: 16px;
            .ant-tabs-nav {
                .ant-tabs-tab {
                    padding-top: 0; } } } }
    .ant-drawer-close {
        color: #000;
        top: 2px;
        left: 10px;
        &:hover {
            color: #14C4A2; } }
    .focus-input {
        z-index: 7;
        position: relative; } }
.scroll-drawer {
    .ant-drawer-body {
        padding: 0; } }
.ant-form {
    .ant-select-auto-complete.ant-select .ant-select-search--inline {} }

