.comparision {
    &.middleSize {
        font-size: 14px !important;
        span {
            i {
                font-size: 14px; } }
        .textColor,.noneColor {
            font-size: 14px !important; } }
    font-size: 12px !important;
    color: #7A8493 !important;
    white-space: nowrap;
    margin-right: 4px;
    &:hover {
        cursor: pointer; }
    span {
        display: flex;
        align-items: center;
        i {
            font-size: 12px; } }
    .textColor,.noneColor {
        font-size: 12px !important; }
    i {}
    .blank {
        visibility: hidden;
        width: 16px;
        height: 16px; }
    &.comparision-increase {
        .textColor {
            color: #050A13; }
        span {
            color: #14C4A2; }
        i {
            color: #14C4A2; } }


    &.comparision-decrease {
        .textColor {
            color: #050A13; }
        span {
            color: #FF6B5C; }
        i {
            color: #FF6B5C; } } }
.table-comparision {
    span {
        justify-content: flex-end; } }

@media screen and (max-width: 768px) {
    .comparision {
        font-size: 0.14rem; } }
