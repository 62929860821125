@import "../../static/sass/variables.sass";


@mixin type-for-iconSelect($hover-color) {
    .setUpColorInput {
        width: 20px;
        height: 23px;
        border-radius: 2px;
        border: none;
        text-align: center;
        background-color: #FFFFFF; }
    .setUpSpan {
        margin-right: 8px;
        width: 56px; }
    .setUpColorInput:hover {
        cursor: pointer; }
    .setUpDiv {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .icon-select {
            margin-left: 8px; }
        .icon-content {
            width: 18px;
            height: 18px;
            line-height: 16px;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            text-align: center;
            i {
                font-size: 14px; } } }
    .setUpColorInput {
        border: 1px solid #e8e8e8; }
    .setUpSpacing {
        width: 32px;
        height: 20px;
        border: none;
        border-bottom: 1px solid #707070;
        margin-right: 8px;
        padding: 0px 12px; }
    .icon-select-popover {
        width: 392px;
        .icon-select-title {
            line-height: 24px;
            color: $hover-color;
            border-bottom: 1px solid $hover-color;
            text-align: center; }
        .icon-select-content {
            padding: 8px 8px 8px 16px;
            color: #7a8493;
            .icon-group {
                margin-bottom: 8px;
                .icon-group-title {
                    line-height: 14px;
                    margin-bottom: 8px; }
                .icon-group-content {
                    display: flex;
                    flex-wrap: wrap;
                    .icon-item {
                        text-align: center;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        cursor: pointer;
                        width: 38px;
                        &.icon-selected-item,
                        &:hover {
                            color: $hover-color; } } } } } } }

@include type-for-iconSelect(#56E2CE);

