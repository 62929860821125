.searchBarContainer {
    padding: 12px; }

.checkListContainer {
    height: 400px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    .checkbox {
        width: 100%; }
    .divider {
        margin: 4px 0; }
    .empty {
        text-align: center;
        line-height: 200px; } }
