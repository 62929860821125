@import "../../../static/sass/variables";
@import "../../../static/sass/mixin";
.shaixuan-btn {
    margin-left: 3px;
    color: #1bb09d; }
.full-right {
    // left: auto !important
    // height: 100%
    .ant-drawer-content-wrapper {
        position: fixed;
        // width: 50vw !important
        margin: 0;
        padding: 0;
        height: 100%;
        left: auto !important;
        top: 0px;
        right: 0px;
        // overflow: auto
        .ant-drawer-header {
            text-align: center; }
        .ant-drawer-body {
            overflow: auto;
            .warning-form-Condition {
                margin-top: 25px;
                margin-left: 50px;
                .or-condition-split-text {
                    margin: 0px; } }
            .ant-form-item {
                .info-checkbox-content {
                    margin: 8px 0; } }
            .waring-alert {
                width: 600px;
                white-space: normal;
                .warning-preview {
                    h5 {
                        font-size: 18px;
                        font-weight: 600;
                        margin: 16px 0 8px; }
                    p {
                        margin-bottom: 8px; } } } } }
    .extraItem {
        .ant-form-item-children-icon {
            margin-right: 24px; } }
    .typeItem {
        .ant-form-item-children-icon {
            margin-right: 10px; } }
    .triggerLimit {
        margin: 0 20px; } }
.param-tips {
    h5 {
        margin-bottom: 8px; }
    li {
        margin-bottom: 8px; } }
.noError {
    .ant-select-arrow {
        color: rgba(0, 0, 0, 0.25) !important; } }

.noError {
    border-color: rgba(0, 0, 0, 0.25) !important; }
