.mobileCalander {
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    width: 260px;
    height: 32px;
    .am-list-item:not(:last-child) .am-list-line {
        border: none; }
    .am-list-item {
        background-color: transparent;
        .am-list-line {
            height: 31px;
            .am-list-arrow-vertical {}
            .am-list-content {
                font-size: 12px; } } } }

.am-calendar {
    .single-month {
        .row {
            flex-wrap: nowrap !important; } }
    .content {
        top: 56px;
        height: calc(100% - 56px); } }
