@import "static/sass/variables.sass";

@mixin color-for-mobile-component-base($FIRST_TEXT-COLOR,$SECOND_TEXT-COLOR, $TIPS-COLOR,$DISABLED-COLOR, $BORDER-COLOR,$FIRST-ACTIVE-BG,$SECOND-ACTIVE-BG,$MAIN-COLOR,$MAIN-HOVER-COLOR,$MAIN-ACTIVE-COLOR,$DISABLED-BG-COLOR,$CALENDAR-ACTIVE-BG,$BOX-SHADOW, $LIGHT, $LIGHTBORDER) {
    // $FIRST_TEXT-COLOR: #050A13SHADOW
    // $SECOND_TEXT-COLOR: #7A8493
    // $TIPS-COLOR: #B2B3B9
    // $DISABLED-COLOR: #D9D9DE
    // $BORDER-COLOR: #7A8493
    // $FIRST-ACTIVE-BG: #EFFAF6
    // $SECOND-ACTIVE-BG: #EFF6FA
    // $MAIN-COLOR: #14c4a2
    // $MAIN-HOVER-COLOR: #38CBAE
    // $MAIN-ACTIVE-COLOR: #40AD98
    .adm-side-bar-item-active {
        color: $MAIN-COLOR; }
    .adm-side-bar-item-highlight {
        background: $MAIN-COLOR; }
    .mobile-sidebar .sidebar-content .active {
        background-color: $FIRST-ACTIVE-BG;
        color: $MAIN-COLOR; }
    .mobile-sidebar .sidebar-content .sidebar-item:hover {
        background-color: $MAIN-HOVER-COLOR; }
    .mobile-filters-reset {
 }        // background: $MAIN-COLOR
    .mobile-filters-sure .mobile-filters-back {
        background: $MAIN-COLOR; }
    .mobile-filter-add-icon {
        color: $MAIN-COLOR; }
    .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
        border-color: $MAIN-COLOR;
        background-color: $MAIN-COLOR; }
    .adm-calendar .adm-calendar-header a.adm-calendar-arrow-button {
        color: $MAIN-COLOR; }
    .adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected {
        background: $MAIN-COLOR; }
    .adm-calendar-cell.adm-calendar-cell-today {
        color: $MAIN-COLOR; }
    .mobile-v5-calendar .mobile-v5-calendar-selectors .tag {
        background-color: $FIRST-ACTIVE-BG;
        border: none; }
    .mobile-v5-calendar .mobile-v5-calendar-selectors .tag-choose {
        background-color: $MAIN-COLOR;
        color: white; }
    .mobile-v5-tag {
        color: black;
        background: #EFFAF6;
        border: none; }
    .mobile-v5-tag-close {
        border: 1px $MAIN-COLOR solid;
        color: $MAIN-COLOR; }
    .mobile-v5-tag-close .anticon-close {
        color: $MAIN-COLOR; }
    .mobile-sidebar .sidebar-main .highlight {
        background: $MAIN-COLOR; }
    .mobile-sidebar .sidebar-main .isActive {
        color: $MAIN-COLOR; }
    .iconHover {
        &:hover:active {
            color: $MAIN-COLOR !important; } }
    .adm-search-bar-active .adm-search-bar-input-box {
        border-color: $MAIN-COLOR; }
    .takeAll {
        color: $MAIN-COLOR; }
    .warning-handle {
        color: $MAIN-COLOR; }
    .warning-detail-button {
        border-color: $MAIN-COLOR;
        color: $MAIN-COLOR; }
    .warning-mobile-handle {
        color: $MAIN-COLOR; }
    .ant-btn-group {
        .ant-btn {
            border-color: #d9d9d9; }
        .ant-btn:hover {
            border-color: $MAIN-COLOR; } }
    .take-number {
        color: $MAIN-HOVER-COLOR; }
    .ant-alert-info {
        background-color: $LIGHT;
        border-color: $LIGHTBORDER; }
    .ant-alert-info .ant-alert-icon {
        color: $MAIN-COLOR; }
    .bindwx {
        color: $MAIN-COLOR; }
    .MAIN-BACK-COLOR {
        background: $MAIN-COLOR; }
    .MAIN-COLOR {
        color: $MAIN-COLOR;
        cursor: pointer; }
    .ant-slider-handle {
        border: 2px $MAIN-COLOR solid; }
    .ant-slider-track {
        background-color: $MAIN-COLOR; }
    .exclusion {
        padding-top: 16px; }
    .detail-drag-info {
        background: $MAIN-COLOR; }
    .detail-drag-empty-info {
        background: $LIGHT;
        border: 1px $MAIN-COLOR dashed; }
    .finish-container {
        .icon-cirle {
            color: $MAIN-COLOR;
            margin-bottom: 20px; } }
    .icon-hover {
        &:hover {
            color: $MAIN-COLOR; } }
    .analysis {
        color: $MAIN-COLOR !important; }
    .mobile-main-color {
        color: $MAIN-COLOR;
        border: 1px $MAIN-COLOR solid; } }
