@import "./../../static/sass/variables";


@mixin style-for-sidebar($main-color,$menu-active-color) {
    #sidebar {
        z-index: 2;
        &.collapsed-sidebar {
            width: 56px;
            .sidebar-tools {
                display: none; }
            .editable-menu {
                a {
                    text-align: center;
                    flex-direction: column;
                    padding-left: 8px!important;
                    .dashboard-type-icon {
                        visibility: visible;
                        line-height: 14px;
                        margin-right: 0!important;
                        padding-left: 0; }
                    >div {
                        height: 18px;
                        line-height: 18px; } } }
            .ant-menu-item {
                flex-direction: column;
                align-items: start!important;
                height: 48px!important;
                .nav-label {
                    display: block;
                    height: 24px;
                    padding: 0!important;
                    width: 28px; }
                .menu-tool {
                    visibility: hidden!important; } }
            .single-title {
                padding-top: 4px!important;
                .iconfont {
                    line-height: 14px; } } }

        .siderbar_logo {
            padding: 10px;
            box-sizing: border-box;
            background-color: $gray-dark;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; }
        .circle_logo {
            cursor: pointer;
            height: 40px;
            width: 40px; }
        .cemyun_logo {
            height: 80%; }
        .trigger {
            text-align: center;
            display: inline-block;
            color: #ffffff; }
        .sidebar-foot {
            position: absolute;
            bottom: 30px;
            left: 0;
            padding-left: 20px;
            display: flex;
            align-items: center;

            .sidebar-foot-label {
                width: 100px;
                overflow: hidden;
                transition: 1s all;
                color: $gray-nav-item;
                white-space: nowrap;
                text-overflow: ellipsis; }
            i {
                font-size: 24px;
                margin-right: 6px; } }
        .sidebar-content {
            .ant-layout-sider.ant-layout-sider-dark {
                background: transparent;
                max-width: 160px !important;
                min-width: 160px !important;
                width: 160px !important;
                .ant-layout-sider-children {
                    .page-sidebar {
                        border-right: none;
                        .ant-menu-item {
                            margin: 0;
                            height: 44px;
                            padding: 0px 16px;
                            .nav-label1 {
                                top: 16px;
                                font-size: 14px; }
                            a {}
                            .iconfont {
                                font-size: 14px;
                                margin-right: 10px;
                                font-weight: 500; } }
                        .single-title {
                            display: flex;
                            align-items: center;
                            &.ant-menu-item {
                                height: 44px;
                                padding: 0 16px;
                                padding-left: 12px!important;
                                a {
                                    padding-left: 0;
                                    font-size: 12px; } }
                            &.ant-menu-item-selected {
                                background-color: transparent;
                                .iconfont, .nav-label {
                                    color: $main-color !important; } }
                            .iconfont {
                                display: block;
                                transform: translateY(0);
                                font-size: 16px; }
                            .iconfont, .nav-label {
                                color: #7a8493; } }

                        .ant-menu-submenu {
                            .ant-menu-submenu-title {
                                margin: 0;
                                height: 44px;
                                padding: 4px 0px 0px 16px !important;
                                &:hover {
                                    color: $main-color;
                                    .iconfont,.nav-label,.nav-label1 {
                                        color: $main-color; } }
                                &:hover,&:focus,&:active {

                                    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
                                        background: -webkit-gradient(linear, left top, right top, from($main-color), to($main-color)) !important;
                                        background: -webkit-linear-gradient(left, $main-color, $main-color) !important;
                                        background: -o-linear-gradient(left, $main-color, $main-color) !important;
                                        background: linear-gradient(to right, $main-color, $main-color) !important; } }

                                a {
                                    display: flex;
                                    line-height: 42px; }

                                .iconfont {
                                    font-size: 16px;
                                    margin-right: 10px;
                                    color: #7a8493;
                                    font-weight: 500;
                                    display: inline-block; }
                                .nav-label1 {
                                    display: none;
                                    font-size: 12px;
                                    font-weight: 400;
                                    padding-left: 2px; }
                                .nav-label {
                                    color: #7a8493;
                                    font-weight: 400;
                                    font-size: 14px;
                                    max-width: 90px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap; }
                                // &:active
 } } }                                //     background: transparent
                    .ant-menu {
                        .ant-menu-item {
                            margin-top: 0;
                            margin-bottom: 0;
                            padding-left: 0!important;
                            height: auto;
                            border-left: 3px solid transparent;
                            a {
                                padding: 4px 0 4px 36px;
                                line-height: 24px;
                                font-size: 12px;
                                color: #050A13;
                                font-weight: 400;
                                overflow: hidden;
                                text-overflow: initial;
                                white-space: break-spaces;
                                &:hover {
                                    color: $main-color;
                                    .dashboard-type-icon {
                                        color: $main-color; } } }
                            &:active {
                                background: transparent; }
                            .dashboard-menu-item {
                                padding-left: 16px; } }
                        .ant-menu-submenu-selected {
                            &>.ant-menu-submenu-title {
                                color: $main-color;
                                .iconfont {
                                    color: $main-color; }
                                .nav-label {
                                    color: $main-color; } }

                            .ant-menu {
                                border-left: none; } }
                        .ant-menu-item-selected {
                            background: $menu-active-color;
                            border-left: 3px solid $main-color;
                            a,
                            .dashboard-type-icon {
                                color: $main-color; }
                            &:active {
                                color: $main-color; }
                            &:after {
                                display: none; } }

                        .dashboard-sub {
                            .ant-menu-submenu-title {
                                line-height: 42px;
                                .title {
                                    white-space: normal;
                                    display: flex;
                                    .icon-dakaiwenjianjia {
                                        display: none; }
                                    .nav-label {
                                        font-size: 12px; } }
                                .menu-tool {
                                    line-height: 42px;
                                    margin-right: 10px; }
                                .ant-menu-submenu-arrow {
                                    display: none; }
                                &:hover {
                                    background: $menu-active-color;
                                    i {
                                        color: #7A8493; } } }
                            &.ant-menu-submenu-open {
                                .ant-menu-submenu-title {
                                    .title {
                                        .icon-bihewenjianjia {
                                            display: none; }
                                        .icon-dakaiwenjianjia {
                                            display: inline-block; } } } } }
                        .editable-menu {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            a {
                                flex-grow: 1;
                                padding-left: 13px;
                                display: flex;
                                align-items: center; }
                            i {
                                font-size: 16px; }
                            .menu-tool {
                                font-weight: 500;
                                flex-grow: 0;
                                visibility: hidden;
                                line-height: 26px;
                                margin-right: 0; }
                            &:hover {
                                .menu-tool {
                                    visibility: visible; } }
                            .ant-dropdown-trigger {
                                &:hover {
                                    color: $main-color!important; } } } } } } }
        .loginPage {
            .icon {
                position: absolute;
                right: 20px;
                top: 20px; }

            &.show {
                transition: .1s all;
                background: $gray-lighter;
                position: fixed;
                z-index: -1;
                left: 0;
                top: 0;
                width: 100%;
                margin: 0 auto;
                padding-left: 226px;
                height: 100vh; }
            &.show.collapse {
                transition: .1s all;
                padding-left: 80px; }
            &.hidden {
                display: none;
                padding-left: 226px; } }
        .sidebar-tools {
            padding: 0 16px;
            .sidebar-tools-content {
                display: flex;
                justify-content: flex-end;
                height: 44px;
                padding: 14px 0 0;
                border-bottom: 1px solid #E7E7EB;
                i {
                    color: #7A8493;
                    margin-left: 8px;
                    &.active {
                        color: $main-color; } } }
            .dashboard-search {
                .ant-input {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-radius: 0;
                    font-size: 12px; } } }
        &.dashboard-sidebar {
            .sidebar-scrollbar-content {
                height: calc(100vh - 100px); } } }
    .ant-menu-submenu-popup {
        .ant-menu-item {
            margin-top: 0;
            height: 40px;
            &:focus {
                background: transparent;
                a {
                    line-height: 34px; } }
            a {
                padding-left: 0; } } }
    .ant-menu-inline {
        .ant-menu-item-selected::after {
            opacity: 0 !important; }
        .iconfont {
            color: $main-color; }
        .nav-label {
            color: $main-color; }
        .nav-label1 {
            display: none; } }
    .ant-menu-inline-collapsed {
        .ant-menu-submenu-title {
            height: 48px !important;
            a {
                line-height: 36px!important; } }
        .iconfont {
            padding-left: 5px;
            line-height: 24px; }
        .nav-label {
            display: none; }
        .nav-label1 {
            display: block !important;
            position: absolute;
            top: 20px;
            left: 14px;
            bottom: -14px;
            width: 41px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; } }
    .ant-menu-inline-collapsed-tooltip {
        width: 60px;
        left: 56px!important;
        .ant-tooltip-content {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            .ant-tooltip-arrow {
                display: none; }
            .ant-tooltip-inner {
                width: 160px;
                height: 34px;
                line-height: 22px;
                background: #fff;
                color: #050A13;
                padding-left: 16px;
                font-weight: 400;
                border-radius: 4px;
                i {
                    display: none; }
                .nav-label {
                    color: #fff; } } } }

    .editable-menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        a {
            flex-grow: 1;
            padding-left: 8px;
            display: flex;
            color: rgb(5, 10, 19);
            align-items: center; }
        i {
            font-size: 16px; }
        .dashboard-type-icon {
            font-size: 16px;
            margin-right: 10px;
            visibility: hidden; }
        &:hover {
            .dashboard-type-icon {
                visibility: visible; } } }

    @media screen and (max-width: 768px) {
        #sidebar {
            box-shadow: 2px 0px 1px rgba(0,0,0,0.08);
            background-color: #ffffff;
            z-index: 10;
            position: fixed;
            left: 0;
            top: 56px;
            .siderbar_logo {
                display: none; }
            .sidebar-foot {
                width: 100%;
                position: static;
                padding-left: 0.38rem;
                .sidebar-foot-label,.iconfont {
                    font-size: 0.48rem;
                    color: $gray-dark !important; } }
            .sidebar-content {

                .icon {
                    text-align: center;
                    width: 0.4rem;
                    height: 0.4rem;
                    position: fixed;
                    right: 0.2rem;
                    top: 0.2rem;
                    z-index: 10; }
                span {

                    &:active {
                        background: transparent !important; } } }
            .sidebar-tools {
                display: none; }
            .sidebar-scrollbar-content {
                height: calc(100vh - 56px); } } }
    @media screen and (max-width: 576px) {
        #sidebar {}
        .collapsed-sidebar {
            display: none !important;
            .siderbar_logo {}
            .circle_logo {}
            .cemyun_logo {}
            .trigger {}
            .sidebar-foot {
                .sidebar-foot-label {}
                i {} }
            .sidebar-content {
                .ant-layout-sider.ant-layout-sider-dark {
                    .ant-layout-sider-children {
                        .page-sidebar {
                            .ant-menu-item {
                                a {}
                                .iconfont {} }
                            .ant-menu-item {}
                            .ant-menu-submenu {
                                .ant-menu-submenu-title {
                                    margin: 0;
                                    width: 160px;
                                    padding: 0px;
                                    height: 44px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 24px;
                                    color: $main-color;
                                    a {}
                                    .iconfont {}
                                    .nav-label1 {}
                                    .nav-label {
                                        &:hover {} }
                                    // &:active
 }                                    //     background: transparent
                                .ant-menu {
                                    .ant-menu-item {
                                        height: 32px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        color: $main-color;
                                        opacity: 1;
                                        a {
                                            &:hover {} }
                                        &:active {} } } }
                            .ant-menu-submenu-selected {
                                .ant-menu-submenu-title {
                                    .iconfont {}
                                    .nav-label {} }
                                .ant-menu {
                                    .ant-menu-item-selected {
                                        color: $main-color;
                                        border-left: 3px solid  $main-color;
                                        margin-left: 10px;
                                        padding-left: 47px;
                                        a {}
                                        &:after {} } } } } } } }
            .loginPage {
                .icon {}
                &.show {}
                &.show.collapse {}
                &.hidden {} } }
        .ant-menu-submenu-popup {
            .ant-menu-item {
                &:focus {
                    a {} } } }
        .ant-menu-inline-collapsed {
            .ant-menu-submenu-title {}
            .iconfont {}
            .nav-label1 {} }
        .ant-menu-inline-collapsed-tooltip {
            .ant-tooltip-content {
                .ant-tooltip-arrow {}
                .ant-tooltip-inner {
                    i {}
                    .nav-label {} } } } } }

@include style-for-sidebar(#14C4A2,#effaf6);

.ant-menu-submenu {
    .ant-menu-submenu-title {
        .editable-menu {
            .title {
                .icon-dakaiwenjianjia {
                     display: none; } } } }
    .ant-menu-submenu-open {
        .ant-menu-submenu-title {
            .title {
                .icon-bihewenjianjia {
                    display: none; }
                .icon-dakaiwenjianjia {
                    display: inline-block; } } } } }
.ant-menu-submenu {
    .ant-menu-submenu-vertical {
        .editable-menu {
            .title {
                margin: 0; } } } }

.ant-menu-item {
    .menu-tool {
        visibility: hidden; }
    &:hover {
        .menu-tool {
            visibility: visible; } } }

.ant-menu-item .ant-menu-submenu .dashboard-menu-item {
    .menu-tool {
        visibility: hidden; }
    &:hover {
        .menu-tool {
            visibility: visible; } } }

.notFolder {
    display: flex;
    justify-content: space-between; }
.ant-menu-inline-collapsed-tooltip {
    .ant-tooltip-inner {
        color: #fff; }
    .editable-menu {
        a {
            color: #fff; } } }

.sidebar-menu-popup {
    left: 70px!important;
    .ant-menu-sub {
        min-width: 100px;
        .ant-menu-item {
            padding: 0 10px;
            margin-bottom: 0;
            &>div,
            &>a {
                line-height: 39px;
                padding: 0 8px; }
            &:not(:last-child) {
                &>div,
                &>a {
                    border-bottom: 1px solid #E7E7EB; } }
            .dashboard-type-icon {
                display: none!important; }
            .menu-tool {
                margin-left: 8px; } } } }
