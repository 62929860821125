.chartContainer {
  flex: 1; }


.scorecard {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .scorecardContainer {
    max-width: 100%;

    .scorecardTitle {
      padding: 8px 0 0;
      display: block;
      span {
        display: inline-block;
        line-height: 1;
        white-space: pre; } } } }

.summary {
  font-size: 12px;
  overflow: hidden;
  .summaryMain {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .summaryText {
      line-height: 36px; }
    .summaryTitle {
      margin-right: 15px;
      padding: 8px 0; }
    .comparision {
      margin-bottom: 3px;
      padding: 8px 0; } }
  p {
    margin-bottom: 0; } }

.iframePage {
  flex: 1;
  border: none; }

