@import '../../static/sass/_mixin.sass';


@mixin style-for-commontable($title-text,$title-bg,$col-text,$hover-bg,$border-color,$main-color) {
    .ant-table-row-expand-icon {
        &:hover,&:focus,&:active {
            color: $main-color; } }
    .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
        &:hover {
            background: $hover-bg; } }
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background-color: $hover-bg!important; }
    .ant-table-thead > tr.ant-table-row-hover > td, .ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-thead > tr:hover > td, .ant-table-tbody > tr:not(.ant-table-expanded-row):hover > td, {
        background-color: $hover-bg!important;
        &.select {
            background-color: $main-color!important; } }
    .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters {
        color: $title-text;

        .ant-table-column-sorters {
            background: none !important;
            &:before {
                background: none !important; } } }
    .ant-table-wrapper {
        padding-top: 16px;
        &.noPadding {
            padding: 0px; }

        table {
            margin: 0 !important;
            border-radius: 0px;
            // 数字类别的指标样式
            .ant-table-column-serialNumber {
                text-align: center;
                white-space: nowrap; }
            .column-for-expandedTableHead {
                min-width: 160px;
                max-width: 160px;
                text-align: left; }
            .column-for-expandedTableHead-url {
                max-width: 350px;
                min-width: 160px;
                text-align: left; }
            .column-for-iconLast {
                text-align: center;
                width: 40px; }
            .column-for-iconHead {
                text-align: center;
                padding: 0 0 0 6px;
                width: 24px; }
            .column-for-key {
                text-align: left;
                min-width: 115px; }
            .column-for-rate {
                text-align: right;
                min-width: 70px; }
            .column-for-string,
            .column-for-date {
                text-align: left;
                min-width: 96px; }
            .column-for-longString {
                text-align: left;
                min-width: 150px;
                white-space: pre-wrap; }
            .column-for-data {
                text-align: right;
                min-width: 96px; }
            .column-for-operateBtn {
                text-align: right;
                min-width: 120px; }
            .last-table-column {
                padding-right: 12px!important; }
            tr {
                &:not(.ant-table-expanded-row) {
                    &:hover {
                        .eventDetailIcon,.editAndCreateIcon {
                            visibility: visible;
                            i {
                                &:hover {
                                    color: $main-color; } } } } }
                &.ant-table-expanded-row {
                    background: none !important;
                    &:hover {
                        background: transparent !important; } }
                .ant-table-row-expand-icon-cell,.ant-table-expand-icon-th {
                    padding: 0 0 0 6px;
                    width: 25px;
                    min-width: 25px; } }
            th {
                border-bottom: 1px solid $border-color;
                padding: 12px 8px 12px 8px;
                background-color: $title-bg!important;
                font-weight: 400 !important;
                color: $title-text!important;
                font-size: 12px;
                font-weight: 500;
                .ant-table-column-sorter {
                    vertical-align: baseline; } }

            .ant-table-expanded-row {
                >td {
                    padding: 0; }
                td {
                    .ant-table-wrapper {
                        margin: 0 !important;
                        padding: 0; } } }


            td {
                border-bottom: 1px solid $border-color;
                padding: 6px 8px;
                color: $col-text;
                font-size: 12px;
                font-weight: 400;
                .ant-checkbox-wrapper {
                    font-size: 12px; } }

            .ant-table-selection-column {
                padding: 6px 0 6px 8px; }

            a {
                text-decoration: underline;
                color: $main-color;
                &:active,&:hover,&:visited,&:focus {
                    text-decoration: underline;
                    color: $main-color; } } } }
    .rateContent {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column; }
    .rateContent>.rateContent-title,.rateContent>.rateContent-urlName,td>.antd-table-column-head {

        //autoprefixer不仅会帮你加-webkit-之类的prefixer，
        // 它还会帮你删除你自己写在 css/sass/less里的样式，
        // 此处注释不用删除
        /*! autoprefixer: off */
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word; }

    .childrenContent {
        position: relative;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        flex-direction: row;
        .childrenContentHeadIcon {
            display: flex;
            align-items: center;
            .childrenContentHeadIcon_for_survey_finish {
                opacity: 1; }
            .childrenContentHeadIcon_for_survey_unfinish {
                opacity: 0.5; } } }
    .ratioAndDataContent {

        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        .ratioAndDataContent-data {
            margin-right: 8px;
            text-align: right; }
        .comparision span {
            justify-content: flex-end; } }


    .eventDetailIcon,.editAndCreateIcon {
        display: flex;
        align-items: center;
        visibility: hidden;
        i {
            margin-left: 10px; } }
    .expandedRowRenderTable {
        table {
            margin-bottom: 0px;
            padding-right: 50px;
            tr {
                &:hover {
                    background: #dddddd; } }
            tr > td {
                line-height: 30px; }
            .expandedStepsName {
                width: 300px; } } }
    .options-tableOptions {
        margin-top: 16px;
        .dropdown {
            background-color: transparent; }
        .dropdownselect {
            margin-bottom: 0px;
            button {
                width: 120px !important; } } }
    .ant-table-column-sorter-inner {
        margin-top: 0px !important;
        transition: none !important;
        .ant-table-column-sorter-up.on,.ant-table-column-sorter-down.on {
            color: $main-color !important; }
        i {

            transition: none !important;
            &:hover {
                color: $main-color; } } } }
@include style-for-commontable(#7a8493,#F3F3F4,#050A13,#eff6f4,#E7E7EB,#14c4a2);
