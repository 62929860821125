// table样式
@import '../../containers/CommonTable/index.sass';
// 桑基图样式
@import '../../component/PageHeader/PageHeader.sass';
@import '../../component/PageHeader/GlobalControl/index.sass';
@import '../../containers/AnalyseCombination/index.sass';
@import '../../containers/AnalyseSurvey/SurveyNewChart/index.sass';
@import './component-style-mixin.sass';
@import './antd-base-style-mixin.sass';
@import './antd-mobile-style-mixin.sass';
@import '../../containers/CommonTab/index.sass';
@import '../../component/Sidebar/Sidebar.sass';
@import '../../component/Loading/Loading.sass';
@import '../../component/OrderNumber/index.sass';
@import '../../component/DropBox/index.sass';
@import '../../containers/AnalyseAlgorithm/index.sass';
@import '../../containers/Worksheet/Detail/index.sass';
@import '../../component/CardSetUp/index.sass';

//山姆主题色
$sams-main: #1F80DE;
$sams-main-hover: #2C90F1;
$sams-main-active: #1879D6;
// 一级色
$sams-text-first: #050A13;
// 二级色
$sams-text-second: #7A8493;
// 边框
$sams-border: #D9D9DE;
// 禁用状态
$sams-disabled: #F5F5F5;
$sams-disabled-bg: #F5F5F5;
$sams-disabled-text: #CFCFCF;
// 背景色
$sams-bg: #F3F3F4;
// 交互一级色
$sams-mutual-first: #EFF6FA;
$sams-mutual-second: #E8F2FC;
// 二级导航背景和卡片背景
$sams-card-bg: #ffffff;
$sams-menu-active : #E8F2FC;
$sams-menu-normal : #ffffff;
$sams-clander-sperate: #F3F3F4;
// 一级菜单以及modal以及表格头部的颜色
$sams-menu-modal: #ffffff;
$sams-tablehead: #EFF6FA;
//分割线
$sams-splitLine: #E7E7EB;
$default-dataRise: #4DD658;
$default-dataDown: #FF6B5C;
body {
    &#sams,&#ipsos {
        a {
            color: $sams-main; }
        color: $sams-text-first;
        background-color: $sams-bg;
        .analyseLayout_title-back {
            a {
                color: #ffffff; } }

        .analyseLayout_title-analyse {
            a {
                color: #ffffff; } }
        .page-index {
            .account {
                a:hover {
                    color: $sams-main; } } }
        .color-link {
            color: $sams-main!important; }
        .infomation {
            a {
                color: $sams-main; } }
        .ant-steps-item-process {
            .ant-steps-item-icon {
                background: $sams-main;
                border-color: $sams-main; } }
        .has-success.has-feedback {
            .ant-form-item-children-icon {
                color: $sams-main; } }
        .childrenFilter {
            .childrenFilter_logic {
                color: $sams-main; } }

        .AnalysePathStep-item {
            .AnalysePathStep-item-plus {
                .green {
                    color: $sams-main; } } }
        .insight {
            .green-font {
                color: $default-dataDown; }
            .red-font {
                color: $default-dataRise; } }

        .ant-drawer-wrapper-body {
            .ant-drawer-close:hover {
                color: $sams-main; } }
        .filterItem_logic {
            color: $sams-main !important; }
        .childrenFilter_add {
            color: $sams-main; }
        .condition-delete {
            color: $sams-main !important; }
        .groupSymbol_box {
            background-color: $sams-main; }
        .customBoard-desc-richTextBox {
            h1,h2,h3,h4,h5,p,span {
                color: $sams-text-first; } }
        .uxs-breadcrumb li:first-child:hover {
            color: $sams-main; }
        .eventTable_toolbar {
            span {
                color: $sams-text-first; } }
        .eventChart_toolbar {
            label {
                span {
                    color: $sams-text-first; } } }
        .ant-input:focus,.ant-input:hover {
            border: 1px solid $sams-main !important; }
        .page-header,.analyseLayout {}
        .ant-btn:hover,.ant-btn:focus {
            color: $sams-main;
            border-color: $sams-main; }
        .select {
            background-color: $sams-main!important; }
        .uxs-pointer {
            &:hover {
                color: $sams-main!important; } }
        .main-color-icon {
            color: $sams-main!important; }
        .commonSummary {
            .summary-col {
                .ant-card {
                    &.selected-item {
                        border: 1px solid $sams-main; } } } }
        .ant-select-dropdown {
            .field-type-icon {
                color: $sams-main; } }
        .widget-content {
            .widget {
                .ant-table-wrapper {
                    .ant-table-fixed-header {
                        th {
                            border-color: $sams-main; } } } } }
        @include color-for-ant-tab(#1879D6,#1879D6,#D9D9DE);
        // @include type-for-pageheader-dropdown($sams-menu-modal,$sams-mutual-first,$sams-text-second,$sams-text-first)
        @include type-for-pageheader-dropdown($sams-menu-normal,$sams-mutual-first,$sams-text-second,$sams-text-first);
        // $bg,$hover-bg,$text,$hover-text,$main,$border,$disabled
        @include type-for-calendar($sams-menu-modal,$sams-mutual-first,$sams-text-second,$sams-text-first,$sams-main,$sams-border,$sams-disabled-bg,$sams-text-second,$sams-clander-sperate,$sams-disabled-text);
        @include type-for-modal($sams-menu-modal,$sams-mutual-first,$sams-text-second,$sams-text-first,$sams-main,$sams-border,$sams-disabled,$sams-text-second,$sams-bg);
        @include style-for-commontable($sams-text-second,$sams-tablehead,$sams-text-first,$sams-mutual-first,$sams-splitLine,$sams-main);
        @include type-for-pagination($sams-text-second,$sams-text-first,$sams-disabled-text,$sams-main,$sams-border);
        @include style-for-surveySentiment($sams-text-second,#EEF6F9,$sams-text-second,$sams-main);
        @include style-for-sidebar($sams-main,$sams-menu-active);
        @include spinner-color($sams-main);
        @include type-for-orderNumber($sams-main,$sams-border,$sams-text-first);
        @include type-for-checkbox($sams-main);
        @include type-for-btn($sams-main);
        @include type-for-menu($sams-main, $sams-mutual-first, $sams-mutual-first, $sams-main);
        @include type-for-combine($sams-main, $sams-mutual-first, #2c90f1, rgba(125, 193, 255, 0.5), #3d76e9, #1879d6);
        @include type-for-iconSelect(#2c90f1);
        @include style-for-dropbox($sams-main, $sams-mutual-first);
        @include type-for-worksheet($sams-main);
        .ant-select-sm {
            &.ant-select-enabled {
                .ant-select-selection {
                    border: none;
                    color: $sams-text-first; } } }
        .ant-select-enabled {
            .ant-select-selection {
                color: $sams-text-first; } }
        .ant-empty-description {
            color: $sams-border; }
        .ant-skeleton {
            .ant-skeleton-content {
                .ant-skeleton-paragraph {
                    li {
                        background: $sams-bg; } } } }
        .sankey-chart {
            .path-level-wrapper {
                .path-level {
                    span {
                        color: $sams-text-first; } } } }
        .footer {
            a {
                color: $sams-text-second; } }
        .setting {
            a {
                color: $sams-main; } }
        .funnel-table {
            .entry-list {
                .info-tips {
                    color: $sams-main; } } }
        .realtime-description-item {
            .description-item-title {
                a {
                    color: $sams-main; } } }
        .ant-select-tree-checkbox-wrapper {
            &:hover {
                .ant-select-tree-checkbox-inner {
                    border-color: $sams-main; } } }
        .ant-select-tree-checkbox {
            &:hover {
                .ant-select-tree-checkbox-inner {
                    border-color: $sams-main; } } }
        .ant-select-tree-checkbox-input {
            &:hover {
                .ant-select-tree-checkbox-inner {
                    border-color: $sams-main; } } }
        .board-control-item-icon {
            color: $sams-main; }
        .secondFieldsContent {
            border: 1px solid $sams-main;
            background-color: #EEF6F9; } } }

