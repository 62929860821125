// .z-depth-0,.z-depth-0-hover:hover
//     box-shadow: none!important
// .z-depth-1,.z-depth-1-hover:hover
//     box-shadow: 0 1px 1px 0 rgba(226,226,226,0.6)
// .z-depth-1-half
//     box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14),0 1px 7px 0 rgba(0,0,0,0.12),0 3px 1px -1px rgba(0,0,0,0.2)
// .z-depth-2,.z-depth-2-hover:hover
//     box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px -1px rgba(0,0,0,0.3)
// .z-depth-3,.z-depth-3-hover:hover
//     box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12),0 3px 5px -1px rgba(0,0,0,0.3)
// .z-depth-4,.z-depth-4-hover:hover,.modal
//     box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12),0 5px 5px -3px rgba(0,0,0,0.3)
// .z-depth-5,.z-depth-5-hover:hover
//     box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12),0 8px 10px -5px rgba(0,0,0,0.3)


@media only screen and (min-width: 768px) {
    #stateContent {
        margin-top: 43px!important;
        .ant-tabs {
            .ant-tabs-bar {
                position: absolute;
                z-index: 100;
                left: 30%;
                margin-bottom: 0px !important;
                border-bottom: none !important;
                margin-top: 445px !important;
                .ant-tabs-nav-container {
                    width: 530px !important; } } } }
    .page-index {
        .container, .ant-layout-content, .banner_menu_item {
            width: 1230px!important;
            margin: 0 auto;
            padding: 0; }
        .ant-layout-header {
            width: 1200px;
            margin: 0 auto;
            padding: 0; }
        .container-fluid {
            padding: 0; } }
    .right-hidden {
        height: 100vh;
        overflow: hidden;
        margin-left: 4px;
        opacity: 0; } }
.page-index {
    background: white;
    .header {
        position: fixed;
        top: 0;
        z-index: 999;
        font-weight: 300;
        height: 56px;
        background: #050A13;
        min-width: 1280px; }
    .unlogin-home-otherTab {
        min-height: 720px; }
    .ant-layout-header {
        height: 56px;
        background: #050A13;
        .ant-menu {
            float: left;
            line-height: 56px; }
        .logo {
            float: left;
            line-height: 54px;
            img {
                width: 100%; } }

        .personal-buttonGroup {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .mobile-personal {
                width: 0.44rem;
                height: 0.44rem;
                background-color: transparent;
                border: none;
                float: right;
                color: #ffffff;
                display: none;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin-top: 0.22rem; } } }


    .ant-menu {
        background-color: #050A13; }
    .ant-menu-horizontal {
        height: 56px;
        border-bottom: none; }
    .ant-menu-item {
        font-size: 16px;
        padding: 0;
        text-align: center;
        width: 160px;
        border-bottom: none;
        top: 0px;
        margin: 0px;
        vertical-align: top;
        a {
            color: #E6E6E6;
            width: 80px;
            height: 56px;
            margin: 0 auto; } }
    .ant-menu-item:hover {
        border-bottom: none;
        color: #E6E6E6; }
    .ant-menu-item:nth-child(8) {
        display: none; }
    .ant-menu-item-selected {
        a {
            border-bottom: 2px solid #1BB09D; }
        a:hover {
            color: #E6E6E6; } }
    .ant-menu-item-active {
        border-bottom: none; }

    .odd {
        background: #F7FAFA; }
    .logo {
        margin-right: 160px; }
    .account {
        float: right;
        line-height: 56px;
        a {
            text-decoration: none;
            color: #FFFFFF;
            font-size: 16px;
            &:hover {
                color: #1BB09D; } }
        .ant-btn {
            font-weight: 300; }
        button {
            margin-left: 50px;
            font-size: 16px;
            border: none;
            background: rgba(27,176,157,0.8);
            color: #ffffff;
            &:hover {
                background-color: rgba(27,176,157,1); } } }
    .content-slogan {
        .ant-layout-content {
            height: 369px;
            h4 {
                font-size: 30px;
                margin-bottom: 40px; }
            p:nth-of-type(1) {
                font-size: 20px;
                font-weight: 300;
                color: #1BB09D;
                margin-bottom: 14px; }
            p:nth-of-type(2) {
                font-size: 20px;
                font-weight: 300;
                color: #7A8493;
                margin-bottom: 40px; }
            p:nth-of-type(3) {
                font-size: 14px;
                color: #7A8493;
                text-align: right;
                margin-right: 8vw;
                cite {
                    font-style: normal; } } } }
    .ant-layout-footer {
        background: #050A13;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        padding: 60px 50px 20px;
        p {
            margin-bottom: 0; }
        .footer-logo {
            margin: 0 auto;
            margin-bottom: 40px;
            width: 296px; }
        img {
            margin-bottom: 20px; }
        .contact-content {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;
            color: #FFFFFF;
            .contact-item {
                &:not(:last-child) {
                    margin-right: 60px; } } }
        .contact-item {
            display: flex;
            justify-content: center;
            p {
                font-size: 18px;
                line-height: 42px;
                margin-left: 10px; }
            .contact-code {
                width: 120px;
                height: 120px;
                background-image: url('../static/images/cemyun_wcCode.png');
                background-size: cover;
                background-repeat: no-repeat; }
            .contact-icon {
                width: 42px;
                height: 42px;
                background-size: cover;
                background-repeat: no-repeat; }
            .contact-icon.phone {
                background-image: url('../static/images/phone_icon.png'); }
            .contact-icon.letter {
                background-image: url('../static/images/letter_icon.png'); }
            .contact-icon.building {
                background-image: url('../static/images/building_icon.png'); } }
        a.contact-item {
            text-decoration: none;
            line-height: 42px;
            color: #ffffff;
            &:hover {
                color: #DCDCDC; } }
        .cemyun-record {
            font-size: 12px;
            color: #7A8493; } }

    //import内容
    //banner
    .banner_text {
        position: relative;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        flex-direction: column;
        text-align: center;
        color: #FFFFFF;
        overflow: hidden;
        h1, h2, h3, h4, h5, h6 {
            color: #FFFFFF;
            font-weight: 300; }
        h1 {
            font-size: 48px;
            font-weight: 400;
            margin-top: 120px; }
        h2 {
            font-size: 80px; }
        h3 {
            font-size: 72px; }
        h4 {
            font-size: 60px; }
        h5 {
            font-size: 20px; }
        h6 {
            font-size: 30px; }
        p {
            font-size: 24px; }
        span {
            font-size: 18px;
            font-weight: 300;
            display: block; }
        i {
            font-size: 14px;
            font-style: normal;
            margin-right: 27vw;
            text-align: right;
            cite {
                font-style: normal; } } }

    //content-sum
    .content-sum {
        .ant-layout-content {
            padding: 0 30px; }
        h4 {
            font-size: 36px; }
        h5 {
            font-size: 24px; }
        h6 {
            font-size: 16px; }
        span {
            font-size: 16px; }
        p {
            font-size: 16px; } }

    //content-desc
    .content-desc {
        // img
 }        //     width: 90%
    .content-desc-text {
        text-align: left;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        // padding-top: 70px
        // padding: 58px
        h4 {
            font-size: 24px;
            color: #000000;
            span {
                font-size: 18px; } }
        h5 {
            font-size: 24px;
            color: #000000; }
        h6 {
            font-size: 18px;
            font-weight: 400;
            color: #000000; }
        span {
                font-size: 16px;
                color: #7A8493; }
        p {
            font-size: 18px;
            color: #7A8493; } }




    //layout-content
    .ant-layout-content {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        .row {
            margin: 0; }
        .row:before {
            display: none; }
        .col-xs-4 {
            padding-left: 0;
            padding-right: 0; } }

    //carousel
    .ant-carousel {
        // .slick-slide
        //     min-height: 40vw
        //     overflow: hidden
        .slick-dots {
            margin-bottom: 50px;
            li {
                button {
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;
                    background-color: #DCDCDC; } }
            li:not(:last-of-type) {
                margin-right: 15px; }
            .slick-active {
                button {
                    width: 12px !important;
                    background-color: #AAAAAA !important; } } } } }

@media screen and (max-width: 768px) {
    html {
        overflow-x: hidden;
        body {
            max-width: 100vw;
            min-width: 1px;
            .ant-dropdown {
                // position: fixed
                // right: 0
                // left: auto!important
                // width: 33.3vw
                .ant-dropdown-menu {
                    // border-radius: 0
                    .ant-dropdown-menu-item {
                        // height: 0.88rem
                        // font-size: 0.26rem
 }                        // line-height: 0.88rem
                    .ant-dropdown-menu-item:hover {
                        background: #F7FAFA;
                        color: #14C4A2; } } }
            &#sams,&#ipsos {
                 #root {
                    .page-index {
                        .header {
                            height: 0.88rem; } } } }

            #root {
                width: 100vw;
                .right-hidden {
                    height: 100vh;
                    overflow: hidden;
                    opacity: 0; }
                .page-index {
                    margin-top: 1.76rem;
                    .unlogin-home-otherTab {
                        min-height: 9.2rem; }
                    .header {
                        height: 1.76rem;
                        width: 100vw!important;
                        max-width: 768px;
                        min-width: 1px;
                        padding-right: 0;
                        padding-left: 0;
                        border-bottom: 1px solid #DCDCDC;
                        .ant-layout-header {
                            padding: 0;
                            height: 100%;
                            min-width: 0;
                            .logo {
                                line-height: 0.56rem;
                                height: 50%;
                                margin-left: 0.3rem;
                                padding: 0.13rem 0;
                                margin-right: 0rem; }
                            .ant-menu {
                                width: 100%;
                                height: 50%;
                                background: #fff;
                                .ant-menu-item {
                                    width: 33.3%;
                                    height: 100%;
                                    a {
                                        height: 100%;
                                        width: 100%;
                                        line-height: 0.88rem;
                                        color: #343C47;
                                        font-size: 0.3rem;
                                        border-bottom: none; } }
                                .ant-menu-item:nth-child(6) {
                                    display: none; }
                                .ant-menu-item:nth-child(8) {
                                    display: inline-block;
                                    .anticon-down {
                                        line-height: 0.88rem;
                                        color: #343C47;
                                        font-size: 0.23rem; } }
                                .ant-menu-item-selected {
                                    background: #F7FAFA;
                                    a {
                                        color: #14C4A2; }
                                    .anticon-down {
                                        color: #14C4A2!important; } } }
                            .account {
                                position: absolute;
                                right: 0.3rem;
                                top: 0.15rem;
                                .account-login {
                                    display: none; }
                                .account-apply {
                                    display: none; }
                                .account-enter {
                                    line-height: 0.55rem;
                                    position: absolute;
                                    font-size: 0.3rem;
                                    white-space: nowrap;
                                    right: 5px; } }
                            .mobile-personal {
                                display: block; } } }

                    .content-slogan {
                        width: 100vw;
                        height: 4.22rem;
                        .ant-layout-content {
                            display: block;
                            height: 4.22rem;
                            line-height: 0.48rem;
                            padding-top: 0.74rem;
                            h4 {
                                margin: 0 auto;
                                font-size: 0.34rem;

                                margin-bottom: 0.24rem;
                                font-weight: bold; }
                            p:nth-of-type(1) {
                                margin: 0 auto;
                                font-size: 0.26rem;
                                margin-bottom: 0.4rem;
                                line-height: 0.26rem; }
                            p:nth-of-type(2) {
                                width: 4.35rem;
                                margin: 0 auto;
                                margin-bottom: 0.24rem;
                                font-size: 0.24rem;
                                line-height: 0.3rem; }
                            p:nth-of-type(3) {
                                font-size: 0.22rem;
                                line-height: 0.22rem;
                                margin-right: 0;
                                margin: 0 auto;
                                text-align: center; } } }
                    .ant-layout-footer {
                        width: 100vw;
                        padding: 0.5rem 0.44rem 0.4rem;
                        .footer-logo {
                            width: 2.96rem;
                            margin-bottom: 0.6rem; }
                        .contact-item {
                            mrgin-bottom: 0.4rem;
                            p {
                                font-size: 0.28rem;
                                line-height: 0.7rem;
                                margin-left: 0.2rem; }
                            .contact-code {
                                width: 2.58rem;
                                height: 2.58rem;
                                background-image: url('../static/images/cemyun_wcCode.png'); }
                            .contact-icon {
                                width: 0.7rem;
                                height: 0.7rem; }
                            .contact-icon.phone {
                                background-image: url('../static/images/phone_icon_mb.png'); }
                            .contact-icon.letter {
                                background-image: url('../static/images/letter_icon_mb.png'); }
                            .contact-icon.building {
                                background-image: url('../static/images/building_icon_mb.png'); } }
                        .contact-content {
                            flex-wrap: wrap;
                            margin-bottom: 0.6rem;
                            .contact-item {
                                &:not(:last-child) {
                                    margin-right: 0;
                                    margin-bottom: 0.4rem;
                                    flex-shrink: 1; }
                                &:first-child {
                                    margin-right: 0.5rem; }
                                &:last-child {
                                    flex-shrink: 0; } } }
                        a.contact-item {
                            margin-bottom: 0.42rem; }
                        .cemyun-record {
                            font-size: 0.24rem; } } } } } } }

@media screen and (max-width: 576px) {
    .page-index .banner_text h1 {
        font-size: 25px;
        margin-top: 20px;
        margin-bottom: 10px !important; }
    .unlogin-home-tabpane {
        #stateContent {
            .ant-tabs {
                margin-top: 56px !important; } } } }
@media screen and (max-width: 768px) {
  .unlogin-home-tabpane {
        #stateContent {
            .ant-tabs {
                display: none; } } } }

