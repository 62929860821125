.commonchart {
    &.noPadding {
        padding: 0; }
    padding: 16px 0 0 0;
    @media screen and (max-width:768px) {
        padding: 0.2rem 0rem 0rem 0rem; } }
.commonchart-noData {
    padding: 0; }
.survey-statistics {
    padding-top: 0; }

