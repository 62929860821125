.uxs-picker-dropdown {
    .ant-calendar-panel {
        display: flex; }
    .ant-calendar-range {
        width: 733px;
        .ant-calendar-range-middle {
            margin-left: -32px; }
        .ant-calendar-range-right {
            .ant-calendar-header,.ant-calendar-body {
                border-right: 1px solid #e8e8e8; } } }
    .ant-calendar-footer {
        width: 212px;
        height: 100%;
        margin-top: 33px;
        padding: 0 20px;
        .ant-calendar-footer-btn {
            width: 100%; }
        .ant-calendar-footer-extra {
            float: none;
            .ant-calendar-footer-extra-content {
                display: flex;
                justify-content: space-between;
                .ant-calendar-footer-extra-content-odd,.ant-calendar-footer-extra-content-event {
                    display: flex;
                    flex-direction: column;
                    margin-top: 12px;
                    width: 80px;
                    text-align: center;
                    .rangePicker-btn.active {
                        background-color: #14c4a2;
                        color: white; }
                    .rangePicker-btn {
                        width: 100%;
                        height: 24px;
                        color: #050a13;
                        border: 0px solid;
                        margin-bottom: 12px;
                        box-shadow: none;
                        background-color: #EFF6FA;
                        padding: 0 4px;
                        &:hover,&:active,&:focus {
                            background-color: #14c4a2;
                            color: white; }
                        span {
                            display: block;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap; } } } } } } }
.uxs-picker {
    margin: 0 !important;
    .ant-calendar-picker-input {
        height: 32px !important;
        line-height: 32px !important;
        padding-right: 0px !important;
        background: transparent; } }
.ant-calendar-range-picker-separator {
    color: #050A13;
    font-size: 12px; }
.showText {
    .ant-calendar-range-picker-separator {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0px;
        top: 0;
        width: 100%;
        background: #F3F3F4; }
    .ant-calendar-picker-input {
        min-width: 180px;
        .ant-calendar-range-picker-input {
 }            // display: none
        .ant-calendar-range-picker-separator {
            witdh: 100%; } }
    .ant-calendar-range-picker-input {
        visibility: hidden; }
    .ant-calendar-input {
        visibility: hidden; }
    .ant-calendar-range-middle {
        white-space: nowrap; } }
.white-picker {
    .showText {
        .ant-calendar-range-picker-separator {
            background: #ffffff; } } }

.uxs-picker-dropdown {
    .ant-picker-range-wrapper {
        .ant-picker-panel-container {
            display: flex;
            .ant-picker-footer {
                .ant-picker-ranges {
                    display: flex; }
                // display: flex
                // align-items: center
                .ant-picker-footer-extra {} }
            .ant-calendar-footer-extra-content {
                display: flex;
                .ant-calendar-footer-extra-content-odd {
                    margin-right: 20px; }
                .ant-calendar-footer-extra-content-odd,.ant-calendar-footer-extra-content-event {
                    display: flex;
                    flex-direction: column;
                    margin-top: 12px;
                    width: 80px;
                    text-align: center;
                    .rangePicker-btn.active {
                        background-color: #14c4a2;
                        color: white; }
                    .rangePicker-btn {
                        width: 100%;
                        height: 24px;
                        color: #050a13;
                        border: 0px solid;
                        margin-bottom: 12px;
                        box-shadow: none;
                        background-color: #EFF6FA;
                        padding: 0 4px;
                        &:hover,&:active,&:focus {
                            background-color: #14c4a2;
                            color: white; }
                        span {
                            display: block;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap; } } } } } } }
.md-picker {
    .ant-calendar-picker {
        width: 240px; } }
.no-background {
    .ant-calendar-range-picker-separator {
        background-color: transparent; } }
