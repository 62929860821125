@import "../../static/sass/mixin";
@import "../../static/sass/variables";

@mixin color-for-ant-tab($hover-color,$active-color,$border) {
    .ant-tabs {
        overflow: inherit; }
    .ant-tabs-bar {
        display: block;
        background-color: transparent;
        margin-bottom: 0px; }
    .mode-left {
        padding-top: 20px; }
    // 地域分布中commonTab的样式
    .ant-tabs-bar_for_district {
        .ant-tabs-nav {
            .ant-tabs-tab {
                font-size: 14px;
                font-weight: normal;
                &:hover {
                    color: $hover-color; } } }
        .ant-tabs-bar {
            display: block;
            overflow: hidden;
            margin: 0;
            background-color: transparent;
            width: auto;
            position: initial;
            right: 30px;
            top: 66px;
            z-index: 2;
            border-bottom: 0px;
            border-radius: 5px; }
        .ant-tabs-nav-container {
            margin-bottom: 0px; }
        .ant-tabs-nav-wrap {
            margin-bottom: 0px; }
        .ant-tabs-ink-bar {
            display: block;
            background-color: transparent; }
        .ant-tabs-tab {
            font-size: 12px;
            text-align: center;
            padding: 5px 22px;
            margin: 0;
            &:not(.ant-tabs-tab-active) {
                border: 1px solid $border; }
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px; }


            &:nth-child(2) {}

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px; } }


        .ant-tabs-tab-active {
            color: $active-color;
            background-color: transparent;
            border: 1px solid $active-color; } }

    .ant-tabs-nav {
        .ant-tabs-tab {
            font-size: 16px;
            font-weight: bold;
            &:hover {
                color: $hover-color; } } }
    .ant-tabs-small {
        .ant-tabs-tab {
            font-size: 14px;
            font-weight: 400; } } }

@include color-for-ant-tab(#14c4a2,#14c4a2,#D9D9DE);
