
.pathStep_form {
    .ant-form-item {
        .pathStep_form-pic {
            margin-top: 22px;
            position: relative;
            .pathStep_form-pic-item {
                position: relative;
                .ant-radio-group {
                    display: flex;
                    justify-content: flex-start; }
                .pathStep_form-pic-item-box {
                    &.disabled {
                       cursor: not-allowed; }
                    cursor: pointer;
                    width: 152px;
                    height: 80px;
                    position: relative;
                    &:last-child {
                        margin-left: 92px; }
                    label {
                        display: flex;
                        align-items: center; }
                    .ant-radio {
                        .ant-radio-inner {
                            width: 20px;
                            height: 20px;
                            &:after {
                                width: 12px;
                                height: 12px; } } }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0; } } }
            .pathStep_form-pic-text {
                font-size: 12px;
                color: #7A8493; } } }
    .pathStep-setting {
        width: 120px;
        .pathStep-setting-childrenFilter {
            .childrenFilter-empty {
                border-left: 1px solid #D9D9DE; }
            .childrenFilter {
                .childrenFilter_add {
                    position: absolute;
                    visibility: visible;
                    top: -39px;
                    right: -50px; } } } } }



