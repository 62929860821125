@import "./../../static/sass/variables";
@import "./../../static/sass/mixin";
@mixin type-for-checkbox($main-color) {
    .board-control-item-icon {
        color: $main-color;
        margin-right: 4px; } }
.board-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 16px; }
.mobile-board-title {
    display: none; }
.board-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap; }
.board-control {
    display: flex;
    flex: 1;
    flex-wrap: wrap; }
.board-control-item {
    margin-right: 16px;
    cursor: pointer;
    &.board-control-description {
        display: flex;
        .ant-switch {
            margin: 4px 0 0 4px; } } }
.page-header-wrapper {
    position: fixed;
    top: 56px;
    background-color: #f3f3f4;
    z-index: 3;
    &.expanding {
        width: calc(100% - 160px); }
    &.collapsed {
        width: calc(100% - 60px); } }
body.customBoard-fullScreen {
    .page-header-wrapper {
        top: 0;
        &.design-header {
            width: 100%; }
        .pageHeader-oprate-dropdown {
            display: none; } }
    .uxs-breadcrumb {
        display: none; } }
.page-header {
    transition: 1s opacity;
    width: 100%;
    border-bottom: none !important;
    padding: $page-header-padding;
    margin: 0;
    color: $page-header-text-color;
    .filters {
        @include clearfix();
        .filters-wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            .trigger {
                line-height: 34px;
                font-size: 18px;
                margin-right: 40px; }
            .pageHeader-mobileCalandar {
                display: none; }
            .pageHeader-mobilePicker {
                .pageMobilePicker, .mobile-right-pageHeader-icon, .pageHeader-mobilePicker-badge {
                    display: none; } }
            .ant-calendar-picker {
                display: block;
                height: $page-header-filter-height;
                margin: $page-header-filter-margin;
                border-radius: 4px;
                .ant-calendar-picker-input {
                    background: transparent;
                    height: $page-header-filter-height;
                    border: 1px solid rgba(0,0,0,0.07);
                    border-radius: 4px;
                    input {
                        color: rgb(0,0,0);
                        cursor: pointer !important;
                        background-color: transparent !important; }
                    .ant-calendar-picker-icon {
                        color: #7a8493; }
                    .ant-calendar-picker-clear {
                        background: #12a491; } } }
            .time-span-select {
                .dropdown {
                    height: $page-header-filter-height;
                    font-size: 12px;
                    margin-left: 8px; } }
            .pageheader-picker {
                position: relative;
                margin-bottom: 8px;
                .ant-calendar-picker-input {
                    minWidth: 180px;
                    padding: 0px 8px 4px 36px; }
                .showText {
                    .ant-calendar-range-picker-separator {
                        left: 40px;
                        width: calc(100% - 40px); } }
                .pageheader-picker-title {
                    position: absolute;
                    top: 0;
                    left: 8px;
                    line-height: 34px;
                    color: #7a8493; } }
            .quick-filter-picker {
                float: none;
                .ant-calendar-picker-input {
                    width: 320px;
                    background: transparent; } }
            .pageHeader-oprate-dropdown {
                position: absolute;
                top: 0;
                right: 0; }
            .product-span-select,
            .time-span-select,
            .base-filter {
                margin: $page-header-filter-margin;
                border-radius: 4px;
                text-align: left !important;
                button {
                    min-width: 94px;
                    max-width: 155px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding: 0px 22px 0px 12px;
                    text-align: left !important;
                    i {
                        position: absolute;
                        right: 10px;
                        top: 10px; } }
                .dropdown {
                    background-color: transparent !important;
                    border: none;
                    color: #050A13;
                    border: 1px solid rgba(0,0,0,0.07) !important;
                    border-radius: 5px !important; } }
            .icon-right {
                color: #7a8493;
                display: flex;
                .iconfont {
                    font-size: 18px; }
                & > div:not(:last-child) {
                    margin-right: 30px;
                    margin-left: 5px; }
                & > div {
                    display: inline-block;
                    line-height: 24px;
                    cursor: pointer;
                    i {
                        margin-right: 5px; } }
                .quick-filter-btn {
                    margin-right: 180px;
                    .ant-switch {
                        margin-left: 4px;
                        margin-top: -4px; } } }
            .icon-left {
                font-size: 18px;
                line-height: 32px;
                color: #7a8493;
                display: block!important;
                margin-right: 4px; }
            .icon-small {
                font-size: 12px; }
            .icon-memu-riqi {
                display: block;
                border: 1px solid rgba(0, 0, 0, 0.07);
                width: 34px;
                height: 34px;
                margin-right: 8px;
                margin-bottom: 8px;
                text-align: center;
                border-radius: 4px; }
            .ant-select-selection {
                color: #050A13;
                background-color: transparent;
                transition: all 0s;
                border: 1px solid rgba(0,0,0,0.07); }
            .pageheader-userGroup-select {
                position: relative;
                margin-right: 8px;
                .ant-select {
                    min-width: 170px!important;
                    max-width: 500px!important;
                    .ant-select-selection {
                        padding-left: 60px;
                        height: 34px;
                        .ant-select-selection__rendered {
                            line-height: 34px; } } }
                .pageheader-userGroup-select-title {
                    position: absolute;
                    top: 0;
                    left: 8px;
                    line-height: 34px;
                    color: #7a8493; } }
            .ant-select-arrow {
                color: #050A13; }
            @media screen and (max-width: 768px) {
                .board-top {
                    display: none; }
                .mobile-board-title {
                    display: block;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 10px; }
                .pageHeader-mobileCalandar {
                    display: flex;
                    padding-left: 5px;
                    .mobileCalander {
                        width: 4.5rem;
                        .am-list-item {
                            background-color: transparent; } }
                    .mobilePicker {
                        margin-left: 0.16rem; } }
                .pageHeader-mobilePicker {
                    position: absolute;
                    right: 4px;
                    .pageMobilePicker {
                        display: block;
                        .mobilePicker {
                            margin: auto 6px; } }
                    .mobile-right-pageHeader-icon {
                        height: 32px;
                        width: 32px;
                        line-height: 32px;
                        text-align: center;
                        color: #7a8493;
                        border-radius: 2px;
                        display: block;
                        .iconfont {
                            font-size: 18px; } }
                    .pageHeader-mobilePicker-badge {
                        display: block;
                        .ant-badge-count {
                            top: 7px;
                            right: 5px;
                            height: 15px;
                            width: 15px !important;
                            min-width: 0;
                            padding: 0;
                            line-height: 15px; } }
                    .am-list-item {
                        background-color: transparent; } }
                .pageHeader-filter {
                    margin: auto 6px; }
                .ant-calendar-picker,.time-span-select {
                    display: none; }
                .icon-right {
                    .quick-filter-btn {
                        display: none; } }
                .pageheader-picker-title {
                    display: none; } } } } }
.advanced-filter-modal {
    .filter-condition-wrapper {
        height: auto;
        width: 750px;
        margin: auto;
        .filter-condition {
            & > div:not(.conditions) {
                padding: 0 24px; } }
        .filter-name-wrapper {
            margin-bottom: 24px;
            label {
                width: 122px;
                margin: 0;
                text-align: right;
                margin-top: 8px;
                display: block;
                float: left; }
            .filter-name-value {
                display: inline-block;
                width: 435px; } }
        .conditions {
            padding: 12px 24px 24px 146px; } }

    .pageHeader-oprate-menu {
        .ant-dropdown-menu-item {
            display: flex;
            align-items: center;
            padding: 5px 8px;
            .iconfont {
                color: $main-color!important; }
            .ant-switch {
                margin-left: 8px; } } }
    .page-header {
        .pageheader-toolbar {
            display: flex;
            flex-direction: row-reverse;
            .pageheader-tool {
                color: #050A13;
                cursor: pointer;
                &:hover {
                    color: $main-color;
                    a {
                        color: $main-color; } }
                &:not(:first-child) {
                    margin-right: 16px; }
                .iconfont {
                    color: $main-color;
                    margin-right: 8px; }
                a {
                    color: #050A13!important; } } } } }

.exports-modal {
    .exports-content-wrapper {
        font-size: $font-size-base;
        .exports-content {
            .active {
                background: $green-init;
                color: #fff;
                border: 1px solid $green-init; }
            span {

                display: inline-block;
                width: 120px; }
            button {
                margin: 0;
                font-size: $font-size-base; } }
        .download {
            .active {
                background: $green-init;
                border-color: $green-init; } }
        .board, .chart, .download, .download-button {
            @include clearfix;
            div, span {
                float: left; }
            div {
                width: 400px;
                button:not(:nth-child(4n)) {
                    margin-right: 20px;
                    button: nth-child(n+5) {
                        margin-top: 12px; } } } }
        .chart, .download, .download-button {
            margin-bottom: 24px; } } }

.pageheader-more-content {
    border-radius: 4px;
    margin-top: 4px;
    background: #fff;
    width: 150px;
    box-shadow: 0 0 14px #BABABA;

    li {
        font-size: 14px;
        padding: 12px 16px;
        cursor: pointer;
        &:not(:last-child) {
            border-bottom: 1px solid $gray-lighter; }
        &:hover {
            color: $green-base; }
        i {
            margin-right: 12px; } } }
.clearfix:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }

.clearfix {
    zoom: 1; }
.linkager-modal {
    .ant-modal-body {
        height: 400px; } }
body {
    .pageheader-select {
        z-index: 3;
        position: fixed; } }
// 手机端样式
@media screen and (max-width: 768px) {
    .page-header {
        padding: 12px;
        padding-right: 8px;
        .pageHeader-oprate-dropdown {
            display: none; }
        .filters {
            .filters-wrapper {
                padding-right: 0; } } }
    .page-header-wrapper.none {
        display: none;
        transition: 0.5s all; }
    #main {
        .page-header-wrapper {
            width: 100%; } } }
@include type-for-checkbox(#14C4A2);
