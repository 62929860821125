.mobilePicker {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: white;
    .mobilePicker_icon {
        display: flex;
        position: absolute;
        pointer-events: none;
        background-color: white;
        z-index: 2;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        white-space: nowrap;
        .iconfont {
            font-size: 14px; } }
    .am-list-item:not(:last-child) .am-list-line {
        border: none; }
    .am-list-item {
        height: 32px;
        min-height: 0px;
        padding: 0;
        background-color: transparent;
        .am-list-line {
            display: block;
            padding: 0;
            .am-list-arrow {
                display: none; }
            .am-list-extra {
                padding: 0;
                text-align: center; } } } }
@media screen and (max-width: 768px) {
    .mobilePicker {
      float: left; } }
