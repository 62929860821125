.combine-chart {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    &.withTitle {
        height: calc(100% - 56px); }
    &.combine-chart-4,
    &.combine-chart-5,
    &.combine-chart-6 {
        flex-direction: column; }
    &.combine-chart-1 {
        .combine-chart-content {
            width: 50%; } }
    &.combine-chart-2 {
        .combine-chart-content-1 {
             width: 33.3%; }
        .combine-chart-content-2 {
             width: 66.6%; } }
    &.combine-chart-3 {
        .combine-chart-content-1 {
             width: 66.6%; }
        .combine-chart-content-2 {
             width: 33.3%; } }
    &.combine-chart-4 {
        .combine-chart-content {
            height: 50%; } }
    &.combine-chart-5 {
        .combine-chart-content-1 {
             height: 33.3%; }
        .combine-chart-content-2 {
             height: 66.6%; } }
    &.combine-chart-6 {
        .combine-chart-content-1 {
             height: 66.6%; }
        .combine-chart-content-2 {
             height: 33.3%; } }
    &.combine-chart-1,
    &.combine-chart-2,
    &.combine-chart-3, {
        .combine-chart-content {
            height: 100%;
            flex-direction: column;
            &:first-child {
                border-right: 1px solid #e8e8e8;
                .chart-item {
                    padding-left: 0; } }
            &:last-child {
                .chart-item {
                    padding-right: 0; } }
            .chart-item {
                &:not(:first-child) {
                    border-top: 1px solid #e8e8e8; }
                &:first-child {
                    padding-top: 0; }
                &:last-child {
                    padding-bottom: 0; } } } }
    &.combine-chart-4,
    &.combine-chart-5,
    &.combine-chart-6, {
        .combine-chart-content {
            width: 100%;
            &:first-child {
                border-bottom: 1px solid #e8e8e8;
                .chart-item {
                    padding-top: 0; } }
            &:last-child {
                .chart-item {
                    padding-bottom: 0; } }
            .chart-item {
                &:not(:first-child) {
                    border-left: 1px solid #e8e8e8; }
                &:first-child {
                    padding-left: 0; }
                &:last-child {
                    padding-right: 0; } } } }

    .combine-chart-content {
        display: flex;
        .chart-item {
            flex: 1 1;
            overflow: hidden;
            padding: 8px;
            display: flex;
            flex-direction: column;
            &.bichart-1,
            &.bichart-21,
            &.bichart-19,
            &.bichart-13,
            &.bichart-14,
            &.bichart-20, {
                overflow: auto; }
            .chart-title {
                height: 14px;
                line-height: 14px;
                font-size: 14px;
                margin-bottom: 16px; }
            .chart-content {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: end;
                height: 100%;
                &.showTitle-chart-content {
                    height: calc(100% - 46px); }
                .widget-content {
                    height: 100%!important;
                    .chartContainer {
                        height: 100%; } }

                .commonchart {
                    height: 100%;
                    .uxs-chart {
                        height: 100%; } } } } } }
@media screen and (max-width: 768px) {
    .combine-chart {
        flex-direction: column;
        .combine-chart-content {
            width: 100%!important;
            border-bottom: none!important;
            border-right: none!important;
            flex-wrap: wrap;
            .chart-item {
                border-top: none!important;
                border-left: none!important;
                flex: 1 0 100%;
                width: 100%;
                &.bichart-20 {
                    flex: 1 0 50%; } } } } }
