@import "../../static/sass/variables.sass";

@mixin type-for-menu($main-color, $second-color, $active-color, $iconColor) {
    .algorithm-table {
        .ant-alert {
            margin-top: 16px;
            margin-bottom: 0; } }
    .operate-bar {
        display: flex;
        height: 100%;
        flex: 0 0 auto; }
    .field-operate {
        flex: 9;
        max-width: 300px;
        border-right: 1px solid #e8e8e8;
        .edit-setting-title {
            padding: 0 16px; }
        .ant-input-search {
            .ant-input {
                border-left: 0;
                border-right: 0;
                border-radius: 0;
                border-color: #e8e8e8;
                &:hover {
                    border-color: #e8e8e8; }
                &:focus {
                    border-color: #e8e8e8; } } }
        .field-list {
            margin: 8px 0;
            height: calc( 100vh - 200px);
            overflow: auto;
            .field-item {
                display: flex;
                padding: 0 24px;
                line-height: 28px;
                cursor: pointer;
                font-size: 12px;
                &.custom-field {
                    .iconfont {
                        color: orange; }
                    &:hover {
                        background-color: #ffdb99; } }
                &.normal-field {
                    .iconfont {
                        color: $iconColor; }
                    &:hover {
                        background-color:  $second-color; } }
                &.disable {
                    color: #D9D9DE;
                    cursor: not-allowed;
                    .iconfont {
                        color: #D9D9DE; }
                    &:hover {
                        background-color: transparent; } }
                .iconfont {
                    display: block;
                    margin-right: 8px;
                    font-size: 20px; }
                .filed-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-bottom: 0; } } } }
    .algorithm-config {
        width: 200px;
        flex: 11;
        border-right: 1px solid #e8e8e8;
        overflow: auto;
        .algorithm-config-tabs {
            .ant-tabs-tabpane {
                padding-top: 16px; } }
        .algorithm-item {
            padding: 0 16px 16px;
            border-bottom: 1px solid #e8e8e8;
            &:first-child {}
            padding: 0 16px 8px;
            .ant-radio-group {
                width: 100%; }
            .algorithm-radio {
                width: calc(50% - 8px);
                margin-bottom: 8px;
                text-align: center;
                border-radius: 4px;
                border-left: 1px solid #D9D9DE;
                &.ant-radio-button-wrapper-checked {
                    box-shadow: none; }
                &:nth-child(odd) {
                    margin-right: 16px; }
                &:before {
                    display: none; } }
            .ant-select {
                width: 100%; }
            .algorithm-md-select {
                width: calc(100% - 104px);
                margin-right: 16px; } }
        .algorithm-data,
        .algorithm-setting {
            height: calc(100vh - 448px);
            overflow: visible;
            padding: 16px; }
        .algorithm-setting {
            .algorithm-setting-title {
                &:not(:first-child) {
                    margin-top: 16px; } }
            .algorithm-setting-item {
                margin: 8px 0;
                display: flex;
                flex-wrap: wrap;
                .ant-checkbox-wrapper,
                .ant-radio-wrapper,
                .algorithm-setting-item-text {
                    color: #7a8493; }
                .ant-radio-wrapper {
                    display: block;
                    height: 32px;
                    lineHeight: 32px; }
                .algorithm-setting-item-text {
                    margin-right: 8px;
                    &.end-text {
                        margin-left: 8px; } }
                .ant-select,
                .ant-picker,
                .drop-box {
                    width: 100%; }
                .form-item-title {
                    margin-top: 16px;
                    margin-bottom: 8px; }
                .drop-box {
                    h5 {
                        color: #050A13; } }
                #algorithm_picker {
                    position: relative;
                    width: 100%;
                    .ant-picker-dropdown {
                        width: 270px;
                        .ant-picker-date-panel {
                            width: 270px; }
                        .halfyear-picker {
                            .picker-header {
                                line-height: 40px;
                                display: flex;
                                padding: 0 8px;
                                border-bottom: 1px solid rgba(0,0,0,.06);
                                .anticon {
                                    line-height: 36px;
                                    padding-top: 4px;
                                    flex: 0 0;
                                    color: rgba(0, 0, 0, 0.25);
                                    &:hover {
                                        color: rgba(0, 0, 0, 0.85); } }
                                .picker-header-view {
                                    flex: 1 1;
                                    text-align: center;
                                    font-weight: 500; } }
                            .picker-body {
                                display: flex;
                                flex-wrap: wrap;
                                padding: 8px;
                                .picker-cell {
                                    width: 25%;
                                    height: 66px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    &:hover {
                                        .picker-cell-inner {
                                            background-color: #f5f5f5; } }
                                    .picker-cell-inner {
                                        width: 56px;
                                        line-height: 24px;
                                        text-align: center;
                                        border-radius: 2px;
                                        &.active {
                                            background-color: $main-color;
                                            color: #ffffff; } } } } } } } } }


        .btn-content {
            padding: 16px;
            .ant-btn {
                width: 100%; } }
        .caculate-name,
        .edit-second-title {
            padding: 0 16px; }
        .algorithm-data,
        .algorithm-setting {
            padding-top: 0;
            height: auto; }
        &.simple-model {
            overflow: auto; } }
    .algorithm-result {
        width: 100%;
        height: 100%;
        padding: 16px;
        background-color: #f3f3f4;
        .algorithm-result-content {
            background-color: #ffffff;
            height: 100%;
            overflow: auto;
            padding: 16px; }
        .edit-setting-title {
            font-weight: bold!important;
            font-size: 16px;
            margin-bottom: 16px; }
        .result-msg {
            margin-bottom: 16px;
            color: #7a8493; }
        .result-item {
            .result-item-title {
                font-size: 16px;
                font-weight: bold!important; }
            .result-table-title {
                font-size: 12px;
                color: #7a8493;
                font-weight: bold!important; }
            .result-table {
                padding: 16px 0;
                .ant-table-wrapper {
                    padding-top: 8px; } } }
        .result-toolbar {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;
            .select-content {
                .ant-select {
                    &:first-child {
                        margin-right: 16px; } } }
            .show-model-btn {
                color: $main-color;
                cursor: pointer;
                line-height: 32px; } }
        .widget-content {
            height: auto!important; } }
    .algorithm-filter-modal {
        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            .type-radio-group {
                margin-bottom: 16px; }
            .ant-transfer-list {
                width: 428px;
                height: 300px; }
            .relation-filter {
                height: 300px;
                overflow: auto;
                .relation-filter-content {
                    display: flex;
                    .relation-logic {
                        display: flex;
                        align-items: center;
                        margin-right: 16px; }
                    .relation-item {
                        display: flex;
                        line-height: 32px;
                        margin-bottom: 16px;
                        &>div,
                        &>button {
                            margin: 0 4px; } } }
                .relation-origin {
                    width: 900px;
                    height: 100%;
                    padding-top: 132px;
                    text-align: center;
                    cursor: pointer;
                    .iconfont {
                        margin-right: 8px; } } }
            .date-filter {
                height: 120px;
                padding: 40px; } } }
    .update-tips {
        margin-left: 8px;
        color: $main-color;
        font-size: 12px; }
    .algorithm-lg-select {
        width: calc(100% - 32px);
        margin: 0 16px 16px; }
    .stop-operate-modal {
        .ant-modal-confirm-body {
            .anticon-info-circle {
                color: $main-color; }
            .spinnerBox {
                margin: 0 auto; } }
        .ant-modal-confirm-btns {
            display: none; } }
    .flex-option {
        display: flex;
        justify-content: space-between;
        .iconfont {
            margin-left: 8px;
            &:hover {
                color: $main-color; } } } }
@include type-for-menu(#14C4A2, rgba(20, 196, 162, 0.1), #56E2CE, #00af8d);
