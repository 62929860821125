@import "../../static/sass/variables";
body {
    .uxs-scrollbar {
        &:hover {
            .scrollbar-container {
                opacity: 1 !important; } }
        .scrollbar-container {
            background: none !important;
            opacity: 0 !important;
            width: 6px !important;
            &.active, &:hover {
                opacity: 1 !important; }
            .scrollbar {
                background: #ebeef3 !important;
                border-radius: 4px;
                width: 4px !important;
                margin-left: 0 !important; } } } }

