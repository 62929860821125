@import "./../../static/sass/variables";

.dropdownselect {
  .dropdown-menu {}
  margin: 0;
  button {
    width: 100px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 22px 0px 12px !important;
    text-align: left;
    i {
      position: absolute;
      right: 10px;
      top: 10px; } }
  .ant-btn:hover, .ant-btn:focus, .ant-btn:active {
    color: $gray-dark; } }
// 无边框的情况




