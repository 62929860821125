@import "static/sass/variables.sass";
@import "static/sass/mixin";
@import "static/sass/antd-base-style-mixin.sass";
@import "static/sass/antd-mobile-style-mixin.sass";
@import "static/sass/customboard-style.sass";
@import "static/sass/sams-style.sass";
@import "static/sass/oppo-style.sass";
@import "static/sass/rainbow-style.sass";
@import "static/sass/fullscreen-style.sass";

//全局滚动条

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #ffffff; }
::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 20px; }
::-webkit-scrollbar-track {
    border-radius: 1em;
    background-color: rgba(50,50,50,.1); }
body {
    &#sams,&#ipsos {
        @include color-for-component-base(#050A13,#7A8493,#B2B3B9,#CFCFCF,#D9D9DE,#EFF6FA,#E8F2FC,#1879D6,#2C90F1,#1879D6,#F5F5F5,#EFF6FA,rgba(31,128,222,0.2));
        @include color-for-mobile-component-base(#050A13,#7A8493,#B2B3B9,#CFCFCF,#D9D9DE,#EFF6FA,#E8F2FC,#1879D6,#2C90F1,#1879D6,#F5F5F5,#EFF6FA,rgba(31,128,222,0.2), #e6f7ff, #91d5ff);
        a {
            color: #0068C4; } }
    &#rainbow {
        @include color-for-component-base(#050A13,#7A8493,#B2B3B9,#CFCFCF,#D9D9DE,rgba(233,161,113,0.2),rgba(233,161,113,0.2),#e9a171,#e9a171,#e9a171,#F5F5F5,rgba(233,161,113,0.2), rgba(233,161,113,0.2));
        @include color-for-mobile-component-base(#050A13,#7A8493,#B2B3B9,#CFCFCF,#D9D9DE,rgba(233,161,113,0.2),rgba(233,161,113,0.2),#e9a171,#e9a171,#e9a171,#F5F5F5,rgba(233,161,113,0.2), rgba(233,161,113,0.2), rgba(233,161,113,0.2), #e9a171); }
    @include color-for-component-base(#050A13,#7A8493,#B2B3B9,#CFCFCF,#D9D9DE,#EFFAF6,#EFF6FA,#14c4a2,#38CBAE,#40AD98,#F5F5F5,#EFFAF6, rgba(20,196,162,0.2));
    @include color-for-mobile-component-base(#050A13,#7A8493,#B2B3B9,#CFCFCF,#D9D9DE,#EFFAF6,#EFF6FA,#14c4a2,#38CBAE,#40AD98,#F5F5F5,#EFFAF6, rgba(20,196,162,0.2), #EFFAF6, #14C4A2); }
body {
    margin: 0;
    padding: 0;
    font-family: $font-family-base !important;
    font-variant-numeric: tabular-nums;
    font-size: $font-size-base;
    background-color: $body-bg-color !important;
    max-width: 100%;
    color: $gray-dark !important;
    min-width: 1260px;
    a {
        color: #14c4a2;
        text-decoration: none !important; }
    &.overflow-hidden {
        overflow: hidden; }
    * {
        padding: 0;
        margin: 0; }
    form {
        margin: 0; }
    a {
        text-decoration: none;
        &:active,&:hover,&:visited,&:focus {
            text-decoration: none; }
        & > i {
            color: initial; } }
    h6 {
        font-size: $font-size-h6; }
    h5 {
        font-size: $font-size-h5; }
    h4 {
        font-size: $font-size-h4; }
    h3 {
        font-size: $font-size-h3; }
    h1, h2, h3, h4, h5, h6 {
        margin: 0; }
    label {
        font-weight: inherit; } }

li {
    list-style-type: none; }

td {
    vertical-align: middle !important;
    word-break: break-all; }

form {
    margin-top: 15px; }

input::-webkit-input-placeholder {
    color: $gray-light !important; }

input[disabled] {
    color: #D9D9DE !important; }
button[disabled]:not(.flatpickr-input) {
    color: #D9D9DE !important; }

.validation-error {
    position: absolute;
    height: 24px;
    line-height: 24px; }
.white-bg {
    background-color: white !important; }

.gray-bg {
    background-color: #f3f3f4; }

.border-bottom {
    border-bottom: 1px solid #e7eaec !important; }

.full-width {
    width: 100%; }

.overflow-x-auto {
    width: 100%;
    overflow-x: auto; }

#wrapper {
    width: 100%;
    overflow-x: hidden;
    .stop-autocompelete {
        width: 0px;
        height: 0px;
        border: none;
        position: absolute;
        top: -1000px; } }

.navbar-static-side {
    height: calc(100vh - 56px);
    float: left;
    background-color: $sidebar-bg-color;
    transition: .3s;
    overflow: auto;
    &.top {
        height: auto;
        position: fixed;
        top: $navbar-top-height;
        bottom: 0;
        left: 0;
        margin-top: 0; }
    .ant-menu {
        background-color: #ffffff; } }
.normal-sidebar {
    width: $sidebar-width;
    position: fixed; }
.collapsed-sidebar {
    width: 56px; //修改了sideBar缩紧后的宽度
    position: fixed;
    .ant-layout-sider-children {
        .page-sidebar {
            .ant-menu-submenu {}
            .ant-menu-item {
                padding: 0 18px!important; }
            .nav-label {
                display: none; } } } }

#page-wrapper {
    position: relative;
    margin-top: $navbar-top-height; }
#main {
    margin: $main-margin;
    z-index: 100 !important; }
#nav {
    margin-bottom: 0 !important; }
.navbar-default {
    box-shadow: none !important;
    background-image: none !important; }
.my-cascader {
    .ant-select-selector {
        border-radius: 4px !important; } }
.uxs-cascader-menu {
    .ant-cascader-menus {
        position: inherit; }
    .ant-cascader-menu-item {
        max-width: 400px; } }

.navbar .container {
    width: auto; }

#page-content {
    padding: $page-content-padding;
    position: relative;
    min-height: calc(100vh - 180px);
    .action {
        height: 100%;
        padding-top: 16px; }
    .operateDom-update,
    .operateDom-update-switch,
    .operateDom-add,
    .operateDom-share,
    .operateDom-delete {
        display: none;
        white-space: nowrap; }
    i {
        &.icon-shanchu,&.icon-card-bianji,&.icon-qita {
            display: none; } }
    button {
        &.ant-btn-add,&.operateDom-update,&.operateDom-add,&.operateDom-share,&.operateDom-delete {
            display: none; } }
    &.userAuthority_show-share {
        .operateDom-share {
            display: block; } }
    &.userAuthority_show-delete {
        .operateDom-delete {
            display: block; }
        i {
            &.icon-shanchu,&.operateDom-delete {
                display: block; } } }
    &.userAuthority_show-update {
        .operateDom-update {
            display: block; }
        .operateDom-update-switch {
            display: inline-block; }
        .operateDom-switch-text {
            display: none; }
        i {
            &.icon-card-bianji,&.icon-qita {
                display: block; } }
        button {
            &.operateDom-update {
                display: block; } } }
    &.userAuthority_show-add {
        .operateDom-add {
            display: block; }
        button {
            &.ant-btn-add,&.operateDom-add {
                display: block; } } }
    .page-content-main {
        padding-top: 16px; } }
#wrapper.mini-sidebar #page-wrapper {
    margin: 0 0 0 70px; }

.ant-form-vertical {
  .ant-form-item-control {
    white-space: nowrap; } }

.ant-form-item-children {
  white-space: nowrap; }

.reduce-icon {
  .condition-delete {
    visibility: hidden;
    margin-left: 8px;
    cursor: pointer;
    font-size: 20px;
    line-height: 32px;
    color: #1bb09d;
    float: left; } }

.fieldSelect {
    border-left: 1px solid #e8e8e8;
    position: relative;
    padding-left: 16px;
    .fieldSelect-item {
        display: flex;
        margin-bottom: 16px;
        .ant-select,
        .ant-calendar-picker {
            margin-right: 8px; }
        &:hover {
            .condition-delete {
                visibility: visible; } }
        .form-text {
            margin-right: 8px;
            line-height: 32px; } }
    .filterItem_logic {
        cursor: pointer;
        line-height: 18px;
        background: #effaf6;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: -7px;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 12px;
        color: #14c4a2;
        z-index: 2; } }

.common-table-layout {
    margin-top: 72px;
    &.no-margin {
        margin-top: 0; }
    &.workbench-card {
        margin-top: 8px; }
    .ant-tabs-content {
        padding-top: 16px;
        .search-header {
            padding-top: 0; } } }

.search-dropdown-header {
    display: flex;
    .search-dropdown {
        margin-right: 8px; } }

@keyframes slideInLeftFrom70 {
    from {
        width: 70px; }
    to {
        width: 220px; } }

.slideInLeftFrom70 {
    animation-name: slideInLeftFrom70; }

@keyframes slideOutRightTo70 {
    from {
        width: 220px; }
    to {
        width: 70px; } }

.slideOutRightTo70 {
    animation-name: slideOutRightTo70; }

select {
    background: linear-gradient(#fff, #eaeaea) !important; }
a, i, label, input[type=checkbox], input[type=radio], .desc, .asc, .active-desc, .active-asc {
    cursor: pointer; }

.dataToggle {
    color: $green-base; }

//通用样式
.pointer {
    cursor: pointer; }
.notAllowed {
    cursor: not-allowed;
    color: $gray; }
.require {
    &:before {
        content: '*';
        color: $orange-base;
        padding-right: 10px; } }
.collapse-button {
    font-size: 14px !important;
    color: $green-base;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%); }
.help {
    font-size: $font-size-small;
    color: $gray; }

.text-center {
    text-align: center !important; }

.text-right {
    text-align: right !important; }

.text-left {
    text-align: left !important; }

.text-green {
    color: $green-base !important; }
.text-green-light {
    color: $green-light !important; }

.text-blue {
    color: $blue-base !important; }
.text-blue-light {
    color: $blue-light !important; }
.text-orange {
    color: $orange-base !important; }

.text-gray {
    color: $gray; }

.validation-error {
    color: red; }

.list-none-item {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray;
    font-size: $font-size-h4; }

.active-desc, .active-asc, .desc, .asc {
    position: relative;
    &:after {
        font-family: "iconfont";
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        position: absolute;
        right: -20px; } }
.active-desc {
    &:after {
        color: $green-base;
        content: "\e621"; } }
.active-asc {
    &:after {
        color: $orange-base;
        content: "\e620"; } }
.desc {
    &:after {
        color: $green-base;
        opacity: 0;
        content: "\e621"; }
    &:hover:after {
        opacity: 1; } }

.asc {
    &:after {
        color: $orange-base;
        opacity: 0;
        content: "\e620"; }
    &:hover:after {
        opacity: 1; } }
.ant-popover {
    min-width: 160px !important;
    font-size: 12px !important;
    min-height: 79px;
    .ant-popover-content {}
    .ant-popover-message-title {
        color: #7a8493; }
    .ant-popover-inner {
        box-sizing: border-box; } }
// .ant-popover-buttons
//   button
//     border-color: #d9d9de !important
.ant-tabs {
    overflow: hidden; }

.ant-tooltip {
    max-width: 160px !important;
    .ant-tooltip-content {
        .ant-tooltip-arrow {
            border-color: #050A13;
            display: block; }
        .ant-tooltip-inner {
            box-shadow: none !important;
            text-align: left;
            word-break: break-all;
            font-weight: 400;
            line-height: 20px;
            font-size: 12px;
            color: #ffffff;
            background-color: #050A13; } } }
.ant-table-wrapper {
    overflow-x: auto;
    >th {
        min-width: 100px; } }
.ant-form {
    .ant-form-item {
        color: $gray-dark;
        margin-bottom: 16px; }
    .ant-form-item-label {
        text-align: left;
        label {
            color: $gray-dark;
            padding-left: 11px;
            &.ant-form-item-required {
                padding-left: 0; } } } }

// .suspension-trigger
//     .string
//         min-width: 125px
//     .number
//         min-width: 100px
//     &:hover
//         .string
//             background: #FAFAFA
//             .suspension
//                 opacity: .9 !important
//         .number,.date,.ant-table-row-expand-icon-cell
//             background: #FAFAFA
//     .suspension-wrapper
//         position: relative
//         .suspension
//             height: 100%
//             line-height: 100%
//             background: #fafafa
//             position: absolute
//             vertical-align: middle
//             padding-left: 16px
//             opacity: 0
//             right: 20px
//             top: 0
//             i
//                 position: relative
//                 margin-right: 20px
//                 display: inline-block
//                 top: 50%
//                 transform: translateY(-50%)
//                 &:hover
//                     color: $green-base
//                 &.delect-icon:hover
//                     color: red

// .detailTable
//     background-color: #e9ecee
//     td
//         border-bottom: 1px solid #fff !important

.page-tabs {
    & > .nav-tabs {
        border: none;
        max-height: 56px;
        background: white;
        border-bottom: 1px solid $gray-light;
        li {
            margin: 18px 30px 0 30px;
            a {
                color: $text-color;
                border: none !important;
                border-radius: 0;
                padding: 0 0 18px 0;
                margin: 0;
                cursor: pointer;
                &:hover {
                    background-color: transparent; } }
            &.active {
                border-bottom: 2px solid $green-base;
                & > a, & > a:hover, & > a:active, & > a:focus {
                    color: $green-base; } } } } }

.dropdown-none-bg {
    background: transparent !important;
    border: none !important;
    height: auto;
    line-height: inherit !important;
    font-size: $font-size-small;
    &:hover, &:active, &:focus, &:visited {
        box-shadow: none !important; }

    .modal {
        &.fill {
            left: auto;
            right: 0;
            .modal-dialog {
                width: 1049px;
                min-width: 1049px;
                margin: 0;
                .modal-content {
                    border: none;
                    border-radius: 0;
                    .modal-header {
                        height: 80px;
                        padding: 28px;
                        text-align: center;
                        border-bottom: 1px solid $gray-light;
                        .close {
                            float: left;
                            color: $green-base;
                            opacity: 1; } }
                    .modal-body {
                        height: calc( 100vh - 155px);
                        overflow-y: auto; }
                    .modal-footer {
                        height: 75px;
                        padding: 24px 30px;
                        text-align: center;
                        background-color: $gray-1; } } } }

        &.default {
            .modal-dialog {
                width: 800px; } }

        .modal-content {
            border-radius: 6px;
            border: none;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.36);
            .modal-header {
                border-bottom: 1px solid $gray-light;
                padding: 15px 20px; }

            .modal-body {
                padding: 40px 30px; }
            .modal-footer {
                border-top: none;
                padding: 15px 20px;
                text-align: center;
                background-color: $gray-1;
                button:not(:first-of-type) {
                    margin-left: 20px; } } } }
    .uxstoasts {
        .Toastify__toast--default {
            background: rgba(0, 0 ,0 , .8);
            color: #fff; } } }

//React Selectt
.Select-control {
    width: 132px;
    max-width: 132px;
    height: 34px;
    background: linear-gradient(#fff, #eaeaea);
    padding: 0 0 0 12px;
    line-height: 34px;

    .Select-value {
        padding-left: 12px !important;
        padding-right: 12px !important; } }

.uxs-tabs {
    overflow: hidden!important; }

@keyframes rotate {
    from {
        transform: rotate(0deg); }
    to {
        transform: rotate(360deg); } }
.rotate {
    animation: rotate 1s linear infinite;
    display: inline-block; }
.rotating-icon {
    animation: rotate .3s linear infinite;
    display: inline-block; }

.yellow {
    color: #ffd900; }

#simple-filter-wrapper {
    [data-value='add'] {
        i {
            display: none; } } }
//使react-tooltip A标签可以触发
.__react_component_tooltip {
    pointer-events: auto !important;
    &:hover {
        visibility: visible !important;
        opacity: 1 !important; } }

//修改滚动条z-index
.scrollarea .scrollbar-container {
    z-index: 990 !important; }
.scrollarea-content {
    min-height: 100%;
    top: 2px; }
//修改modal位置

.zoomIn {
    .modal-dialog {
        top: 10%;
        .modal-body {
            max-height: calc(100vh - 300px);
            overflow-y: auto; } } }

.modal {
    animation-duration: .4s !important; }

//修改antd样式
.ant-calendar-picker {
    .ant-calendar-picker-input {
        padding: 0px 12px 4px 12px; }
    .ant-calendar-picker-input[readonly],.ant-calendar-range-picker-input[readonly] {
        background: transparent!important;
        cursor: pointer!important; } }

//切换路由的loading
.router-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

//react-tooltip样式
.uxs-react-tooltip {
    width: 240px;
    word-break: break-all; }
//dropdown
//pageHeader中drodown组件

//新建drawer中slect step-dropdown的样式规范
.step-dropdown {
    box-sizing: border-box;
    float: left;
    margin-right: 8px; }

.target-select .white-cascader-picker {
    &:hover,&:active,&:focus {
        box-shadow: none !important; } }

#main {
    .row {
        margin-left: -4px;
        margin-right: -4px;
        >.col-md-3,
        >.col-md-4,
        >.col-md-6 {
            padding-left: 4px;
            padding-right: 4px;
            .card {
                min-height: 450px; } }
        >.col-md-12 {
            padding-left: 4px;
            padding-right: 4px; } }
    // .ant-row
    //     >.ant-col-md-8,
    //     >.ant-col-md-12
    //         padding-left: 4px
    //         padding-right: 4px
    //  @media (max-width: 768px)
 }    //      display: block

// 使用自定义动画的同时能调用钩子函数
body {
    .modal.fade {
        .modal-dialog {
            transition: 0s opacity !important;
            transform: translateY(0); } } }

@keyframes fadeInRightNoOpacity {
    0% {
        transform: translate3d(100%, 0, 0); }

    100% {
        transform: none; } }
.fadeInRightNoOpacity {
    animation-name: fadeInRightNoOpacity; }

.UXSModal {
    .ant-modal-body {
        padding: 40px 30px; }
    .ant-modal-footer {
        padding: 15px 20px;
        background: #f3f6fc;
        text-align: center;
        border-top: none;
        button {
            &:first-child {
                margin-right: 21px; } }
        .hide-btn {
            display: none !important; } } }
.hide {
    display: none; }
.header-space {
    .ant-modal-header {
        height: 56px; } }

.swal2-container { //弹窗插件
    .swal2-popup {
        font-size: 14px; } }
.introjs-overlay {
    position: fixed!important;
    background: rgba(52,60,73,.6)!important; }
.introjs-tooltipReferenceLayer {
    .guide-tooltip {
        padding: 30px;
        box-sizing: border-box;
        width: 300px;
        .template-tips-content {
            h5 {
                color: #050A13;
                margin-bottom: 16px; }
            p {
                color: #7a8493;
                font-size: 14px;
                line-height: 18px;
                margin: 16px 0 25px; } }
        .introjs-bullets {
            display: none; }
        .introjs-tooltipbuttons {
            text-align: center;
            margin: 0 auto;
            @include clearfix();
            .introjs-button {
                width: 80px;
                height: 32px;
                text-align: center;
                font-size: 14px;
                line-height: 30px;
                padding: 0;
                box-sizing: border-box;
                background-image: none;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                border-radius: 4px;
                text-decoration: none;
                text-shadow: none;
                cursor: pointer; }
            .introjs-skipbutton {
                margin-right: 21px;
                display: inline-block;
                border-color: $delay-init-border;
                color: $delay-init;
                background-color: #fff;
                &:hover {
                    color: $delay-hover;
                    border-color: $delay-hover; }
                &:active {
                    color: $delay-active;
                    border-color: $delay-active; } }
            .introjs-nextbutton {
                display: inline-block;
                border-color: $green-init;
                background-color: $green-init;
                color: #fff;
                &:hover {
                    background-color: $green-hover;
                    border-color: $green-hover; }
                &:active {
                    background-color: $green-active;
                    border-color: $green-active; } }
            .introjs-donebutton {
                float: none; }
            .introjs-prevbutton {
                display: none; } } } }

.target-select {}
.white-cascader-picker {
    .ant-cascader-input {}
    &:focus {
        box-shadow: none !important; } }

h5 {
    font-size: 14px;
    font-weight: 700;
    color: #4D4F5C; }
.ant-form {}
.ant-form-item-label {
    margin-right: 8px;
    label {
        &:after {
            content: '';
            display: none; } } }
.delete-confirm-title {
    line-height: 22px; }
.app-start-contain {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    &>div {
        text-align: center; }
    .app-start-description {
        h5 {
            padding: 24px 0;
            font-size: 20px;
            font-weight: 500; } } }
.color-block-content {
    span {
        vertical-align: top;
        line-height: 24px; }
    .color-block {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 8px; }
    .color-block.red {
        background-color: rgba(255, 107, 92, 0.2);
        outline: 1px solid #FF6B5C;
        outline-offset: -1px; }
    .color-block.green {
        background-color: rgba(20, 196, 162, 0.2);
        outline: 1px solid #14C4A2;
        outline-offset: -1px; } }
.children-form-item.ant-row {
    width: 600px;
    &:last-of-type {
        margin-bottom: 0; }
    .ant-form-item-label {
        width: 20%;
        vertical-align: top; }
    .ant-form-item-control-wrapper {
        width: 72%;
        display: inline-block; } }
.survey-modal {
    .children-form-item.ant-row {
        margin-left: 160px; } }
.permision-select.white-select {
    background-color: #fff;
    .ant-select-tree {
        li {
            ul {
                padding-left: 24px; } } } }
.icon-form-item {
    &:hover {
        .hide-icon {
            display: inline-block; } }
    .hide-icon {
        display: none; } }
.table-long-text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: right; }
.xs-select {
    width: 48px!important; }
.sm-select {
    width: 96px!important; }
.md-select {
    width: 120px!important; }
.lg-select {
    width: 240px!important; }
.md-multiple-select {
    min-width: 120px!important;
    width: auto!important; }

.xs-input {
    width: 96px!important; }
.sm-input {
    width: 120px!important; }
.md-input {
    width: 240px!important; }
.lg-input {
    width: 320px!important; }
.table-select {
    .ant-select-selection--single {
        height: 24px;
        .ant-select-selection__rendered {
            line-height: 22px; } } }
.block-radio {
    .ant-radio-wrapper {
        display: block;
        padding: 8px 0;
        .ant-select {
            margin: 0 4px; } } }
.filterItem_logic {
    cursor: pointer;
    line-height: 18px;
    background: #effaf6;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    color: #14c4a2;
    z-index: 2; }

.uxs-pointer {
    cursor: pointer;
    &:hover {
        color: $main-color!important; } }
.main-color-icon {
    color: $main-color!important; }
.form-item-children {
    .form-delete-btn {
        cursor: pointer;
        font-size: 20px;
        line-height: 32px;
        color: #1bb09d;
        visibility: visible; }
    &:hover {
        .form-delete-btn {
            visibility: visible; } } }
.nowrap-radio-group {
    white-space: nowrap; }
.ant-collapse-borderless {
    background-color: transparent; }

.delete-modal {
    .ant-modal-body {
        padding: 24px 64px;
        .delete-tips {
            display: flex;
            color: #7a8493;
            div {
                &:nth-child(1) {
                    margin-right: 16px; } } }
        .delete-text {
            color: red; } } }
.new-cemyun-card.card {
    .card-content {
        padding: 20px; } }
.select-group {
    &:not(:first-child) {
        margin-left: 8px; } }
.uxs-new-select-option {
    max-width: 500px;
    white-space: normal!important; }
.cemyun-chart-tooltip {
    z-index: 9!important; }
.block {
    display: block!important; }
.single-card {
    margin-top: 16px; }
.drawer-btn-content {
    >button:not(:last-child) {
        margin-right: 16px; } }
.x6-graph {
    .x6-graph-svg {
        overflow: visible; }
    body {
        background: transparent !important;
        min-width: auto; } }
.ant-popover-message-title {
    width: 240px; }
.dropdown-menu {
    &:not(.ant-dropdown-hidden) {
        display: block!important; } }
.Resizer {
    &.vertical {
        width: 11px;
        margin: 0 -6px;
        cursor: col-resize;
        z-index: 1;
        box-sizing: border-box;
        background-clip: padding-box;
        flex: 0 0 auto; } }
.audit-status-content {
    display: flex;
    align-items: center;
    .status-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 8px;
        &.blue {
            background-color: #02a5fe; }
        &.green {
            background-color: #14c4a2; }
        &.red {
            background-color: #f67c70; }
        &.orange {
            background-color: #f2aa62; } } }
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
    display: none!important; }

.w-e-text-container {
    background-color: rgba(217, 219, 223, .5)!important; }


.Authorization {
    display: flex;
    align-items: center;
    flex-direction: column; }


.permissions-detail-tab {
    .ant-tabs-content {
        height: calc(100vh - 359px);
        padding-top: 16px;
        .ant-tabs-tabpane {
            display: flex;
            flex-direction: column;
            .ant-btn-primary {
                margin-bottom: 16px;
                float: right; }
            .ant-input-search {
                margin-bottom: 16px; }
            .permissions-field-operate {
                width: 100%;
                flex-direction: row;
                margin-bottom: 16px; }
            .expanded-table {
                width: 100%;
                padding-top: 0;
                .feature-operate-content {
                    min-width: 512px;
                    display: flex;
                    justify-content: start; }
                .feature-check {
                    width: 120px;
                    text-align: left;
                    &:not(:first-child) {
                        margin-left: 8px; } }
                .permissions-table-operate {
                    .ant-checkbox-wrapper {
                        margin-right: 64px; } } }
            .ant-table-tbody {
                >tr {
                    >td {
                        line-height: 24px; } } } } } }

.operate-content {
    display: flex;
    margin-top: 16px;
    .operate-item {
        &:not(:last-child) {
            margin-right: 24px; } }
    .flex-operate-item {
        display: flex;
        line-height: 32px; } }
.closed-alert {
    margin-top: 10px;
    margin-bottom: -24px; }
.takeAllContainer {
    display: flex;
    justify-content: space-between;
    .take-text {
        color: #7A8493;
        .take-number {
            padding: 8px; } }
    .takeAll {
        &:hover {
            cursor: pointer; } } }
.warning-handle {
    &:not(:last-child) {
        padding-right: 5px; }
    &:hover {
        cursor: pointer; } }
.warning-mobile-handle {
    &:not(:last-child) {
        margin-right: 5px; } }
.warning-handle-btns {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px; }
.warning-handle-select {
    height: 24px !important;
    .ant-select-selection-selected-value {
        line-height: 24px; }
    .ant-select-selection--single {
        height: 24px; }
    .ant-select-dropdown {
        z-index: 20001 !important; } }

.warning-handle-btn {
    width: 50px !important;
    min-width: 0 !important; }
.warning-handle-cancel-btn {
    margin-right: 10px;
    border: 1px solid rgba(0, 0, 0, 0.07) !important; }

.warning-detail-button {
    margin-right: 20px !important; }
.warning-status-tag {
    display: inline-block;
    height: 24px;
    line-height: 22px;
    padding: 0 8px;
    border-radius: 4px;
    &.red {
        color: #f67c70;
        border: 1px solid #f67c70; }
    &.orange {
        color: #f2aa62;
        border: 1px solid #f2aa62; }
    &.green {
        color: #14c4a2;
        border: 1px solid #14c4a2; }
    &.blue {
        color: #02a5fe;
        border: 1px solid #02a5fe; }
    &.gray {
        color: #b2b3b9;
        border: 1px solid #b2b3b9; } }
.warning-level-content {
    display: flex;
    align-items: center; }
.icon-yujing {
    &.high {
        color: #f67c70; }
    &.middle {
        color: #f2aa62; }
    &.low {
        color: #14c4a2; } }
.personal-workbench {
    .exclusion {
        padding-top: 0;
        .card {
            margin-top: 0;
            .card-content {
                padding: 0; } } } }
.operate-content {
    display: flex;
    margin-top: 16px;
    .operate-item {
        &:not(:last-child) {
            margin-right: 24px; } }
    .flex-operate-item {
        display: flex;
        line-height: 32px; } }
.table-content-icon {
    margin-right: 8px;
    font-size: 18px;
    vertical-align: middle; }
.icon-gao {
    color: #FF6B5C; }
.icon-zhong {
    color: #FFA136; }
.icon-di {
    color: #8750FF; }
.warning-card {
    .card-content {
        padding: 20px 16px;
        span {
            color: #7A8493; }
        .warning-info-status {
            color: #FF6B5C; }
        .warning-info-other-item {
            &:not(:last-child) {
                margin-right: 24px; } }
        .warning-info-other {
            display: flex;
            justify-content: space-between;
            .warning-info-other-content {
                display: flex; } }
        .warning-info {
            border-bottom: 1px solid #f3f3f4; }
        .warning-survey {
            padding: 20px 0 0;
            margin-bottom: 0; } } }
.warning-survey-item {
    &:not(:last-child) {
        margin-bottom: 12px; }
    .warning-survey-question {
        margin-bottom: 8px;
        color: #7A8493;
        span {
            display: inline-block;
            width: 48px; } }
    .warning-survey-answer {
        margin-bottom: 0; } }
.warning-user {
    margin-top: 8px!important;
    .warning-userInfo {
        padding-top: 16px;
        display: flex;
        .warning-user-avatar {
            padding-right: 16px;
            img {
                width: 80px;
                height: 80px;
                border-radius: 50%; } }
        .warning-userInfo-detail {
            width: calc(100% - 96px); }
        .warning-userInfo-item {
            &:not(:last-child) {
                margin-bottom: 14px; }
            &:nth-child(1) {
                padding-top: 20px; }
            .warning-info-other-item {
                word-break: break-all; }
            .warning-userInfo-item-content {
                .warning-info-other-item {
                    display: inline-block; } } } } }
.warning-visitor-info-content {
    .card-content {
        height: 600px; } }
.ant-btn-primary {
    a {
        color: #ffffff!important; } }
@media screen and (max-width: 768px) {
    .operate-content {
        position: relative;
        margin-top: 0.32rem;
        .operate-item.md-input {
            margin-right: 0; }
        .operate-item.lg-select {
            width: 100%!important; } }
    .warning-list-card {
        position: relative;
        .card-content {
            padding-top: 0.3rem;
            .filter-content {
                display: flex;
                justify-content: flex-end;
                height: 0.28rem;
                position: absolute;
                right: 16px;
                top: 16px;
                &>div {
                    &:not(:last-child) {
                        margin-right: 0.16rem; } } } } }
    .mobileCalander {
        width: 100%; }
    .warning-list {
        .am-list-body {
            &::before {
                display: none!important; } }
        .warning-list-item {
            padding: 0.4rem 0;
            font-size: 0.28rem;
            border-bottom: 0.02rem solid #E7E7EB;
            width: 100%;
            .warning-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.3rem;
                line-height: 0.48rem;
                .warning-status {
                    display: flex;
                    .table-content-icon {
                        font-size: 0.48rem;
                        margin-right: 0.2rem; }
                    span {
                        font-size: 0.32rem; } }
                .warning-time {
                    font-size: 0.24rem;
                    color: #B2B3B9; } }
            .warning-topic-name {
                line-height: 0.48rem;
                margin-bottom: 0.32rem; }
            .warning-detail {
                display: flex;
                justify-content: space-between;
                vertical-align: bottom;
                .warning-detail-item {
                    color: #7A8493;
                    line-height: 0.4rem;
                    margin-bottom: 0.16rem;
                    display: flex;
                    width: 80%;
                    .warning-detail-score {
                        margin-right: 0.48rem;
                        width: 30%;
                        word-break: break-all; }
                    .warning-detail-role {
                        width: 70%;
                        word-break: break-all; } }
                .warning-detail-link {
                    width: 1.2rem;
                    a {
                        color: #14C4A2; } }
                .warning-detail-item-value {
                    color: #050A13; } }
            .warning-rule-detail.warning-detail {
                flex-direction: column; } } }
    .warning-card {
        .card-content {
            padding: 0.4rem;
            .warning-info-level {
                height: 0.48rem;
                font-size: 0.32rem;
                margin-bottom: 0.32rem;
                .iconfont {
                    font-size: 0.48rem;
                    margin-right: 0.16rem; } }
            .warning-info-other {
                font-size: 0.28rem;
                line-height: 0.4rem;
                flex-direction: column;
                .warning-info-other-content {
                    flex-direction: column;
                    .warning-info-other-item {
                        margin-bottom: 0.16rem; } } }
            .warning-survey {
                padding-top: 0.4rem;
                font-size: 0.28rem;
                .warning-survey-item {
                    &:not(:last-child) {
                        margin-bottom: 0.24rem; }
                    .warning-survey-question {
                        margin-bottom: 0.16rem; } } } } }
    .warning-user {
        .warning-userInfo {
            flex-direction: column;
            .warning-user-avatar {
                width: 1.6rem;
                height: 1.6rem; }
            .warning-userInfo-item {
                padding-top: 0.16rem;
                &:nth-child(1) {
                    padding-top: 0.16rem; }
                &:not(:last-child) {
                    margin-bottom: 0.32rem; }
                .warning-info-other-item {
                    &:not(:last-child) {
                        margin-bottom: 0.16rem; } } } } }
    .warning-visitor-info-content {
        .card-content {
            height: auto; } } }




@media screen and (max-width: 768px) {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0.5em !important; }
    #main {
        margin-top: 56px !important;
        margin-left: 60px !important; }
    #page-wrapper {
        margin-top: 0; }
    .app-start-contain {
        padding: 0.48rem;
        .app-start-description {
            h5 {
                font-size: 0.5rem; }
            ul {
                li {
                    list-style-type: disc;
                    p {
                        font-size: 0.3rem;
                        text-align: left; } } } }
        .app-start-btn {
            .ant-btn {
                font-size: 0.4rem;
                line-height: 1rem;
                height: 1rem;
                padding: 0 0.4rem;
                border-radius: 0.5rem; } } }

    // 隐秘按钮以及可操作icon
    i {
        &.icon-shanchu,&.icon-card-bianji,&.icon-close,&.icon-qita {
            display: none !important; } }
    button {
        &.ant-btn-report,&.ant-btn-add,&.operateDom-update,&.operateDom-add,&.operateDom-share,&.operateDom-delete {
            display: none !important; } }
    .anticon {
        &.anticon-question-circle {
            display: none !important; } }
    .mask {
        top: 0;
        left: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.2);
        z-index: 3; }
    .md-input {
        width: 100%!important;
        height: 0.63rem; }
    .mobile-picker-title {
        font-size: 0.28rem;
        i {
            font-size: 0.28rem;
            margin-right: 0.16rem; } } }
@media screen and (max-width: 576px) {
    #main {
        margin-left: 0px !important; } }
@media screen and (max-width: 1280px) {
    #main {
        .report-board {
            width: 100%; } } }
