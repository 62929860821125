$text-color: #050A13;
$second_text-color: #7A8493;
$title-size: 16px;
$title-weight: bold;
$padding: 16px;
.analyseLayout {
    .analyseLayout_backBtn {}
    .analyseLayout_title {
        background-color: #050A13;
        width: 100%;
        min-width: 1080px;
        height: 56px;
        padding: 0 16px 0 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .analyseLayout_title-analyse {
            .UXSNewSelect {
                .ant-select-selection-selected-value {
                    color: #ffffff;
                    a {
                        color: none;
                        pointer-events: none; } }
                .ant-select-enabled {
                    .ant-select-arrow-icon {
                        color: #ffffff !important; } } } }
        .analyseLayout_title-select {
            position: absolute;
            bottom: -40px;
            left: 16px; }
        .analyseLayout_title-back {
            color: #ffffff;
            i {
                color: #ffffff;
                font-size: 24px; } }
        .name-content {
            flex: 1;
            position: relative;
            margin: 0 32px 0 16px;
            height: 100%;
            .name-input {
                width: 100%;
                padding: 0;
                position: absolute;
                top: 12px;
                left: 0;
                font-size: 18px;
                color: #ffffff!important;
                background-color: transparent;
                border: none; }
            .name-text {
                visibility: hidden;
                font-size: 18px; } }
        .analyseLayout_title-btnGroup {
            display: flex;

            .analyseLayout_title-btnGroup-btn {
                display: flex;
                cursor: pointer;
                align-items: center;
                i {
                    margin-right: 5px; }
                &:not(:first-child) {
                    margin-left: 18px; }
                &:hover,&:active,&:focus {
                    color: #FFFFFF; } } } }
    .analyseLayout_content {
        height: calc(100vh - 57px);
        padding: 0px 16px;
        display: flex;
        .SplitPane {
            height: calc(100vh - 57px)!important; }
        .operate-bar {
            display: flex;
            height: 100%;
            flex: 0 0 auto;
            background-color: #fff; }


        .analyseLayout_content-from {
            flex: 9;
            border-right: 1px solid #D9D9DE; }
        .analyseLayout_content-chart {
            padding: 16px;
            .analyseLayout_content-chart-toolbar {
                display: flex;
                margin-bottom: 8px;
                .ant-calendar-picker-input {
                    width: 250px;
                    margin-right: 8px;
                    background: transparent;
                    input {
                        color: $text-color;
                        cursor: pointer !important;
                        background-color: transparent !important; }
                    .ant-calendar-picker-icon {
                        color: $text-color; } } }
            .card {
                margin: 0 !important;
                .card-title {
                    h5 {
                        font-size: 16px;
                        color: $text-color; } }
                .card-content {
                    min-height: 550px;
                    height: calc(100vh - 96px);
                    .analyseLayout_content-chartGroup {
                        height: 100%;
                        padding-top: 16px;
                        .sampleTotal {
                            text-align: right;
                            &:first-child {
                                margin-bottom: 8px; } }
                        .analyseLayout_content-chartGroup-title {
                            font-size: 16px;
                            color: $text-color;
                            display: flex;
                            justify-content: space-between;
                            .analyseLayout_content-chartGroup-title-tool {
                                color: #7a8493;
                                cursor: pointer;
                                i {
                                    margin-right: 4px; }
                                .analyseLayout_content-chartGroup-title-tool-text {
                                    font-size: 12px; }
                                &:hover {
                                    color: #14C4A2; } } }
                        .analyseLayout_content-chartGroup-chart {
                            height: calc(100% - 64px);
                            &.scroll {
                                overflow-y: auto; }
                            .ant-calendar-picker {
                                margin-top: 8px;
                                .ant-calendar-range-picker-separator {
                                    background-color: #fff;
                                    border-radius: 4px; } } }
                        .commonchart {
                            height: 100%;
                            .uxs-chart {
                                height: 100%;
                                .chart-content {
                                    height: 100%; } } } }
                    .condition-delete {
                        cursor: pointer;
                        color: #1bb09d; }
                    .childrenFilter {
                        .childrenFilter-item {
                            width: 470px;
                            margin-bottom: 12px;
                            line-height: 0;
                            display: flex;
                            justify-content: flex-start;
                            .childrenFilter-formula {
                                height: 32px; }
                            .childrenFilter-dimValue {
                                .ant-select {
                                    width: 240px !important; } }
                            .condition-delete {
                                visibility: hidden;
                                cursor: pointer;
                                font-size: 20px;
                                line-height: 32px;
                                color: #1bb09d; }
                            .unit {
                                line-height: 32px;
                                margin-left: 4px; }
                            &:hover {
                                .condition-delete {
                                    visibility: visible; } }
                            .ant-cascader-picker {
                                width: 96px;
                                height: 32px;
                                margin-right: 8px; }
                            .ant-select-show-search {
                                width: 200px !important; }
                            .ant-select-enabled {
                                width: 96px; }
                            .ant-select {
                                margin-right: 8px; }
                            button {
                                margin-right: 4px; } } } } } }
        .analyseLayout_content-config {
            flex: 11;
            .ant-collapse {
                &.ant-collapse-borderless {
                    background-color: transparent; }
                .ant-collapse-item {
                    padding-left: 16px;
                    padding-bottom: 16px;
                    .ant-collapse-header {
                        color: #7A8493;
                        padding: 16px 0px 0px 15px;
                        .ant-collapse-arrow {
                            top: 70%;
                            left: 0px; } }
                    .ant-collapse-content {
                        >.ant-collapse-content-box {
                            padding: 0px 0 0px 16px;
                            .AnalyseLayoutConfig-childrenPanel-item {
                                &:last-child {
                                    .AnalyseLayoutConfig-childrenPanel-item-configCop {
                                        margin: 0px; } }
                                .AnalyseLayoutConfig-childrenPanel-item-configName {
                                    color: #7A8493;
                                    font-size: 12px;
                                    margin: 8px 0px; }
                                .AnalyseLayoutConfig-childrenPanel-item-configCop {
                                    margin: 8px 0px;
                                    .ant-checkbox + span {
                                        padding-right: 0; } } } } } } } } } }

.analyseLayoutModal {
    display: flex;
    flex-direction: column;
    .analyseLayoutModal-item {
        display: flex;
        &:not(:last-child) {
            margin-bottom: 24px; }
        p {
            width: 100px;
            text-align: right;
            margin: 0px 8px 0px 0px; }
        .ant-input {
            width: 360px; } } }
@media screen and(max-width:768px) {
    .analyseLayout {
        .analyseLayout_content {
            .card {
                .card-content {
                    height: fit-content; } } } } }
