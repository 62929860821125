@import "../../../static/sass/variables";
@import "../../../static/sass/mixin";
.avatar-uploader {
    width: auto!important; }
.ant-select-dropdown-menu-item {
    span {
        a {
            margin-left: 8px;
            color: #000000; } } }
.survey-form-wrapper {
    width: 100%;
    margin: 0;
    .survey-select {
        .ant-select-selection-selected-value {
            .pull-right {
                display: none; } } }
    .surveyForm-select {
        .ant-select {
            width: 240px; } }
    .survey-header {
        margin-bottom: 24px;
        p {
            word-break: break-all; } }
    .trigger-service-name {
        width: 480px; }

    .survey-select-wrapper {
        overflow: visible !important; }
    .my_survey-list-wrapper {
        display: inline-block;
        .ant-select-selection-selected-value {
            span {
                display: none; } } }
    .my_survey-list-wrapper, .trigger-action-value-wrapper, .topic-list-wrapper {
        .rbt {}
        display: inline-block;
        .rbt-input-hint-container {
            .form-control {}
            width: 480px; } }

    .survey-list-reload {
        margin-left: 4px;
        .iconfont {
            color: #7A8493;
            font-size: 16px; } }
    .survey-add {
        margin-left: 8px;
        color: $orange-base;
        text-decoration: underline; }

    .survey-form-footer {
        button:not(:last-child) {}
        margin-right: 30px; }

    .btn-filter-collapse {
        display: inline-block;
        margin-bottom: 24px; }

    .fixed-survey-display {
        .ant-radio-button-wrapper {
            width: 96px;
            text-align: center; } }
    .fixed-survey-style {
        display: flex;
        align-items: center;
        .setUpColorInput {
            margin-right: 32px; } }
    .survey-form-preview {
        display: flex;
        .survey-form-preview-label {
            width: 25%;
            margin-right: 8px; }
        .survey-form-preview-wrapper {
            width: 72%;
            .survey-form-preview-btn {
                display: flex;
                align-items: center;
                width: 60px;
                min-height: 60px;
                padding: 9px;
                border: 1px solid #d9d9de;
                border-radius: 6px;
                text-align: center;
                line-height: 20px;
                cursor: pointer;
                &:hover {
                    box-shadow: 0px 0px 10px #dbdfe5; }
                .survey-form-preview-btn-text {
                    width: 100%;
                    &:focus {
                        background-color: #000!important; } } }
            .survey-form-preview-title {
                margin-bottom: 8px;
                font-size: 16px; }
            .copyCode {
                color: #7a8493; }
            .copy-btn {
                width: 64px;
                margin-left: 640px;
                float: right;
                text-align: right;
                margin-bottom: 16px; } } }
    .children-form-group {
        &:last-of-type {
            margin-bottom: 0; }
        .form-children {
            width: 240px; } }
    .rigger-action-type-wrapper {
        margin-bottom: 16px; }
    .trigger-action-value-wrapper {
        .rbt {}
        width: 480px; }
    .input-validator {
        width: 480px; }
    .hiddenSurveyArea {
        display: none; }
    .childrenFilter_add {
        visibility: visible;
        top: -40px;
        right: 360px; }
    .invite-time {
        .ant-time-picker {
            margin-left: 8px; } }
    .form-group-title {
        display: flex;
        font-weight: bold;
        .title-text {
            margin-right: 8px; }
        .title-tips {
            font-size: 12px;
            color: #b2b3b9;
            margin-right: 8px; }
        .icon-chenggong {
            color: #14c4a2; }
        .icon-tishiicon {
            color: #ff6b5c; } }
    .form-row {
        display: flex;
        align-items: center;
        .form-item-text {
            width: 21%; }
        .form-item-children-text {
            width: 80px; }
        .form-item-text-tips,
        .icon-bangzhu {
            color: #b2b3b9; }
        .icon-bangzhu {
            margin-left: 8px; }
        .form-item-require {
            color: #f5222d;
            margin-right: 4px; }
        .ant-select,
        .ant-input-number,
        .form-item-text-tips,
        .form-item-text,
        .form-item-children-text,
        .ant-switch {
            margin-right: 8px; } } }

.ant-modal-content {
    .ant-modal-title,.ant-btn {
        color: #050A13; }
    .ant-btn {
        border-color:#D9D9DE !important {} }
    .ant-modal-body {
        color: #7A8493; }
    .ant-btn-primary {
        color: #ffffff !important;
        border: none !important; } }
.survey-filter {
    .disturb-rule {
        padding: 8px 0 0 16px; }
    .ant-select-auto-complete {
        width: 200px !important; }
    .ant-cascader-picker,.ant-select-enabled {
        width: 96px; }
    .large-select {
        width: 180px; }
    .disturb-list {
        border-left: 1px solid #D9D9DE;
        padding: 8px 16px;
        margin-top: 8px;
        position: relative;
        .logic {
            position: absolute;
            left: -6px;
            top: 40%;
            display: inline-block;
            background-color: #ffffff;
            color: #14c4a2;
            font-size: 12px; }
        .disturb-item {
            margin-bottom: 8px;
            width: 600px;
            &:hover {
                .delete-icon {
                    display: block; } }
            .delete-icon {
                float: right;
                font-size: 18px;
                color: #14c4a2;
                line-height: 32px;
                display: none; } } } }
.update-status-warning-modal {
    h5 {
        font-weight: 600; }
    .ant-radio-wrapper {
        margin-top: 16px; } }
