.global-control-modal {
    .ant-modal-body {
        display: flex;
        padding: 0;
        height: 560px;
        .controller-list {
            width: 300px;
            border-right: 1px solid #e8e8e8;
            .controller-header {
                display: flex;
                padding: 0 8px;
                line-height: 48px;
                justify-content: space-between;
                font-size: 16px;
                border-bottom: 1px solid #e8e8e8; }
            .controller-menu {
                border-right: none;
                height: 511px;
                overflow: auto;
                .ant-menu-item {
                    display: flex;
                    justify-content: space-between;
                    .controller-menu-title {
                        width: 200px;
                        overflow: hidden;
                        text-overflow:ellipsis {}
                        white-space: nowrap; }
                    .controller-menu-tools {
                        display: none;
                        i {
                            margin-left: 8px; } }
                    .ant-input {
                        margin: 4px 0; }
                    &:hover {
                        .controller-menu-tools {
                            display: block; } } } } }
        .config-form {
            width: calc(100% - 300px);
            .config-form-item {
                padding: 0 8px 8px;
                .form-item-header {
                    display: flex;
                    line-height: 48px;
                    justify-content: space-between;
                    font-size: 16px;
                    color: #7A8493; }
                .checkbox-list {
                    overflow: auto;
                    height: 492px;
                    .checkbox-item {
                        padding: 8px 0; } }
                .ant-form-item-label {
                    text-align: left!important; } } } } }
.hover-icon {
    color: #050A13;
    &:hover {
        color: #14c4a2; } }
