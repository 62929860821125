// table样式
@import '../../containers/CommonTable/index.sass';
// 桑基图样式
@import '../../containers/AnalyseSurvey/SurveyChart/index.sass';
@import './component-style-mixin.sass';
@import '../../component/OrderNumber/index.sass';
// 自定义看板配色管理
//黑色主题色
$dark-main: #14C4A2;
// 一级色
$dark-text-first: #D6D6D6;
// 二级色
$dark-text-second: #858585;
// 边框
$dark-border: #3D3D3D;
// 禁用状态
$dark-disabled: #3D3D3D;
$dark-calendar-text-disabled: #888888;
// 背景色
$dark-bg: #000000;
// 交互一级色
$dark-mutual-first: #2E2E2E;
// 二级导航背景和卡片背景
$dark-menu-card-second : rgba(23, 23, 23, .9);
// 一级菜单以及modal以及表格头部的颜色
$dark-menu-modal-tablehead: #212121;
//分割线
$dark-splitLine: #262626;


$blue-main: #3D63CC;
$blue-text-first: #D3D6E0;
$blue-text-second: #7A8299;
$blue-border: #4E5466;
$blue-disabled: #4E5466;
$blue-calendar-text-disabled: #747780;
$blue-bg:  #151C2E;
$blue-mutual-first: #374057;
$blue-menu-modal-tablehead: #2A3042;
$blue-menu-card-second : #20283D;
//分割线
$blue-splitLine: #2F364A;

body {

    &.customBoard-dark {
        color: $dark-text-first !important;
        background-color: $dark-bg !important;
        .customBoard-desc-richTextBox {
            h1,h2,h3,h4,h5,p,span {
                color: $dark-text-first !important;
                font {
                    color: $dark-text-first !important; } } }
        .eventTable_toolbar {
            span {
                color: $dark-text-first !important; } }
        .eventChart_toolbar {
            label {
                span {
                    color: $dark-text-first !important; } } }
        .page-header-wrapper,
        .ant-calendar-range-picker-separator {
            background-color: $dark-bg; }
        @include type-for-pageheader-dropdown( $dark-menu-modal-tablehead,$dark-mutual-first,$dark-text-second,$dark-text-first);
        // $bg,$hover-bg,$text,$hover-text,$main,$border,$disabled
        @include type-for-calendar( $dark-menu-modal-tablehead,$dark-mutual-first,$dark-text-second,$dark-text-first,$dark-main,$dark-border,$dark-disabled,$dark-text-second,$dark-bg,$dark-calendar-text-disabled);
        @include type-for-modal( $dark-menu-modal-tablehead,$dark-mutual-first,$dark-text-second,$dark-text-first,$dark-main,$dark-border,$dark-disabled,$dark-text-second,$dark-bg);
        @include style-for-commontable($dark-text-second,$dark-menu-modal-tablehead,$dark-text-first,$dark-mutual-first,$dark-splitLine,$dark-main);
        @include type-for-pagination($dark-text-second,$dark-text-first,$dark-disabled,$dark-main,$dark-border);
        @include style-for-surveySentiment($dark-text-second,$dark-menu-modal-tablehead,$dark-text-second,$dark-main);
        @include type-for-checkbox($dark-main);
        @include type-for-orderNumber($dark-main,$dark-border,$dark-text-first);
        .page-header-wrapper {
            @include type-for-pageheader-button($dark-border,$dark-text-second,$dark-text-second); }
        .customBoard-null-data {
            background-color: $dark-menu-card-second; }
        .ant-select-sm {
            &.ant-select-enabled {
                .ant-select-selection {
                    border: none !important;
                    color: $dark-text-first; }
                .ant-select-arrow-icon {
                    color: #D3D6E0; } } }
        .ant-select-enabled {
            .ant-select-selection {
                color: $dark-text-first; }
            .ant-select-arrow-icon {
                color: $dark-text-first; } }
        .ant-empty-description {
            color: $dark-border; }
        .ant-empty-image {
            opacity: 0.3; }
        .ant-skeleton {
            .ant-skeleton-content {
                .ant-skeleton-paragraph {
                    li {
                        background: $dark-border !important; } } } }
        .sankey-chart {
            .path-level-wrapper {
                .path-level {
                    span {
                        color: $dark-text-first !important; } } } } }
    &.customBoard-blue {

        color: $blue-text-first !important;
        background-color: $blue-bg !important;
        .customBoard-desc-richTextBox {
            h1,h2,h3,h4,h5,p,span,ul li {
                color: $blue-text-first !important;
                font {
                    color: $blue-text-first !important; } } }
        .ant-checkbox-checked.ant-checkbox-inner {
            border-color {} }
        .eventTable_toolbar {
            span {
                color: $blue-text-first !important; } }
        .eventChart_toolbar {
            label {
                span {
                    color: $blue-text-first !important; } } }
        a {
            color: $blue-text-first !important; }
        .page-header-wrapper,
        .ant-calendar-range-picker-separator {
            background-color: $blue-bg; }
        @include type-for-pageheader-dropdown($blue-menu-modal-tablehead, $blue-mutual-first,$blue-text-second,$blue-text-first);
        // $bg,$hover-bg,$text,$hover-text,$main,$border,$disabled
        @include type-for-calendar($blue-menu-modal-tablehead, $blue-mutual-first,$blue-text-second,$blue-text-first,$blue-main, $blue-border,$blue-disabled,$blue-text-second,$blue-bg,$blue-calendar-text-disabled);
        @include type-for-modal($blue-menu-modal-tablehead, $blue-mutual-first,$blue-text-second,$blue-text-first,$blue-main, $blue-border,$blue-disabled,$blue-text-second,$blue-bg );
        @include type-for-sankey(#238BDB,#2AC5FF,#85D9FF,#14c4a2,#71EB8E,#C3FFB1,#13a9c2,#23D6DC,#62EDF2,#F37623,#F9A360,#FEDA6E);
        @include style-for-commontable($blue-text-second,$blue-menu-modal-tablehead,$blue-text-first, $blue-mutual-first, $blue-splitLine,$blue-main);
        @include type-for-pagination($blue-text-second,$blue-text-first,$blue-disabled,$blue-main,$blue-border);
        @include style-for-surveySentiment($blue-text-second,$blue-menu-modal-tablehead,$blue-text-second,$blue-main);
        @include type-for-checkbox($blue-main);
        @include type-for-orderNumber($blue-main,$blue-border,$blue-text-first);
        .page-header-wrapper {
            @include type-for-pageheader-button( $blue-border,$blue-text-second,$blue-text-second); }
        .customBoard-null-data {
            background-color: $blue-menu-card-second; }
        .ant-select-sm {
            &.ant-select-enabled {
                .ant-select-selection {
                    border: none !important;
                    color: $blue-text-first; }
                .ant-select-arrow-icon {
                    color: $blue-text-first; } } }
        .ant-select-enabled .ant-select-selection {
            color: $blue-text-first;
            .ant-select-arrow-icon {
                color: $blue-text-first; } }
        .ant-empty-description {
            color: $blue-border; }
        .ant-empty-image {
            opacity: 0.25; }
        .ant-skeleton {
            .ant-skeleton-content {
                .ant-skeleton-paragraph {
                    li {
                        background: $blue-border !important; } } } }
        .sankey-chart {
            .path-level-wrapper {
                .path-level {
                    span {
                        color: $blue-text-first !important; } } } } } }


