.survey-item {
    .survey-question {
        background: #EFF6FA;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        color: #7A8493;
        margin-bottom: 8px;
        span {
            display: inline-block;
            width: 48px;
            text-align: right; } }
    .survey-answer {
        padding-left: 48px;
        font-size: 12px;
        margin-bottom: 24px; } }
