@import '../../static/less/variable.less';

.textAlignCenter {
  text-align: center !important;
}

.textAlignRight {
  text-align: right !important;
}

.textAlignLeft {
  text-align: left !important;
}

.hide {
  display: none !important;
}

/*** Table ***/

.searchFilterDropdown {
  background-color: @white;
  padding: 8px;
  border-radius: 6px;
  box-shadow: @popover-box-shadow;
}

.unSelected {
  color: @codemirror-placeholder;
}
// @rich-text

.highlight {
  color: @primary-color;
}

/*** Form ***/

.formStepArea {
  padding: 0 5%;
  margin: 10px 0 30px;
}

/*** Modal ***/

.modalLeftButton {
  float: left;
  margin-left: 6px;
}

/*** Breadcrumb ***/

.breadcrumb {
  a {
    font-size: 1.25em;
    line-height: 32px;
  }
}

.widget-tooltip-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

@border-color-base: #D9D9DE;@text-color: #050A13;@primary-color: #14c4a2;@select-border-color: @border-color-base;@select-dropdown-bg: #EFF6FA;@select-dropdown-font-size: 14;@select-item-selected-bg: #EFF6FA;@select-item-active-bg: #EFF6FA;@select-selection-item-bg: #EFF6FA;@select-selection-item-border-color: @border-color-base;