@import '../../static/less/variable.less';

.datepicker {
  position: relative;

  input {
    height: 32px;
    padding-right: 24px;
  }

  i {
    position: absolute;
    top: 2px;
    right: 8px;
    color: @light-text-color;
  }
}

@border-color-base: #D9D9DE;@text-color: #050A13;@primary-color: #14c4a2;@select-border-color: @border-color-base;@select-dropdown-bg: #EFF6FA;@select-dropdown-font-size: 14;@select-item-selected-bg: #EFF6FA;@select-item-active-bg: #EFF6FA;@select-selection-item-bg: #EFF6FA;@select-selection-item-border-color: @border-color-base;