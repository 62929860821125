@import  '../../static/sass/variables.sass';
.ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
.ant-empty-image {
    height: 50% !important;
    max-height: 72px;
    img {
        max-height: 72px; } }
.table-null-data,.table-null-step,.customBoard-null-data {
    display: flex;
    justify-content: center;
    align-items: center;
    .perm-msg {
        background-color: transparent;
        border: none;
        color: #cfcfcf;
        font-size: 14px;
        line-height: 22px; } }
.table-null-text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cfcfcf; }
.table-null-data,.table-null-step {
    height: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 32px;
    &.bi-chart-nulldata {
        height: calc(100% - 22px); } }
#page-content {
    >.table-null-data,
    >.table-null-step {
        height: 358px; } }
.customBoard-null-data {
    width: 500px;
    height: 350px;
    background-color: white;
    margin: 0 auto;

    &.web-style {
        display: flex; }
    &.mobile-style {
        display: none; } }
.table-null-step {
    width: 50%;
    margin: 0 auto; }
@media screen and (max-width: 768px) {
    .customBoard-null-data {
        min-width: 250px;
        min-height: 180px;
        width: 5rem;
        height: 3.5rem;
        &.web-style {
            display: none; }
        &.mobile-style {
            display: flex; } }
    .ant-empty-image {
        height: 0.6rem !important; }
    .table-null-data {
        height: 3rem; } }
