body {
    &.customBoard-fullScreen,&.analyse-fullScreen {
        transition: 0.5s all;
        #page-wrapper {
            // 全屏时候的类控制样式 详情见自定义看板
            transition: 0.5s all;
            margin: 0 !important; }
        #main {
            // 全屏时候的类控制样式 详情见自定义看板
            transition: 0.5s all;
            margin: 0 !important; }
        #sidebar {
            // 全屏时候的类控制样式 详情见自定义看板
            transition: 0.5s all;
            display: none; }
        #nav {
            // 全屏时候的类控制样式 详情见自定义看板
            transition: 0.5s all;
            display: none; }
        .page-header-wrapper {
            // 全屏时候的类控制样式 详情见自定义看板
            transition: 0.5s all !important;
            margin: 0 !important; }
        .ant-select-selection {
            background-color: transparent !important; } }

    &.customBoard-fullScreen {

        .ant-divider {
            display: none !important; }
        .userGroupSelect-add {
            display: none !important; } }
    &.analyse-fullScreen {
        #page-content {
            padding: 0px !important; }
        .page-header {
            display: none !important; }
        .footer {
            display: none; } }
    &.customBoard-fullScreenCancel,&.analyse-fullScreenCancel {
        #page-wrapper {
            transition: 0.5s all !important; }
        #main {}

        #sidebar {
            transition: 0.5s all !important; }
        #nav {
            transition: 0.5s all !important; }
        .page-header-wrapper {
            transition: 0.5s all !important; } } }
