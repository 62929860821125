.childrenFilter_add {
  position: absolute;
  visibility: hidden;
  width: 16px;
  height: 16px;
  top: -52px;
  right: 0px;
  color: rgba(20,196,162,1); }

.childrenFilter,.childrenFilter-empty {
  border-left: none; }


.childrenFilter {
  border-left: 1px solid #D9D9DE;
  padding-left: 18px;
  position: relative;
  .childrenFilter-item {
    width: fit-content;
    margin-bottom: 6px;
    line-height: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    >.ant-cascader-picker,>.childrenFilter-formula,>.childrenFilter-dimValue {
      margin-bottom: 6px; }
    &:hover {
      .condition-delete {
        visibility: visible; } }
    .childrenFilter-formula {
      height: 32px; }
    .childrenFilter-dimValue {
      .ant-select {
        width: 240px !important; }
      .ant-select-selection__choice {
        border: none;
        background-color: #EFF6FA;
        color: #7A8493; }
      .childrenFilter-picker {
        .ant-input {
          width: 300px;
          background-color: transparent; } }
      .childrenFilter-small-picker {
        width: 200px; } }
    .condition-delete {
      visibility: hidden;
      cursor: pointer;
      font-size: 20px;
      line-height: 32px;
      color: #1bb09d; }
    .unit {
      line-height: 32px;
      margin-left: 4px; }

    .ant-cascader-picker {
      width: 96px;
      height: 32px;
      margin-right: 8px; }
    .ant-select-show-search {
      width: 200px !important; }
    .ant-select-enabled {
      width: 96px; }
    .ant-select {
      margin-right: 8px; }
    button {
      margin-right: 4px; } }
  .childrenFilter_logic {
    cursor: pointer;
    line-height: 18px;
    background: rgba(239,250,246,1);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -7px;
    top: 40%;
    font-size: 12px;
    color: rgba(20,196,162,1);
    z-index: 2; }
  .childrenFilter_logic-empty {
    display: none !important; } }



