@import '../../../static/sass/variables.sass';
.showScrollBar {
    .ant-table {
        ::-webkit-scrollbar {
            display: block !important; } } }
.widget {
    .ant-table-wrapper {
        padding-top: 0;
        margin-bottom: 0;
        .ant-table-pagination.ant-pagination {
            margin-bottom: 0; } } }
.cemyun-BI-pagination {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
button {
    margin-left: 8px;
    &:first-child {
        margin-left: 0; } }
.sizer {
    width: auto;
    margin-left: 8px; }
.quickJumper {
    margin-left: 8px;
    font-size: 14px;

    input {
        width: 50px;
        margin: 0 8px; } }

.serialNumber-0 {
    background-image: url('../../../static/images/first.png') !important;
    background-size: 22px 22px!important;
    background-repeat: no-repeat !important; }

.serialNumber-1 {
    background-image: url('../../../static/images/second.png') !important;
    background-size: 22px 22px!important;
    background-repeat: no-repeat !important; }

.serialNumber-2 {
    background-image: url('../../../static/images/third.png') !important;
    background-size: 22px 22px !important;
    background-repeat: no-repeat !important; }

.table {
    &.noBorder {
        .ant-table-thead > tr > th {
            border: none!important; }

        .ant-table-tbody > tr > td {
            border: none!important; } }
    .mergeBodyCell {
        display: flex;
        align-items: center;
        .mergeBodyCellItem {
            &:not(:first-child) {
                margin-left: 4px; } } }
    .contentFieldsBlock {
        white-space: normal;
        line-height: 24px;
        margin-bottom: 12px; }

    .contentFieldsInline {
        color: #7A8493;
        margin-right: 10px; }

    .secondFieldsContent {
        padding: 9px 8px;
        margin-top: 8px;
        border: 1px solid #14C4A2;
        border-radius: 2px;
        background-color: rgba(20,196,162, .2); } }

.mobile-content-item {
    padding: 16px 0;
    border-bottom: 1px solid #E7E7EB;
    .fields-content {
        display: flex;
        flex-wrap: wrap;
        >div {
            width: 50%;
            display: flex;
            line-height: 24px;
            font-size: 14px;
            color: #050A13;
            &:nth-of-child(odd) {
                justify-content: start;
                td {
                    text-align: left!important; } }
            &:nth-of-child(even) {
                justify-content: end;
                td {
                    text-align: right!important; } }
            td {
                font-size: 14px!important;
                color: #050A13!important; } } }
    .content-fields-block {
        padding: 12px 0 8px;
        line-height: 24px; }
    .content-fields-inline {
        display: inline-block;
        margin-right: 10px;
        font-size: 12px;
        color: #7A8493; }
    .main-color-icon {
        text-align: right;
        padding-right: 8px; } }



@mixin type-for-btn($main-color) {
    .select {
        background-color: $main-color!important; }
    .select,
    .select a {
        color: #fff!important; } }
@include type-for-btn(#14C4A2);

.ant-table,
.ant-pagination,
.ant-select {
    font-style: 12px; }
.ant-table-pagination.ant-pagination {
    float: none; }

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    background: transparent !important;
    overflow-x: hidden !important;
    overflow-y: auto !important; }

.ant-table-thead > tr {
    background: transparent !important; }

.ant-table-body {
    background: transparent !important;
    margin: 0 !important;
    overflow: auto !important; }

.ant-table-tbody > tr > td {
    word-break: break-all; }

.react-resizable-handle {
    position: absolute;
    width: 4px;
    height: 100%;
    padding: 0;
    bottom: 0;
    right: 0;
    cursor: col-resize;
    background: none; }

.ant-table-placeholder {
    display: none;

    .selectedRow {
        background-color: #d2eafb; }
    .unSelectedRow {
        background-color: transparent; }

    .headerIcon {
        font-size: 14px;
        line-height: 14px;
        margin: 0 8px;
        vertical-align: middle;
        cursor: pointer; } }
@mixin style-for-surveySentiment($sentimentText,$labelBg,$labelText,$labelTextCheck) {
    .sentiment {
        line-height: 24px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2px;
        .sentimentTitle {
            margin-bottom: 4px;
            margin-right: 8px;
            font-size: 12px;
            line-height: 24px;
            white-space: nowrap; }
        .ant-radio-button-wrapper {
            color: $labelText !important;
            font-size: 12px;
            margin: 0px 8px 8px 0px;
            border-color: $labelBg !important;
            background: $labelBg !important;
            padding: 0 12px;
            border-radius: 8px;
            &:before {
                display: none; } }

        .ant-radio-button-wrapper-checked {
            color: $labelTextCheck !important;
            border-color: $labelTextCheck !important;
            border-radius: 4px;
            border-left: 1px solid $labelTextCheck;
            box-shadow: none!important; } }
    .sentiment-0 {
        margin-top: 16px;
        margin-bottom: 4px; }
    .sentiment-1 {
        margin-top: 0; } }
