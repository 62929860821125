.mobile-v5-calendar {
    position: absolute;
    bottom: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    .adm-calendar .adm-calendar-header {
        padding: 20px; }
    .mobile-v5-calendar-selectors {
        display: flex;
        height: 30px;
        justify-content: space-between;
        align-items: center;
        padding-right: 13px;
        padding-left: 5px;
        padding-top: 10px;
        .tag-container {
            overflow: hidden;
            height: 20px; }
        .tag {
            height: 20px;
            margin-left: 10px;
            min-width: 60px;
            text-align: center; }
        .tag-choose {
            border-color: blue; } } }
.mobile-time-tag {
    height: '32px' !important;
    line-height: 32px !important; }
