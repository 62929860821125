@import '../../../../../../static/less/variable.less';

.dashboardControlPanel {
  background-color: transparent;
  // padding: 4px 8px 8px;
  // margin-top: 16px;
  border-radius: 3px;
  display: flex;
  flex: 1;
  align-items: flex-end;

  &.empty {
    padding: 0;
    margin: 0;
    display: none;
  }

  .controls {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  .actions {
    display: flex;
    // flex-shrink: 0;
    padding: 6px 8px;

    button {
      margin-left: 8px;
      margin-top: 20px;

      &:first-child {
        margin-left: 18px;
      }
    }
  }
}

.fullscreenControlPanel {
  background-color: @white;
  display: flex;
  flex-direction: column;

  .actions {
    margin-top: 8px;
    text-align: right;

    button {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.dashboardItemControlPanel {
  padding: 8px 16px;
  border-bottom: 1px solid @border-color-base;
  overflow: hidden;
  display: flex;

  .controls {
    flex: 1;
  }

  .actions {
    flex-shrink: 0;
    text-align: right;

    button {
      margin-left: 8px;
      margin-top: 10px;

      &:first-child {
        margin-left: 18px;
      }
    }
  }
}

.controlItem {
  display: flex;
  margin: 0;
  min-height: 30px;
  margin-top: 15px;
  margin-right: 8px;
  .antFormItemStyleWithoutForm;
  .controlItemName {
    white-space: nowrap;
    line-height: 30px;
    margin-right: 4px;
    color: #7a8493;
  }
  .controlOldItemName {
    position: absolute;
    line-height: 32px;
    color: #7a8493;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
  }
  .controlItemComponent {
    width: 100%;
  }
}

.inputText {
  .ant-input {
    background: transparent;
  }
  .ant-input-suffix {
    color: #7a8493;
  }
  .ant-input-search .ant-input-affix-wrapper {
    line-height: 32px;
  }
}

.reloadButton {
  margin-left: 8px;
  border: 1px #d9d9de solid;
}

.controlSelect {
  .ant-select {
    width: 100%;
    .ant-select-arrow {
      color: #7a8493 !important;
    }
    .ant-select-selection--multiple .ant-select-selection__placeholder {
      margin-left: 0;
    }
  }
}

.controlDate {
  .ant-picker-active-bar {
    width: 50% !important;
    height: 2.5px !important;
  }

  .ant-picker {
    width: 100%;
    background: transparent;
    border-radius: 4px;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-calendar-range-picker-input {
    // width: 110px;
  }

  .ant-calendar-range-picker-input {
    // width: 30%;
  }

  .ant-calendar-picker .ant-calendar-picker-input {
    padding: 4px 11px;
  }
  .ant-calendar-picker-clear {
    background: white !important;
  }
  .ant-calendar-picker-icon {
    color: #7a8493;
  }
}

.controlSlider {
  .ant-slider {
    margin: 10px 6px 10px;
    .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
      word-break: normal;
    }
  }
}

.controlRadio {
  line-height: 30px;
  margin-bottom: -15px;
  span.ant-radio + * {
    padding-left: 4px;
  }
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-radio-wrapper {
    margin-bottom: 15px;
    line-height: 30px;
  }
  .ant-radio-button-wrapper {
    margin-bottom: 15px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      background: #ffffff !important;
      color: #14c4a2 !important;
    }
  }
}

.controlQuick {
  .ant-select {
    border-color: #d9d9de;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    color: #7a8493;
    background: transparent !important;
  }
  .ant-select-arrow {
    color: #7a8493 !important;
  }
}

.controlQuickPicker {
  border-radius: 4px !important;
  background: transparent !important;
}

.ant-picker-ok {
  margin-left: 98px !important;
}

.withConfirm {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .oldControlQuickPicker {
    display: block !important;
  }
  .controlDate .ant-calendar-picker {
    display: block !important;
  }
}

@border-color-base: #D9D9DE;@text-color: #050A13;@primary-color: #14c4a2;@select-border-color: @border-color-base;@select-dropdown-bg: #EFF6FA;@select-dropdown-font-size: 14;@select-item-selected-bg: #EFF6FA;@select-item-active-bg: #EFF6FA;@select-selection-item-bg: #EFF6FA;@select-selection-item-border-color: @border-color-base;