@import "./../../static/sass/variables";

.swal2-popup.swal2-modal.uxs-confirm {
    #swal2-title {
        font-size: $font-size-base;
        margin: 0;
        font-weight: normal;
        .text {
            margin-bottom: 30px; } }

    .swal2-actions, .swal2-styled {
        margin: 0;
        padding: 0; }
    .swal2-actions {
        display: inline-block !important;
        margin: 0 auto;
        button {
            box-shadow: none;
            margin-left: 10px; } }
    .uxs-button-confirm, .uxs-button-cancel {
        display: block;
        margin-bottom: 0;
        font-weight: normal;
        float: right;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        border-radius: 4px;
        user-select: none;
        width: $button-width-lg;
        height: $button-height-lg;
        line-height: $button-height-lg;
        font-size: $font-size-base; }
    .uxs-button-confirm {
        color: #fff;
        background-color: $green-init;
        border-color: $green-init;
        &:hover {
            background-color: $green-hover;
            border-color: $green-hover; }
        &:active {
            background-color: $green-active;
            border-color: $green-active; } }
    .uxs-button-cancel {
        color: $delay-init;
        background-color: #fff;
        border-color: $delay-init-border;
        &:hover {
            border-color: $delay-hover;
            background-image: none !important;
            color: $delay-hover; }
        &:active {
            border-color: $delay-active;
            background-image: none !important;
            color: $delay-active; } } }

