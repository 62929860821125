.delete-warning-confirm {
  .ant-popover-message-title {
    p {
      &:nth-child(even) {
        font-weight: 600;
        margin-bottom: 0; }
      &:nth-child(odd) {
        margin-bottom: 8px; } } }
  .ant-btn {
    visibility: hidden; } }
