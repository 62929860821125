/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

/**** react-grid *****/

/* .react-grid-item {
  background-color: #fff;
  border-radius: 2px;
  cursor: move;
}

.react-grid-item.react-grid-placeholder {
  background-color: #586b84;
} */

/***** antd *****/

.ant-modal-small > .ant-modal {
  width: 400px !important;
}

.ant-modal-medium > .ant-modal {
  width: 600px !important;
}

.ant-modal-large > .ant-modal {
  width: 900px !important;
}

.ant-modal-xlarge {
  padding: 20px;
}

.ant-modal-xlarge > .ant-modal {
  width: auto !important;
  height: 100%;
  padding: 0;
  margin: 0;
  top: auto;
  display: flex;
  flex-direction: column;
}

.ant-modal-xlarge > .ant-modal > .ant-modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* firefox flex overflow issue */
}

.ant-modal-xlarge > .ant-modal > .ant-modal-content > .ant-modal-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* firefox flex overflow issue */
}

.ant-modal-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-modal-center > .ant-modal {
  padding: 0;
  margin: 0;
  top: auto;
  min-height: 0;
}

/* 解决 RangePicker 与其他form元素不对齐 */
.ant-calendar-range-picker-input {
  vertical-align: baseline;
}

@media (max-width: 1200px) {
  .ant-modal-xlarge {
    padding: 10px;
  }
}

@media (max-width: 900px) {
  .ant-modal-large > .ant-modal {
    width: auto !important;
    margin: 10px;
  }
}

@media (max-width: 768px) {
  .ant-modal-small > .ant-modal,
  .ant-modal-medium > .ant-modal {
    width: auto !important;
  }
}

/* action 栏按钮左右间距 */
.ant-table-action-column .ant-btn {
  margin: 0 4px;
}

/* 缩小默认行高（override variable） */
/* .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 8px;
} */

/* 调整空列表与 BoxBody 下方间距 */
.ant-table-empty {
  margin-bottom: 20px;
}

/* 调整因字体大小造成的 RangePicker input 位置不对 */
.ant-calendar-picker-input {
  line-height: 1;
}

.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: inherit;
}

.ant-page-header-heading-extra {
  line-height: 32px;
}
/* .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
} */
/* .ant-table-tbody > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
} */
