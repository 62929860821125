.uxs-tag {
    &.uxs-tag-withShadow {
        .ant-tag {
            box-shadow: 2px 2px 0px #D9D9DE; } }
    .ant-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        min-width: 96px;
        max-width: 450px;
        white-space: initial;
        height: 32px;
        font-size: 14px;
        background-color: #ffffff;
        .anticon-close {
            color: #B2B3B9;
            font-size: 14px; }
        .uxs-tag-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; } } }
