@mixin style-for-surveySentiment($sentimentText,$labelBg,$labelText,$labelTextCheck) {
    .survey-table-content {
        overflow: auto;
        padding-top: 16px;
        .sentimentGroup {
            .survey-sentiment {
                &:first-child {
                    margin-bottom: 8px; }
                .survey-sentiment-title {
                    margin-bottom: 4px;
                    margin-right: 8px;
                    font-size: 12px;
                    line-height: 24px;
                    white-space: nowrap; }
                .survey-sentiment-list {
                    margin-bottom: 0px;
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        line-height: 24px;
                        font-size: 12px;
                        margin-right: 16px;
                        cursor: pointer;
                        color: $sentimentText;
                        i {
                            margin-right: 4px;
                            font-size: 20px;
                            font-weight: bold;
                            display: inline-block;
                            vertical-align: middle; } }
                    .xi {
                        color: $labelTextCheck; }
                    .danding {
                        color: #34d1df; }
                    .ai {
                        color: #db3d2d; }
                    .qita {
                        color: #B2B3B9; } }
                .ant-radio-group {
                    line-height: 24px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 2px;

                    .ant-radio-button-wrapper {
                        color: $labelText !important;
                        font-size: 12px;
                        margin: 0px 8px 8px 0px;
                        border-color: $labelBg !important;
                        background: $labelBg !important;
                        box-shadow: none;
                        padding: 0 12px;
                        &:before {
                            display: none; } }
                    .ant-radio-button-wrapper-checked {
                        color: $labelTextCheck !important;
                        border-color: $labelTextCheck !important;
                        border-left: 1px solid $labelTextCheck;
                        border-radius: 4px; } } } }
        .ant-table-wrapper {
            padding-top: 0; } }
    .survey-analyse-tools {
        display: flex;
        justify-content: space-between;
        padding-right: 8px;
        a {
            color: #050A13;
            line-height: 40px;
            i {
                color: #050A13;
                font-weight: 600;
                margin-right: 5px; }
            &:hover {
                color: $labelTextCheck;
                i {
                    color: $labelTextCheck; } } } }
    .sentiment {
        line-height: 24px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4px;
        .sentimentTitle {
            margin-bottom: 4px;
            margin-right: 8px;
            font-size: 12px;
            line-height: 24px;
            white-space: nowrap; }
        .ant-radio-button-wrapper {
            color: $labelTextCheck !important;
            font-size: 12px;
            margin: 0px 8px 8px 0px;
            border-color: $labelTextCheck !important;
            border-left: 1px solid $labelTextCheck;
            background: $labelBg !important;
            padding: 0 12px;
            border-radius: 8px;
            &:before {
                display: none; } }

        .ant-radio-button-wrapper-checked {
            color: #ffffff !important;
            background-color: $labelTextCheck!important;
            border-color: $labelTextCheck !important;
            border-radius: 6px;
            border-left: 1px solid $labelTextCheck;
            box-shadow: none!important; } }
    .sentiment-0 {
        margin-top: 16px; }
    .sentiment-1 {
        margin-top: 0; } }
.table-compare-content {
    min-width: 60px;
    width: 100%;
    @media screen and (max-width: 768px) {
     .sentimentGroup {
        .survey-sentiment {
            display: flex;
            flex-direction: column;
            &:first-child {
                margin-top: 0.24rem;
                margin-bottom: 0.24rem; }
            .survey-sentiment-title {
                margin-right: 0.16rem; }
            .survey-sentiment-list {
                margin-bottom: 0px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li {
                    margin-right: 0; } }
            .ant-radio-group {
                margin-top: 0.04rem;

                .ant-radio-button-wrapper {
                    margin: 0rem 0.16rem 0.16rem 0rem; } } } } } }
.countrywide-value {
    margin-top: 4px;
    font-size: 12px;
    color: #7a8493; }
.countrywide-value.center {
    text-align: center; }
.analyse-table-content {
    overflow: auto; }




@include style-for-surveySentiment(#7A8493,rgba(20,196,162,.2),#7A8493,#14C4A2);
