@import "../../static/sass/mixin";
@import "../../static/sass/variables";
.visitor-info {
    .uxs-picker {
        margin-bottom: 16px; }
    .visitor-info-title {
        color: #7A8493;
        flex-shrink: 0; }
    .visitor-info-value, .visitor-info-title {
        word-break: break-all; }
    .basic-card {
        .card-content {
            padding: 16px 0 20px;
            .visitor-title {
                padding-bottom: 12px;
                padding-left: 20px;
                line-height: 24px;
                font-weight: bold; }
            .visitor-info-item {
                display: flex;
                font-size: 12px;
                line-height: 20px;
                padding: 0 20px; }
            .warning-detail {
                margin-top: 16px; } } }
    .ant-alert.ant-alert-no-icon {
        padding: 12px 16px;
        margin: 0 20px;
        .warning-content,
        .warning-info,
        .warning-info-item {
            display: flex; }
        .warning-content {
            line-height: 24px; }
        .warning-score {
            width: 44px;
            text-align: center;
            font-size: 12px;
            i {
                font-size: 26px; }
            .gao {
                color: #9fca5d; }
            .zhong {
                color: #f2aa62; }
            .di {
                color: #ff6b5c; } }
        .warning-info {
            flex-wrap: wrap;
            padding-left: 16px; }
        .warning-info-item {
            margin-right: 32px; } }
    .feedbak-card {
        .card-content {
            padding: 16px 20px 20px;
            line-height: 24px;
            .feedback-topic {
                margin-bottom: 8px; }
            .feedback-answer {
                margin-bottom: 0; }
            .feedback-answer.empty {
                color: #CFCFCF; } } }
    .ant-collapse-borderless.visitor-event-list {
        background: none;
        .ant-collapse-item-active {
            .ant-collapse-header {
                padding-bottom: 8px; } }
        .ant-collapse-item {
            background: #ffffff;
            border-bottom: 1px solid #f3f3f4;
            &:first-child {
                border-top: 1px solid #f3f3f4; }
            .ant-collapse-header {
                padding-left: 24px;
                .iconfont {
                    margin-right: 4px; }
                .icon-yujing {
                    color: #FF6B5C; }
                .icon-dingdan {
                    color: #6FBE4E; }
                .icon-tiaoyan {
                    color: #1F80DE; }
                .ant-collapse-arrow {
                    left: 0; } }
            &:hover {
                background-color:  #eff6fa; }
            .ant-collapse-content-box {
                padding: 0 16px 12px;
                .event-info-item {
                    line-height: 20px; } }
            .visitItem {
                background: #050A13;
                font-size: 12px;
                line-height: 40px;
                color: #ffffff;
                position: relative;
                .visitItemCount {
                    position: absolute;
                    left: 10px; }
                .visitItemInfo {
                    margin-left: 94px;
                    display: flex;
                    flex-wrap: wrap;

                    > :not(:last-child) {
                        margin-right: 29px; }

                    .grayFont {
                        color: #7A8493; } } }
            .visitMoreList_btn {
                display: flex;
                flex-direction: row-reverse; } } }
    .visitor-event-content {
        .visitor-event-operate {
            border-top: 1px solid #f3f3f4;
            padding: 16px 0;
            margin-top: 16px;
            .ant-cascader-picker {
                margin: 0 16px; } }
        .ant-btn {
            margin-top: 16px; }
        .event-info {
            .event-info-item {
                font-size: 12px;
                display: flex;
                padding: 0 24px; }
            .info-category-title {
                margin: 8px; } } }
    .info-category-title {
        font-weight: 700; } }
.color-link {
    color: #14c4a2!important; }
@media screen and (max-width:768px) {
    .visitor-info {
        .ant-alert.ant-alert-no-icon {
            .warning-info {
                display: block; } }
        .visitor-event-content {
            .visitor-event-operate {
                .ant-cascader-picker {
                    margin: 16px 0; } } }
        .basic-card {
                .visitor-info-item {
                    padding: 0; } } } }
