#analyseChartSelect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-radio-group {
        .ant-radio-button-wrapper,ant-radio-button-wrapper-checked {
            border-radius: 5px;
            width: 30px;
            height: 30px;
            padding: 2px 1px;
            margin: 8px 8px 0px 0px;
            line-height: 24px;
            text-align: center;
            border-left: 1px solid #D9D9DE;
            &::before {
                display: none; }
            .iconfont {
                font-size: 16px; } } }
    .analyseChartSelect_group {
        display: flex;
        .analyseChartSelect_group-orderDesc {
            margin-right: 8px; } } }
