@mixin style-for-surveySentiment($sentimentText,$labelBg,$labelText,$labelTextCheck) {
    @media screen and (max-width: 768px) {
     .sentimentGroup {
        .survey-sentiment {
            display: flex;
            flex-direction: column;
            &:first-child {
                margin-top: 0.24rem;
                margin-bottom: 0.24rem; }
            .survey-sentiment-title {
                margin-right: 0.16rem; }
            .survey-sentiment-list {
                margin-bottom: 0px;
                display: flex;
                flex-wrap: wrap;
                li {
                    margin-right: 0; } }
            .ant-radio-group {
                margin-top: 0.04rem;

                .ant-radio-button-wrapper {
                    margin: 0rem 0.16rem 0.16rem 0rem; } } } } } }





@include style-for-surveySentiment(#7A8493,#F3F3F4,#7A8493,#14C4A2);
