@import './../../static/sass/variables';

#nav {
    width: 100%;
    position: fixed;
    height: $navbar-top-height;
    background-color: $navbar-top-bg-color;
    z-index: 4;
    top: 0;
    border-width: 0 0 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-left {
        margin-left: 10px;
        color: white; }
    .nav-middle {
        color: white;
        font-size: 16px;
        font-weight: bold; }
    .nav-right {
        padding-right: 10px;
        .logo {
            width: 30px; } } }
