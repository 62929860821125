@mixin type-for-orderNumber($main-color,$border-color,$text-color) {
    .orderNumber-text {
        &.orderNumber-text-normal {
            width: 18px;
            height: 18px;
            line-height: 18px;
            display: inline-block;
            text-align: center;
            background-color: transparent;
            border-radius: 50%;
            border: 1px solid $border-color;
            color: $text-color;
            margin: 0 4px; }
        &.orderNumber-text-mainColor {
            border: 1px solid $main-color;
            color: $main-color; } } }
@include type-for-orderNumber(#14C4A2,#D9D9DE,#050A13);
