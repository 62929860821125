@import "static/sass/variables.sass";

@mixin color-for-component-base($FIRST_TEXT-COLOR,$SECOND_TEXT-COLOR, $TIPS-COLOR,$DISABLED-COLOR, $BORDER-COLOR,$FIRST-ACTIVE-BG,$SECOND-ACTIVE-BG,$MAIN-COLOR,$MAIN-HOVER-COLOR,$MAIN-ACTIVE-COLOR,$DISABLED-BG-COLOR,$CALENDAR-ACTIVE-BG,$BOX-SHADOW) {
    // $FIRST_TEXT-COLOR: #050A13SHADOW
    // $SECOND_TEXT-COLOR: #7A8493
    // $TIPS-COLOR: #B2B3B9
    // $DISABLED-COLOR: #D9D9DE
    // $BORDER-COLOR: #7A8493
    // $FIRST-ACTIVE-BG: #EFFAF6
    // $SECOND-ACTIVE-BG: #EFF6FA
    // $MAIN-COLOR: #14c4a2
    // $MAIN-HOVER-COLOR: #38CBAE
    // $MAIN-ACTIVE-COLOR: #40AD98

    $select-hover-color: $SECOND-ACTIVE-BG;
    $selct-text-color: $SECOND_TEXT-COLOR;
    $select-text-hover-color: $FIRST_TEXT-COLOR;
    $calendar-bg-color: $CALENDAR-ACTIVE-BG;
    ::selection {
        color: #fff;
        background: $MAIN-COLOR; }
    input.ant-input {
        border-color: $BORDER-COLOR;
        color: $FIRST_TEXT-COLOR;
        padding: 4px 12px;
        &:hover {
            border-color: 1px solid $MAIN-COLOR !important; }
        &:focus {
            box-shadow: none !important; } }

    button.ant-btn-report {
        vertical-align: top;
        padding: 0px;
        width: 80px;
        height: 32px;
        font-size: 14px;
        color: $selct-text-color;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid $BORDER-COLOR;
        &:hover {
            color: $MAIN-HOVER-COLOR;
            border: 1px solid $MAIN-HOVER-COLOR; }
        &:focus {
            color: $MAIN-ACTIVE-COLOR;
            border: 1px solid $MAIN-ACTIVE-COLOR; } }
    button.ant-btn-addButton {
        vertical-align: top;
        padding: 0 8px;
        min-width: 72px;
        height: 24px;
        font-size: 12px;
        color: $MAIN-COLOR;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid $MAIN-COLOR;
        &:hover {
            color: $MAIN-ACTIVE-COLOR;
            background-color: transparent;
            border: 1px solid $MAIN-ACTIVE-COLOR; }
        &:focus {
            color: $MAIN-ACTIVE-COLOR;
            background-color: transparent;
            border: 1px solid $MAIN-ACTIVE-COLOR; } }
    button.search {
        min-width: 33px !important;
        height: 33px !important; }
    button {
        &.ant-btn-circle {
            min-width: 32px !important; }
        &.ant-btn-add,&.ant-btn-primary {
            color: #ffffff;
            min-width: 80px;
            text-shadow: none;
            background-color: $MAIN-COLOR;
            border-color: $MAIN-COLOR;
            &:hover {
                color: #ffffff;
                background-color: $MAIN-HOVER-COLOR;
                border-color: $MAIN-HOVER-COLOR;
                box-shadow: none !important; }
            &:focus {
                color: #ffffff;
                box-shadow: none !important;
                background-color: $MAIN-ACTIVE-COLOR;
                border-color: $MAIN-ACTIVE-COLOR; } }
        &.ant-btn-cancel {
            color: $MAIN-COLOR;
            min-width: 80px;
            text-shadow: none;
            background-color: #ffffff;
            border-color: $MAIN-COLOR;
            border: 1px solid;
            &:hover {
                color: $MAIN-HOVER-COLOR;
                border-color: $MAIN-HOVER-COLOR; }
            &:focus {
                color: $MAIN-ACTIVE-COLOR;
                border-color: $MAIN-ACTIVE-COLOR; } } }
    // cascader下拉框样式
    .ant-cascader-picker {
        background-color: transparent;
        .ant-cascader-input {
            border-color: $BORDER-COLOR;
            box-shadow: none; }

        &:focus {
            border-color: $MAIN-ACTIVE-COLOR;
            .ant-cascader-input {
                box-shadow: none; } } }
    .ant-cascader-picker-disabled {
        background: $DISABLED-BG-COLOR !important;
        &:focus,&:hover,&:active {
            border-color: $DISABLED-COLOR !important; }
        .ant-cascader-picker-label {
            &:hover + .ant-cascader-input {
                border-color: $DISABLED-COLOR !important; } } }
    .ant-cascader-picker-label {
        z-index: 1;
        &:hover + .ant-cascader-input,&:focus + .ant-cascader-input,&:active + .ant-cascader-input {
            border-color: $MAIN-COLOR !important; } }
    .ant-cascader-menu-item {
        color: $selct-text-color;
        &.ant-cascader-menu-item-disabled {
            color: $DISABLED-COLOR; } }
    .ant-cascader-menu-item:hover {
        color: $select-text-hover-color;
        background-color: $select-hover-color !important;
        &.ant-cascader-menu-item-disabled {
            color: $DISABLED-COLOR; } }

    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background-color: $select-hover-color !important;
        color: $select-text-hover-color;
        font-weight: 400 !important; }
    // select下拉框样式
    //antd select disabled 样式

    .ant-select-open {
        .ant-select-selection {
            box-shadow: 0 0 0 2px $BOX-SHADOW !important; } }

    .ant-select {
        color: $FIRST_TEXT-COLOR; }
    .ant-select-sm {
        .ant-select-selection-selected-value {
            padding-right: 20px !important; } }
    .ant-select-selection-selected-value {
        padding-right: 0px !important; }
    .ant-select-disabled {
        .ant-select-selection-selected-value {
            color: #A8A8A8 !important;
            padding-right: 0px !important; }
        .ant-select-selection {
            background-color: #e9ecee !important; }
        .ant-select-arrow-icon,
        .ant-select-selection__choice {
            cursor: not-allowed; } }
    //antd select disabled 样式
    .ant-select-sm {
        &.ant-select-enabled {
            .ant-select-selection {
                padding-left: 8px; }
            .ant-select-arrow-icon {
                color: $FIRST_TEXT-COLOR; } }
        .ant-select-selection__rendered {
            margin: 0 !important; } }
    .ant-select-enabled {
        .ant-select-selection {
            border: 1px solid $BORDER-COLOR;
            &:active,&:focus {
                border: 1px solid $MAIN-COLOR;
                box-shadow: none !important; } } }
    .ant-select-dropdown {
        min-width: 96px !important;
        width: auto !important;
        z-index: 99999;
        background: white;

        &.ant-select-dropdown--multiple {
            .ant-select-dropdown-menu-item-selected {
                .ant-select-selected-icon {
                    color: $MAIN-COLOR; } } } }
    .ant-select-dropdown-menu {
        padding: 4px 0 6px 0;
        border-radius: 4px;
        li {
            font-size: 14px;
            color: $selct-text-color;
            background: #ffffff;
            font-weight: 400;
            a {
                color: $selct-text-color; }
            &:hover,&:active,&:focus {
                color: $select-text-hover-color;
                background: $select-hover-color;
                a {
                    color: $select-text-hover-color; } } }
        .ant-select-dropdown-menu-item-selected {
            background: $select-hover-color !important;
            a {
                color: $select-text-hover-color !important; } }
        .ant-select-dropdown-menu-item-active {
            &:hover {
                box-shadow: none !important;
                font-weight: 400 !important;
                color: $select-text-hover-color !important;
                background: $select-hover-color !important; } }
        .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
            background-color: $select-hover-color !important; }
        .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
            background-color: $select-hover-color !important;
            font-weight: 400 !important; } }
    // dropdown下拉框样式
    .ant-dropdown-menu-item,.ant-dropdown-menu-submenu-title {
        color: $selct-text-color !important;
        i {
            margin-right: 8px;
            color: $selct-text-color !important; }
        a {
            color: $selct-text-color !important; }
        &:hover {
            color: $select-text-hover-color !important;
            background-color: $select-hover-color !important;
            a {
                color: $select-text-hover-color !important; }
            i {
                color: $select-text-hover-color !important; } } }
    .dropdown-menu {
        border: none !important;
        min-width: 96px !important;
        ul {
            padding: 4px 0 6px 0;
            overflow: hidden !important;
            li {
                overflow: hidden;
                text-overflow: ellipsis;
                color: $selct-text-color;
                height: 28px;
                box-sizing: border-box;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                &:hover {
                    color: $select-text-hover-color;
                    background-color: $select-hover-color; } } } }
    // 时间筛选框
    .ant-calendar-range {
        .ant-calendar-in-range-cell {
            &:before {
                background: $CALENDAR-ACTIVE-BG !important; } } }

    .am-popover {
        .am-popover-item {
            &.am-popover-item-active {
                .am-popover-item-container {
                    .mobilePicker {
                        .mobilePicker_icon {
                            background-color: #ddd; } } } } } }
    .ant-tabs-nav {
        .ant-tabs-tab {
            color: $SECOND_TEXT-COLOR; } }
    .ant-tabs-nav {
        .ant-tabs-tab-active {
            color: $MAIN-COLOR; } }
    .ant-tabs-ink-bar {
        background-color: $MAIN-COLOR; }
    // radio组件
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: $MAIN-COLOR !important; }
    .ant-radio-inner {
        width: 17px;
        height: 17px;
        &:after {
            top: 4px;
            left: 4px; } }
    .ant-radio-group {
        .ant-radio-button-wrapper {
            &:not(:first-child) {
                &::before {
                    background-color: $BORDER-COLOR !important; } }
            &.ant-radio-button-wrapper-checked {
                background: #ffffff;
                border-color: $MAIN-COLOR !important;
                -webkit-box-shadow: -1px 0 0 0 $MAIN-COLOR;
                box-shadow: none !important;
                border-left: 1px solid $MAIN-COLOR;
                // box-shadow: -1px 0 0 0 $MAIN-COLOR
                &::before {
                    background-color: $MAIN-COLOR !important;
                    border-color: $MAIN-COLOR !important;
                    -webkit-box-shadow: -1px 0 0 0 $MAIN-COLOR;
                    box-shadow: -1px 0 0 0 $MAIN-COLOR; } } }
        .ant-radio-button-wrapper {
            background: transparent;
            color: $SECOND_TEXT-COLOR;
            span {
                i {
                    color: $MAIN-COLOR;
                    font-size: 32px; } }
            &:hover {
                color: $MAIN-COLOR;
                span {
                    i {
                        color: $MAIN-COLOR; } } }
            &.ant-radio-button-wrapper-disabled {
                color: $DISABLED-COLOR;
                background: none;
                span {
                    cursor: not-allowed;
                    i {
                        cursor: not-allowed;
                        color: $DISABLED-COLOR;
                        font-size: 32px; } } }
            &.ant-radio-button-wrapper-checked {
                color: $MAIN-COLOR;
                border-color: $MAIN-COLOR !important;
                span {
                    i {
                        color: $MAIN-COLOR; } } } } }
    .ant-tree {
        >li {
            clear: both; }
        >li>ul>li {
            clear: both; }
        >li>ul>li>ul>li {
            padding-top: 0 !important;
            clear: none;
            float: left;
            width: 150px; } }

    // 复选框
    .ant-tree-checkbox-checked {
        .ant-tree-checkbox-inner {
            background-color: $MAIN-COLOR;
            border-color: $MAIN-COLOR; } }
    .ant-select-tree-checkbox-checked {
        .ant-select-tree-checkbox-inner {
            background-color: $MAIN-COLOR;
            border-color: $MAIN-COLOR; } }
    .ant-tree-checkbox-indeterminate {
        .ant-tree-checkbox-inner::after {
            background-color: $MAIN-COLOR; } }
    .ant-tree-checkbox:hover {
        .ant-tree-checkbox-inner {
            border-color: $MAIN-COLOR; } }
    // 空状态组件

    //  img

    //      opacity: 0.35
    .ant-empty-description {
        color: $DISABLED-COLOR; }
    // 分页组件
    .ant-pagination-item-active {
        background-color: transparent !important; }
    //treeSelect多选框
    .ant-select-tree {
        padding: 4px 0;
        border-radius: 4px 0 0 4px;
        border-right: 1px solid #e8e8e8;
        margin-top: 0;
        background: white;
        li {
            margin: 0;
            padding: 0;
            span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
                display: none; }

            .ant-select-tree-node-content-wrapper {
                width: calc(100%) !important;
                background: white;
                padding: 7px 12px;
                .ant-select-tree-title {
                    color: $SECOND_TEXT-COLOR; }
                &.ant-select-tree-node-selected {

                    background-color: $select-hover-color !important;
                    .ant-select-tree-title {
                        color: $FIRST_TEXT-COLOR; } }
                &:hover {
                    background-color: $select-hover-color !important; } } } }
    .ant-select-tree-dropdown {
        max-height: 250px !important; }
    .ant-select-selection--multiple {
        .ant-select-selection__rendered {
            margin-left: 4px !important;
            .ant-select-selection__choice {
                background-color: $FIRST-ACTIVE-BG !important;
                border: none !important;
                color: #050a13 !important; } } }
    .ant-menu-submenu-placement-rightTop {
        .ant-menu-item-selected {
            color: $MAIN-COLOR !important; }
        .ant-menu:not(.ant-menu-horizontal) {
            .ant-menu-item-selected {
                background-color: transparent;
                &>a {
                    background-color: $SECOND-ACTIVE-BG; } } } }
    .ant-menu-item:hover,  .ant-menu-item > a:hover,.ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
        color: $MAIN-COLOR !important; }
    .ant-input-number:hover,.ant-input-number:active,.ant-input-number:focus,.ant-input-number-focused {
        border-color: $MAIN-COLOR !important; }
    // seitch开关按钮
    .ant-switch {
        background-color: #d9d9de; }
    .ant-switch-checked {
        background-color: $MAIN-COLOR !important; }
    //
    .operateGroup {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        flex-wrap: nowrap;
        .text-disabled {
            color: $DISABLED-COLOR;
            white-space: nowrap;
            &:not(:last-child) {
                margin-left: 8px; } }
        .operateDom-update,.operateDom-add,.operateDom-share,.operateDom-delete,.operateDom-btn {
            color: $MAIN-COLOR;
            cursor: pointer;
            white-space: nowrap;
            &:not(:last-child) {
                margin-left: 8px; } } }
    .ant-input-number-handler:hover {
        .ant-input-number-handler-up-inner {
            color: $MAIN-COLOR; } }
    .ant-input-number-handler:hover {
        .ant-input-number-handler-down-inner {
            color: $MAIN-COLOR; } }
    .operate-text-btn {}
    &:hover {
        color: $MAIN-COLOR; } }
