.expanded-table {
    .number {
        display: none; }
    .ant-table-row-level-0 {
        .number {
            display: inline-block; } }
    .column-for-key {
        display: flex;
        align-items: center; } }
.contact-overview.contact-overview-chart {
    height: calc(100vh - 194px);
    .gragh-content {
        height: 100%; } }
.contact-overview {
    position: relative;
    padding-top: 16px;
    .display-radio.ant-radio-group {
        position: absolute;
        right: 0;
        top: -41px;
        .ant-radio-button-wrapper {
            padding: 0 9px;
            span {
                .iconfont {
                    font-size: 16px; } } } } }
.input-list {
    .input-item {
        display: flex;
        margin-bottom: 16px;
        .ant-input {
            margin-right: 16px; }
        .condition-delete {
            cursor: pointer;
            font-size: 20px;
            line-height: 32px;
            color: #1bb09d;
            display: none; }
        &:hover {
            .condition-delete {
                display: block; } } }
    .add-condition {
        margin-left: 0; } }
.ant-dropdown-menu-item {
    .menu-link {
        max-width: 200px;
        overflow: hidden;
        padding: 0;
        margin: 0; } }
.contact-audit-form {
    margin-top: 48px; }
.childrenNoSpace {
    .ant-table-row-expand-icon {
        display: none !important; }
    .ant-table-row-indent {
        display: none !important; } }
