@import "./../../static/sass/variables";

.ant-btn {
  border: 1px solid; }
.top-dropdown {
  position: fixed;
  z-index: 1060; }
#nav {
  width: 100%;
  position: fixed;
  height: $navbar-top-height;
  background-color: $navbar-top-bg-color;
  z-index: 4;
  top: 0;
  border-width: 0 0 0px;
  // border-bottom: 1px solid #eeeeee
  .product-mobileSelect {
    display: none; }
  .dropdown {
    margin-top: 16px !important;
    background-color: #373B42;
    color: #B2B3B9;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
    &:hover {
      color: #ffffff !important; } }
  & .container {
    padding: 0;
    width: 100%;
    height: 100%;
    .ant-row {
      height: inherit;
      .ant-col {
        height: inherit; } }
    .product-span-select {
      margin-top: -3px;
      float: right;
      margin-right: 32px; }
    .trigger {
      float: left;
      color: white;
      line-height: 56px;
      margin-left: 15px;
      margin-right: 20px;
      i {
        font-size: 24px; } }
    //& .navbar-header
    ////border-right: 1px solid $border-color
    .sams-style.navbar-brand {
      width: $sidebar-width;
      height: $navbar-top-height;
      float: left;
      padding: 15px 0px 0px 12px;
      img {
        width: 100%;
        display: block;
        margin: 0 auto; } }
    .ipsos-style.navbar-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $sidebar-width;
      height: $navbar-top-height;
      float: left;
      padding: 0px;
      img {
        display: block;
        margin: 0 auto; } }
    .navbar-brand {
      width: $sidebar-width;
      height: $navbar-top-height;
      float: left;
      padding-top: 20px;
      display: flex;
      align-items: center;
      img {
        display: block;
        margin: 0 auto; }
      .brand-text {
        color: #fff;
        font-size: 16px;
        font-weight: 600; } }
    & .navbar-collapse {
      & .nav-top-menu {
        height: 56px;
        li {
          float: right;
          display: inline-block;
          height: 40px;
          width: 25px;
          margin-right: 16px;
          &:not(:first-child) {}
          a {
            height: 100%;
            line-height: $navbar-top-height;
            color: #ffffff;
            display: block; }
          .lg-icon {
            height: 100%;
            line-height: $navbar-top-height;
            font-size: 24px;
            color: #7A8493;
            &:hover {
              color: white; } }
          &:last-child {
            a {
              margin: 0; } }
          .product-button {
            a {
              &:active {
                color: #FFFFFF !important; } } }
          & > .dropdown {
            color: $navbar-top-text-color;
            height: 100%;
            margin: $navbar-top-collapse-filter-margin;
            .dropdown-menu {
              z-index: 991; }
            &:first-child .dropdown {
              margin-left: 86px; }

            &:last-child .dropdown {
              margin: 0; } } }
        .active {
          color: #FFFFFF !important; }
        .feedback-btn {
          width: 96px;
          height: 32px;
          margin-top: 12px;
          margin-right: 24px;
          .ant-btn {
            line-height: 32px;
            color: #7A8493;
            border-color: #7A8493;
            &:hover, &:active {
              color: #ffffff;
              border-color: #ffffff; } } } } }
    .nav-top-dropdown-trigger {
      width: 120px;
      display: flex;
      font-size: 16px;
      color: #fff;
      line-height: 56px;
      margin-right: 32px;
      i {
        display:block {}
        font-size: 20px;
        color: #fff;
        transition-duration: .3s;
        margin-right: 4px; }
      &.active {
        color: #fff;
        i {
          color: #fff; } }
      &:hover {
        color: #fff;
        i {
          color: #fff; } } }



    .active-cemyun {
      float: left;
      line-height: $navbar-top-height;
      font-size: $font-size-large;
      color: white;
      ul {
        li {
          float: left;
          margin: 0px 20px;
          line-height: 53px;
          color: rgba(122,132,147,1);
          span {
            cursor: pointer;
            text-decoration: none;
            &:hover {
              color: rgba(255,255,255,1); } }
          a {
            text-decoration: none;
            color: rgba(122,132,147,1);
            &:hover {
              color: rgba(255,255,255,1); } } } }
      .active-text {
        border-bottom: 3px solid rgba(255,255,255,1);
        span {
          color: rgba(255,255,255,1); }
        a {
          color: rgba(255,255,255,1);
          text-decoration: none; } } } } }

.navbar-product-list {
  border-radius: 4px;
  margin-top: 4px;
  background: #fff;
  width: 150px;
  text-align: center;
  box-shadow: 0 0 14px #BABABA;

  li {
    font-size: 14px;
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $gray-lighter;
      a {
        color: $gray-dark;
        line-height: 1;
        display: block;
        text-decoration: none;
        &.active {
          border-radius: 4px;
          a {
            color: #1bb09d; } } } } } }
.navbar-icon-color {
  color: $navbar-top-icon-color; }
.notification-list-card {
  min-width: 200px!important;
  .ant-popover-inner-content {
    padding: 0;
    .notification-list {
      margin-bottom: 0;
      max-height: 60vh;
      padding: 0 24px;
      overflow: auto;
      .notification-item {
        display: flex;
        width: 288px;
        padding: 8px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #e7e7eb; }
        .notification-icon {
          margin-right: 16px; }
        .notification-detail {
          width: 100%;
          line-height: 20px;
          .notification-name {
            font-weight: 600; }
          .notification-content, .notification-time {
            color: #7a8493; }
          .download-item {
            display: flex;
            justify-content: space-between;
            .file-name {
              max-width: 184px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-right: 8px;
              line-height: 22px;
              &.active {
                cursor: pointer;
                color: #39b8fe; } } } } } }
    .operate-btn-content {
      display: flex;
      border-top: 1px solid #e7e7eb;
      .operate-text-btn {
        height: 32px;
        line-height: 32px;
        width: 50%;
        text-align: center;
        cursor: pointer;
        text-align: center;
        &:first-child {
          border-right: 1px solid #e7e7eb; } } }
    .spinnerBox, .table-null-data {
      padding: 16px 0; } }

  .spinnerBox {
    margin: 0 auto; } }
@media screen and (min-width: 768px) {
  #nav {
    min-width: 1134px; } }

@media screen and (min-width:768px )and(max-width: 850px) {
  .active-cemyun {
    ul {
      li {
        margin: 0px 10px !important; } } }
  .product-span-select {
    margin-right: 16px !important; } }
@media screen and (max-width: 768px) {
  #nav {
    .product-mobileSelect {
      display: block;
      height: 100%;
      color: white;
      font-size: 29px;
      position: absolute;
      right: 10px; }
    .cemyun-logo {
      display: none !important; }
    .cemyun-logo1 {
      display: block !important;
      margin: 0px !important;
      width: 28px;
      height: 28px;
      margin-top: -5px !important; }
    .navbar-tools {
      display: none; }
    .container {
      .sams-style.navbar-brand,.ipsos-style.navbar-brand {
        padding: 15px;
        width: 63px;
        .cemyun-logo1 {
          display: block !important;
          margin: 0px auto!important; }
        i {
          height: 28px; } }
      .ipsos-style.navbar-brand {
        .cemyun-logo1 {
          width: 28px; } }
      .navbar-brand {
        width: 63px; }
      .product-span-select {
        display: none !important;
        .navbar-collapse {
          .nav-top-menu {
            display: none !important; } } }
      .active-cemyun {
        ul {
          li {
            margin: 0 18px; } } }
      .nav-top-dropdown-trigger {
        display: none; } } } }


@media screen and (max-width: 576px) {
  #nav {

    .navbar-brand {
      display: none; }
    .navbar-tools {
      display: none; }
    .container {
      .active-cemyun {
        ul {
          li {
            margin: 0 0.18rem;
            font-size: 0.28rem; } } } } } }
