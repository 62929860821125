@import "../../static/sass/variables";

.uxs-breadcrumb {
    font-size: 12px;
    color: #7A8493;
    padding: 0 0 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px !important;
    li {
        cursor: pointer;
        display: inline-block;
        &:first-child {
            color: $gray;
            &:hover {
                color: $green-hover; } }

        span {
            color: #7A8493; }
        & + li:before {
                content: "/\A0";
                padding: 0 10px;
                color: #ccc; } } }
.top-bread-crumb {
    padding-top: 16px; }
.buttonBar {
    .buttonBar-optimizeDate {
        color: #7A8493;
        .ant-calendar-picker {
            width: 128px;
            height: 32px;
            margin-left: 4px;
            margin-right: 8px; } }
    .contrast-cycle {
        color: #7A8493;
        line-height: 32px; }
    .buttonBar-period {
        width: 64px;
        height: 32px;
        margin-left: 4px;
        margin-right: 8px;
        .dropdown {
            width: 64px !important;
            background-color: #F3F3F4;
            border-color: rgba(0, 0, 0, 0.07) !important; } } }
.dropdownselect {
    margin-bottom: 16px; }
.dropdownselect button {
    width: 96px; }
button.ant-btn {
    min-width: 0px; }
.dropdown {
    background-color: #F3F3F4;
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    color:  #050A13; }
.hasNoBorder {
  .ant-dropdown-trigger {
    background-color: rgba(243,243,244,1) !important;
    &:hover,&:active {
      background-color: rgba(243,243,244,1) !important; } } }
.buttonBar .contrast-cycle {
  padding-left: 16px;
  padding-right: 4px; }
