.shareCascader {
    width: 160px!important;
    height: 32px;
    // border: 1px solid #D9D9DE
    opacity: 1;
    border-radius: 4px; }
.objCascader {
    width: 128px!important;
    height: 32px;
    // border: 1px solid #D9D9DE
    opacity: 1;
    border-radius: 4px; }
.rolecascader {
    width: 293px!important;
    // border: 1px solid #D9D9DE
    opacity: 1;
    border-radius: 4px;
    margin: 0 8px; }
.shareDeleteIcon :hover {
    color: #2EC84E; }
.shareDeleteIcon {
    display: none; }
.shareModelDiv :hover .shareDeleteIcon {
    display: inline-block; }
