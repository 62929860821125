.am-popover {
    position: fixed;
    top: 45px !important;

    .am-popover-item {
        width: 100px;
        height: 39px;
        .am-popover-item-active {
            background: #fff !important; } }
    .am-popover-item-container {

        padding: 0px; }
    .am-popover-item-content {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(122,132,147,1);
        .mobilePicker {
          .mobilePicker_icon {} } }
    a {
        color: #7A8493 !important;
        .iconfont {
            font-size: 14px !important;
            font-weight: 400;
            line-height: 24px;
            color: rgba(122,132,147,1);
            padding-right: 5px; } } }

.ant-dropdown.navbar-dropdown {
    width: 2rem;
    .ant-dropdown-menu {
        .ant-dropdown-menu-item {
            height: 0.78rem;
            padding: 0 0.16rem;
            line-height: 0.78rem;
            a {
                padding: 0 3px;
                margin: 0; }
            &:not(:first-child) {
                a {
                    border-top: 1px solid #ddd; } } } } }
