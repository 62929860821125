@mixin rule-for-select-delete-icon() {
    .analyseSelect-item-icon {
        margin-left: 8px;
        .condition-delete {
            visibility: hidden;
            color: #1bb09d; } }
    &:first-child {
        .analyseSelect-item-icon {
            margin-left: 8px;
            .condition-delete {
                visibility: hidden;
                color: #1bb09d; } } }
    &:hover {
        .analyseSelect-item-icon {
            margin-left: 8px;
            .condition-delete {
                visibility: visible;
                color: #1bb09d; } } } }
.analyseForm {
    padding: 16px;
    .ant-row {
        margin: 0 !important; }
    .ant-form-item {
        margin-bottom: 10px;

        .ant-form-item-children {
            display: flex;
            line-height: 0;
            align-items: center;
            margin-bottom: 24px;
            .analyseSelect {
                .analyseSelect-item {
                    line-height: 0;
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;
                    @include rule-for-select-delete-icon(); }
                .analyseSelect-button {
                    padding-left: 26px; } }
            .subDividedDimSelect {
                .subDividedDimSelect-button {
                    padding-left: 26px; } }
            .surveySelect {
                .surveyItem_list-item {
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0px; }
                    @include rule-for-select-delete-icon(); } } }
        .ant-form-item-label {
            display: flex;
            align-items: center;
            padding: 0;
            overflow: visible;
            text-align: left;
            // @media screen and (max-width: 1600px)
            //     width: 85px
            label {
                margin-bottom: 8px;
                color: #7A8493; } } } }
