@import "./../../static/sass/variables";

.mobile-sidebar {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    .sidebar-main {
        width: 105px;
        height: calc(100% - 56px);
        display: flex;
        flex-direction: column;
        .highlight {
            position: absolute;
            height: 18px;
            width: 2px;
            left: 10px;
            top: 19px;
            background: #1F80DE;
            border-radius: 2px; }
        .sidebar-icon-container {
            display: flex;
            align-items: center;
            height: 30px; }
        .sidebar-menu-item {
            position: relative;
            padding: 16px 12px 16px 22px;
            align-items: center;
            display: flex;
            background-color: #f5f5f5;
            font-size: 16px;
            letter-spacing: 9px; }
        .isActive {
            background-color: white;
            color: #1F80DE; }
        .isTop {
            border-radius: 0 0 8px 0;
            border: 4px #f5f5f5; }
        .isBottom {
            border-radius: 0 8px 0 0;
            border: 4px #f5f5f5; }
        .other {
            height: 100%;
            background-color: #f5f5f5;
            padding: 30px 12px;
            display: flex;
            flex-direction: column-reverse; } }

    .sidebar-content {
        flex: auto;
        overflow: auto;
        .active {
            background-color: #1F80DE;
            color: white; }
        .sidebar-item {
            display: flex;
            align-items: center;
            height: 52px;
            font-size: 12px;
            padding-left: 40px; }
        .sidebar-item:hover {
            background-color: #2C90F1;
            color: white; } }

    .myCheckList {
        --border-top: 0;
        --border-bottom: 0; }
    .checkItem {
        display: flex;
        justify-content: center; } }
.searchBarContainer {
    padding: 12px;
    border-bottom: 0; }
.checkContainer {
    height: 300px !important;
    overflow-y: scroll; }
