

@import '../../../static/sass/variables.sass';
.funnel-card {
    position: relative;
    overflow-x: auto;
    .funnel-title {
        height: 41px;
        line-height: 41px;
        font-size: 16px;
        color: #050A13;
        padding-left: 15px; }
    .e-funnel {
        width: 600px;
        position: absolute!important;
        z-index: 1; }
    .funnel-table {
        width: 100%;
        tr {
            height: 159px;
            border-top: 1px solid #e9ecee;
            border-bottom: 1px solid #e9ecee;
            td {
                border-bottom: none; }
            .title-content {
                padding-left: 50px;
                h5 {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    width: 240px;
                    line-height: 159px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 400;
                    text-align: left;
                    a {
                        color: #050A13;
                        margin-left: 20px; } } }
            .funnel-step {
                display: inline-block;
                text-align: center;
                width: 18px;
                height: 18px;
                line-height: 18px;
                border-radius: 50%;
                border: 1px solid #050A13;
                color: #050A13;
                margin: 0 4px;
                font-size: 12px; }
            .info-content {
                padding: 0 0 0 600px;
                color: #050A13;
                display: flex;
                min-width: 280px;
                max-width: auto;
                height: 159px;
                position: relative;
                .funnel-table-data {
                    width: 250px;
                    padding: 8px 16px;
                    background: #fff;
                    position: absolute;
                    top: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    z-index: 3;
                    p {
                        font-size: 12px;
                        line-height: 25px;
                        text-align: left;
                        margin-bottom: 0;
                        span {
                            line-height: 25px;
                            margin-right: 10px;
                            font-size: 12px; }
                        .ant-switch-small {
                            margin-right: 15px; } }
                    .info-tips {
                        color: #1bb09d;
                        text-decoration: underline;
                        cursor: pointer; }
                    .exit-percent {
                        color: #F23055; }
                    .entry-list {
                        position: relative;
                        height: 22px;
                        p {
                            font-size: 12px; } } } }
            .funnel-btn-content {
                position: relative;
                .new-search-btn {
                    float: left; }
                .funnel-step-analyse-link {
                    display: none;
                    position: absolute;
                    top: 0px;
                    right: 0px; } }
            .funnel-conversion-rate {
                width: 100px;
                height: 40px;
                font-size: 26px;
                line-height: 40px;
                background: #fff;
                border: 1px solid #D9D9DE;
                border-radius: 4px;
                color: #050A13;
                position: absolute;
                left: 250px;
                margin-top: 140px;
                z-index: 2;
                text-align: center; }
            .funnel-last-step {
                color: #050A13;
                .funnel-last-step-content {
                    height: 180px;
                    padding-top: 50px;
                    padding-left: 210px;
                    .funnel-last-step-title {
                        width: 180px;
                        line-height: 18px;
                        margin-bottom: 6px;
                        margin-left: 20px;
                        font-size: 14px; }
                    .funnel-last-step-data {
                        width: 180px;
                        height: 72px;
                        color: #ffffff;

                        border-radius: 4px;
                        line-height: 24px;
                        font-size: 16px;
                        padding: 14px 0;
                        p {
                            margin-bottom: 0;
                            text-align: center; } } } } }
        tr:hover {
            .funnel-step-analyse-link {
                display: inherit; } } } }


.entryUrl-card {
    .ant-popover-inner-content {
        padding: 0;
        .ant-list {
            border: none;
            .list-content {
                    width: 240px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    a {
                        color: #1bb09d;
                        width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: left; } }
            .ant-list-header {
                padding: 8px 16px; }
            .ant-list-item {
                padding: 8px 16px;
                .unable-link {
                    color: #050A13; } } } } }
#funnel-convert {
    .boardSummaryCard {
        margin-top: 8px;
        margin-bottom: 8px; } }
@media screen and (max-width: 768px) {
    .funnel-card {
        overflow: hidden;
        .e-funnel {
            width: 100%;
            position: absolute!important; }
        .funnel-table {
            width: 100%;
            tr {
                .funnel-conversion-rate {
                    line-height: 0.54rem;
                    font-size: 0.44rem;
                    padding-right: 0;
                    position: absolute;
                    width: 1.6rem;
                    height: 0.54rem;
                    left: 50%;
                    margin-left: -0.8rem; }
                .funnel-last-step {
                    color: #050A13;
                    padding: 0;
                    .funnel-last-step-content {
                        margin-left: -2.25rem;
                        .funnel-last-step-title {

                            line-height: 0.24rem;
                            margin-bottom: 0.11rem;
                            font-size: 0.28rem; }
                        .funnel-last-step-data {
                            box-sizing: border-box;
                            height: 1.1rem;
                            color: #ffffff;
                            background: $green-init;
                            border-radius: 0.04rem;
                            line-height: 0.48rem;
                            font-size: 0.28rem;
                            padding: 0.08rem 0.14rem; } } } } } } }

