@import '../../static/sass/mixin';

.event-select {
    padding-top: 16px;
    .event-select-search {
        margin: 16px 0;
        .ant-input-affix-wrapper {
            width: 600px;
            #current_page {
                padding-right: 90px; } } }
    .event-select-content {
        width: 100%;
        height: calc(100vh - 150px);
        border: 1px solid #D9D9DE;
        display: flex;
        flex-direction: column;
        .event-select-tools {
            border-bottom: 1px solid #D9D9DE;
            padding: 0 16px 16px;
            flex: none;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            li {
                margin-top: 16px;
                margin-right: 32px;
                line-height: 32px;
                height: 32px;
                .ant-switch {
                    margin-left: 8px; }
                .color-block-content {
                    &:nth-child(1) {
                        margin-right: 24px; } }
                &:nth-child(4) {
                    padding: 4px;
                    display: flex;
                    span {
                        display: inline-block;
                        vertical-align: top;
                        height: 24px;
                        line-height: 24px; } } } }
        .event-select-page {
            padding: 16px;
            flex: auto;
            margin: 0 auto;
            iframe {
                border: none; } } } }
.cemyun-error-waring {
    p {
        margin-bottom: 0; } }
