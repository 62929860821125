// 时间筛选框

// $bg 大下拉框的背景色
// $hover-bg,
// $text,
// $hover-text,
// $main,
// $border,
// $disabled,
// $btn-text-hover,
// $body
@mixin type-for-calendar($bg,$hover-bg,$text,$hover-text,$main,$border,$disabled-bg,$btn-text-hover,$body,$disabled-text) {

    .ant-calendar-my-select {
        a {
            color: $hover-text !important; } }
    .ant-calendar-range {

        .ant-calendar-range-middle {
            color: $text; }
        .ant-calendar-range-right {
            .ant-calendar-header {
                border-right: 1px solid $border !important; }

            .ant-calendar-body {
                border-right: 1px solid $border !important; } } }
    .ant-calendar-input-wrap {
        border-bottom: 1px solid $border !important; }
    .ant-calendar {
        color: $text;
        background-color: $bg !important;
        border: 1px solid $border;
        .ant-calendar-footer {
            border-top: 1px solid $border !important;
            .rangePicker-btn {
                background-color: $hover-bg !important;
                color: $hover-text !important;
                &.active {
                    background-color: $main !important;
                    color: #ffffff !important; }
                &:hover {
                    background-color: $main !important;
                    color: #ffffff !important; } } }
        .ant-calendar-body {
            border-top: 1px solid $border !important; }

        .ant-calendar-input {
            color: $text;
            background-color: transparent !important; }

        .ant-calendar-in-range-cell {
            &:before {
                background: $hover-bg !important; } }

        .ant-calendar-today .ant-calendar-date {
            border-color: $main !important; }
        .ant-calendar-selected-start-date .ant-calendar-date,
        .ant-calendar-selected-end-date .ant-calendar-date, {
            color: #ffffff !important; }

        .ant-calendar-selected-date .ant-calendar-date:hover,
        .ant-calendar-selected-start-date .ant-calendar-date:hover,
        .ant-calendar-selected-end-date .ant-calendar-date:hover {
            background-color: $main;
            color: #ffffff !important; } }
    .ant-calendar-date {

        color: $text;
        &:hover {
            color: $hover-text;
            background-color: $hover-bg; } }
    .ant-calendar-disabled-cell {
        .ant-calendar-date {
            background-color: $disabled-bg;
            color: $disabled-text; } }
    .ant-calendar-selected-day {
        .ant-calendar-date {
            background-color: $main; } }
    .ant-picker-cell-in-view {
        &.ant-picker-cell-today {
            .ant-picker-cell-inner {
                &::before {
                    border-color: $main !important; } } }
        &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
                background-color: $main; } } }
    .ant-picker-header-view {
        button {
            &:hover {
                color: $hover-text; } } }
    .ant-picker-focused {
        border-color: $main;
        box-shadow: 0 0 0 2px rgba(31, 128, 222, .2); }
    .ant-picker {
        &:hover {
            border-color: $main; } } }



// pageheader的按钮
@mixin type-for-pageheader-button($border,$text,$arrow-color) {

    .page-header {
        .filters {
            .filters-wrapper {
                .ant-calendar-picker {
                    outline: none;
                    .ant-calendar-picker-input {
                        border: 1px solid $border;
                        input,.ant-calendar-picker-icon, {
                            color: $text; } } }
                .time-span-select {
                    .dropdown {
                        border: 1px solid $border !important;
                        span,i {
                            color: $text; } } } } } }
    .ant-select-selection {
        border: 1px solid $border !important;
        color: $text !important;
        .ant-select-arrow {
            color: $arrow-color; } } }
// pageheader的下拉框
@mixin type-for-pageheader-dropdown($bg,$hover-bg,$text,$hover-text) {
    .ant-dropdown-menu {
        background-color: $bg;
        ul {
            li {
                color: $text !important;
                &:hover {
                    color: $hover-text;
                    background-color: $hover-bg !important; } } } }

    .ant-dropdown-menu-item,.ant-dropdown-menu-submenu-title {
        color: $text !important;
        &:hover {
            color: $hover-text !important;
            background-color: $hover-bg !important; } }
    .ant-select-dropdown {
        background-color: $bg !important;

        span {
            color: $text; } }
    .ant-select-dropdown-menu {
        .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
            background-color: $hover-bg !important;
            color: $hover-text !important; } }
    .ant-select-dropdown {
        .ant-select-dropdown-menu {
            li {
                background-color: $bg !important;
                color: $text;
                &:hover {
                    color: $hover-text !important; } } } }

    .ant-divider {
        ant-divider-horizontal {
            background-color: $text; } }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
        color: $hover-text !important;
        background-color: $hover-bg !important; }
    .ant-select-dropdown-menu-item-active {
        color: $hover-text !important; } }
// 弹窗
@mixin type-for-modal($bg,$hover-bg,$text,$hover-text,$main,$border,$disabled,$btn-text-hover,$body) {
    .ant-modal-close-x {
        color: $hover-text  !important; }
    .ant-modal-content {
        background-color: $bg !important;

        .ant-modal-header {
            background-color: $bg !important;
            border-color: $border;
            .ant-modal-title {
                color: $hover-text  !important; } }
        .ant-modal-body {
            .setStyleModal-item {
                &.false {
                    border-color: $border; }
                &.active {
                    border-color: $main; }
                h5 {
                    color: $hover-text; } } }
        .ant-modal-footer {
            border-color: $border;
            button.ant-btn-primary {
                background-color: $main !important; } } } }
// 桑基图
@mixin type-for-sankey($nodeColor...) {
    $newNodeColor: $nodeColor;
    .highcharts-sankey-series {
        @each $color in $newNodeColor {
            $i: index($newNodeColor,$color);
            .highcharts-color-#{$i - 1} {
                fill: $color !important; } } } }
// 分页
@mixin type-for-pagination($text,$hover-text,$disabled,$main,$border) {
    ul {
        &.ant-pagination {
            &.ant-pagination-disabled.ant-pagination-prev {
                li {
                    a {
                        color: $disabled !important; } } }
            li {
                a {
                    color: $text !important;
                    &:hover {
                        color: $hover-text !important; } }
                &.ant-pagination-item-active {
                    border-color: $main !important;
                    color: $main !important; } }
            span {
                &.ant-pagination-item-ellipsis {
                    color: $text !important; } } } } }
// checkbox颜色
@mixin type-for-checkbox($main-color) {
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color:$main-color !important {}
            border-color:$main-color !important {} } }
    .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
            &:after {
                background-color:$main-color !important {} } } }
    .ant-checkbox:hover,.ant-checkbox-wrapper:hover {
        .ant-checkbox-inner {
            border-color:$main-color !important {} } }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $main-color !important; }

    .ant-radio-checked {
        .ant-radio-inner {
            border: 1px solid $main-color!important; }
        .ant-radio-inner::after {
            background-color:$main-color !important {} } }
    .ant-radio-checked::after {
        border: 1px solid $main-color!important; } }
