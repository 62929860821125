@import '../../static/sass/mixin';
.spinnerBox {
  text-align: center;
  &.small-type {
    max-width: 30px;
    .small-ui-spinner {
        margin: 0;
        display: inline-block; }
    &.center {
      margin: 0 auto; } }
  &.normal-type {
    height: 358px;
    width: 100%;
    position: relative;
    .ui-spinner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; } }
  .ui-spinner {
    width: 30px;
    height: 20px;
    text-align: center;
    font-size: 10px;
    div {
      height: 100%;
      width: 4px;
      margin: 0 2px;
      display: inline-block;
      -webkit-animation: ui-spinner--sk 1.2s infinite ease-in-out;
      animation: ui-spinner--sk 1.2s infinite ease-in-out; }


    div.ui-spinner__rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s; }

    div.ui-spinner__rect3 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s; } } }


.loading-summary {
    overflow: hidden;
    height: 100%;
    .ant-skeleton-content {
        padding: 0px;
        .ant-skeleton-paragraph {
            margin: 16px 0px 0px 0px; } } }

@media screen and (max-width: 768px) {
  .loading-summary {
      overflow: hidden;
      height: 100%;
      .ant-skeleton-paragraph {
          margin: 0.16rem 0rem 0rem 0rem; } }
  .spinnerBox {
      &.normal-type {
          height: 5rem; } } }
@-webkit-keyframes ui-spinner--sk {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.6); }
  20% {
    -webkit-transform: scaleY(1.0); } }


@keyframes ui-spinner--sk {
  0%, 40%, 100% {
    transform: scaleY(0.6);
    -webkit-transform: scaleY(0.6); }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0); } }

@mixin spinner-color($main-color) {
  .spinnerBox {
    .ui-spinner {
      width: 30px;
      height: 20px;
      text-align: center;
      font-size: 10px;
      >div {
        background-color: $main-color; } } } }

@include spinner-color($main-color:#14c4a2);

