@import "./../../static/sass/variables";

.footer {
    background: transparent;
    //height: $footer-height
    flex-direction: column;
    padding-top: 20px;
    p {
        text-align: center;
        font-size: 12px;
        margin-bottom: 20px;
        color: #7A8493; }
    img {
        width: 132px;
        height: 34px; } }
