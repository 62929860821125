@import "../../../static/sass/variables.sass";

.mobile-filters-outer {
    display: flex;
    justify-content: space-between;
    min-height: 32px;
    width: calc(100% - 10px);
    .mobile-filters-outer-tag {
        max-width: 250px;
        word-break: break-all;
        background: #fafafa;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 0 7px;
        min-height: 20px;
        line-height: 32px;
        min-width: 20px;
        font-size: 12px; } }

.mobile-filters {
    display: flex;
    position: fixed;
    width: 100%;
    height: calc(100% - 56px);
    top: 56px;
    left: 0;
    z-index: 999;
    background-color: white;
    flex-direction: column;
    .mobile-filters-header {
        display: flex;
        height: 50px;
        width: 100%;
        align-items: center;
        padding: 0px 10px;
        justify-content: space-between;
        .mobile-filters-reset {
            width: 80px;
            height: 32px;
            line-height: 1; }
        .mobile-filters-back {
            width: 80px;
            height: 32px;
            line-height: 1; } }
    .mobile-filters-content {
        flex: 1;
        overflow: auto;
        overflow-x: hidden; }
    .mobile-filters-footer {
        height: 80px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .mobile-filters-back {
            width: 40%;
            height: 40px;
            line-height: 1;
            margin-right: 44px; }
        .mobile-filters-sure {
            width: 100%;
            height: 40px; } } }

.mobile-filters-item {
    min-height: 45px;
    .mobile-filters-item-header {
        padding: 10px 10px 0px 10px;
        font-size: 16px;
        display: flex;
        justify-content: space-between; }
    .mobile-filters-empty-select-header {
        align-items: center;
        height: 50px;
        padding: 0 10px; }
    .mobile-filters-item-content {
        padding: 10px 10px; }
    .mobile-filters-empty-select-content {
        // display: none
        padding: 0; } }
.mobile-filters-divider {
    margin: 0; }

.mobile-reset-modal {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 150px;
    background-color: white;
    border-radius: 4px;
    .mobile-reset-modal-main {
        height: 110px;
        line-height: 110px;
        text-align: center;
        border-bottom: 1px #D9D9DE solid; }
    .mobile-reset-modal-buttons {
        display: flex;
        height: 40px; }
    .mobile-reset-modal-button {
        width: 50%;
        text-align: center;
        line-height: 40px; } }

.quick-filter-item.pc-quick-filter {
    position: relative;
    margin-right: 8px;
    font-size: 12px;
    .quick-filter-select {
        width: auto;
        min-width: 180px;
        .ant-input {
            font-size: 12px; }
        .ant-select-selection--multiple {
            height: 32px; }
        .ant-select-selection {
            background-color: transparent;
            .ant-select-selection__rendered {
                min-height: 34px;
                display: flex;
                .ant-select-selection__placeholder {
                    font-size: 12px;
                    line-height: 16px; }
                &>ul {
                    display: flex;
                    flex-wrap: wrap;
                    .ant-select-selection__choice {
                        margin-top: 5px;
                        background: transparent!important;
                        .ant-select-selection__choice__content {
                            font-size: 12px;
                            color: #050A13; } } }
                .ant-select-selection__placeholder {
                    margin-left: 12px; } } } }
    .quick-filter-single-select {
        .ant-select-selection {
            .ant-select-selection__rendered {
                padding-left: 60px;
                margin-right: 32px;
                .ant-select-selection-selected-value {
                    line-height: 32px; } } } }
    .quick-filter-item-title {
        position: absolute;
        top: 0;
        left: 8px;
        line-height: 32px;
        color: #7a8493;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 2; }
    .quick-filter-item-title-single {
        width: auto; }
    .quick-filter-picker {
        .ant-calendar-picker-input {
            padding-left: 50px;
            width: 360px!important; } } }
.quick-filter-btn.pc-quick-filter {
    // margin-left: 8px
    height: 32px;
    font-size: 12px;
    &.clean-btn {
        border: 1px solid rgba(0, 0, 0, 0.07);
        margin-left: 8px;
        background-color: transparent;
        width: 80px;
        color: #7a8493; }
    &:focus {
        background-color: transparent; } }
.pc-quick-filter {
    margin-bottom: 8px; }
.quick-filter-item {
    .ant-input {
        background: transparent; } }
.quick-filter {
    .mobile-quick-filter {
        display: none!important; } }
.mobile-quick-filter-item {
    display: none; }

@mixin type-for-btn($main-color) {
    .quick-filter-icon-btn.pc-quick-filter {
        margin-left: 8px;
        padding: 0 8px;
        color: $main-color; } }
#quick_filter_form {
    .quick-filter-modal-group-title {
        font-weight: 600;
        margin: 16px 0; }
    .quick-filter-modal-checkbox {
        margin-bottom: 16px;
        .ant-checkbox-wrapper {
            width: 100%;
            >span {
                &:nth-child(2) {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 32px);
                    margin-bottom: -4px; } } } } }
@media screen and (max-width: 768px) {
    .pc-quick-filter {
        display: none!important; }
    .quick-filter {
        width: 100%;
        .mobile-quick-filter {
            transition-duration: .3s;
            position: absolute;
            top: 50px;
            left: 0;
            // width: calc(100vw - 24px)
            width: 100%;
            height: 100%;
            z-index: 2;
            padding: 0.4rem 0.4rem 0.48rem; }
        .quick-filter-item, .quick-filter-btn {
            display: none; }
        .mobile-quick-filter-item {
            margin-left: 0!important;
            .quick-filter-select {
                display: block;
                margin-left: 0;
                margin-top: 0.16rem;
                width: 100%; }
            &:last-child {
                margin-right: 0; }
            &:not(:last-child) {
                .quick-filter-select {
                    margin-bottom: 0.32rem; } }
            .am-search {
                background-color: transparent;
                padding: 0;
                .am-search-input {
                    border: 1px solid rgba(0, 0, 0, 0.07);
                    height: 32px;
                    .am-search-value {
                        padding: 0 28px 0 48px;
                        &::-webkit-input-placeholder {
                            color: transparent!important; }
                        &::-moz-input-placeholder {
                            color: transparent!important; }
                        &:-ms-input-placeholder {
                            color: transparent!important; } } } } }
        .first-mobile-quick-filter-item {
            display: flex;
            width: 60%;
            .quick-filter-select {
                margin-top: 0;
                margin-bottom: 0; } }
        .mobile-quick-filter.hide {
            height: 0;
            display: block!important;
            overflow: hidden; }
        .mobile-quick-filter.show {
            display: block!important;
            transition-duration: .5s;
            height: 6.2rem;
            max-height: 8rem;
            background-color: #fff;
            box-shadow: 0px 0px 10px #DBDFE5;
            &.scroll-mobile-quick-filter {
                overflow: auto; }
            &.visible-mobile-quick-filter {
                overflow: visible; }
            .quick-filter-item, .quick-filter-btn {
                display: block; }
            .mobile-quick-filter-footer {
                display: flex;
                .quick-filter-btn:not(:first-child) {
                    margin-left: 0.44rem; } } } } }
@include type-for-btn(#14C4A2);
