.editAndCreateIcon {
    visibility: hidden;
    background: transparent;
    transition: background 0.3s; }
tbody > tr:hover {
    .editAndCreateIcon {
        visibility: visible;
        background: #eff6f4; } }
.table-tools-content {
    position: relative;
    .editAndCreateIcon {
        position: absolute;
        height: 100%;
        right: 0;
        top: 0; } }
