@import "../../static/sass/variables.sass";

@mixin style-for-dropbox($main-color, $second-color) {
    .drop-box {
        margin-bottom: 16px;
        h5 {
            display: flex;
            height: 32px;
            line-height: 32px;
            margin-right: 4px;
            margin-bottom: 8px;
            align-items: center;
            text-align: center;
            color: #7a8493;
            .number {
                width: 14px;
                height: 14px;
                font-size: 12px;
                border: 1px solid #050A13;
                border-radius: 50%; }
            .warning {
                color: #f5222d;
                margin-left: 4px; }
            .require-tips {
                color: #ff6b5c;
                margin-left: 4px; }
            .iconfont {
                margin-left: 8px; } }
        .drop-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 62px;
            padding: 16px 8px;
            border: 1px dashed #e8e8e8;
            border-radius: 8px;
            text-align: center;
            position: relative;
            &.enter {
                border-color: $main-color; }
            .drop-placeholder {
                color: #7a8493;
                font-size: 12px;
                .iconfont {
                    font-size: 12px;
                    margin-right: 4px; } }
            .drop-card {
                height: 22px;
                line-height: 22px;
                font-size: 12px;
                margin: 2px 0;
                background-color: $main-color;
                color: #ffffff;
                border-radius: 4px;
                padding: 0 20px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                &.dragging {
                    z-index: 1; }
                &.preparing {
                    background-color: transparent;
                    border: 1px dashed #e8e8e8;
                    z-index: 0; }
                &.leaving {
                    transform: translateX(-9999px); }
                &:hover {
                    .icon-close {
                        display: block!important; } }
                &.tips-card {
                    display: flex;
                    justify-content: space-between;
                    .drop-card-name {
                        flex: 1 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-right: 8px;
                        text-align: center; }
                    .drop-card-tips {
                        font-size: 12px;
                        flex: 0 0; } }
                .icon-close {
                    display: none;
                    position: absolute;
                    right: 8px;
                    top: 0;
                    font-size: 12px; } }

            .mask {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: none;
                &.active {
                    display: block;
                    background-color: $main-color;
                    opacity: 0.1; } } } } }




@include style-for-dropbox(#14C4A2, rgba(20, 196, 162, 0.1));

