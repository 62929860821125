@import '../../static/less/variable.less';

.split-pane {
  display: flex;

  &-horizontal {
    flex-direction: row;
    min-height: 0;

    &-spliter {
      border-right: @border-width-base @border-style-base @border-color-split;
    }
  }

  &-vertical {
    flex-direction: column;
    min-width: 0;

    &-spliter {
      border-bottom: @border-width-base @border-style-base @border-color-split;
    }
  }

  &-1 {
    display: flex;
    min-height: 0;

    & > .react-resizable {
      display: flex;
      min-height: 0;
    }
  }

  &-2 {
    flex: 1;
    display: flex;
    min-height: 0;
    overflow: hidden;

    & > .react-resizable {
      display: flex;
    }
  }

  &-resize-handle {
    position: absolute;
    width: 8px;
    cursor: col-resize;
    user-select: none;

    &-horizontal {
      top: 0;
      bottom: 0;

      &-1 {
        right: -4px;
      }

      &-2 {
        left: -4px;
      }
    }

    &-vertical {
      left: 0;
      right: 0;

      &-1 {
        bottom: -4px;
      }

      &-2 {
        top: -4px;
      }
    }
  }
}

@border-color-base: #D9D9DE;@text-color: #050A13;@primary-color: #14c4a2;@select-border-color: @border-color-base;@select-dropdown-bg: #EFF6FA;@select-dropdown-font-size: 14;@select-item-selected-bg: #EFF6FA;@select-item-active-bg: #EFF6FA;@select-selection-item-bg: #EFF6FA;@select-selection-item-border-color: @border-color-base;