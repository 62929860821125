@import "../../static/sass/variables.sass";

@mixin type-for-combine($main-color, $second-color, $active-color, $active-half-color, $hover-color, $click-color) {
    .combine-layout {
        height: calc(100vh - 57px);
        background-color: #ffffff;
        padding: 0;
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        .SplitPane {
            height: calc(100vh - 57px)!important;
            .Pane2 {
                width: 200px; } }
        .operate-bar {
            display: flex;
            height: 100%;
            resize: horizontal;
            flex: 0 0 auto; }
        .widget-operate {
            flex: 9;
            height: 100%;
            overflow: hidden;
            border-right: 1px solid #e8e8e8;
            .widget-operate-title {
                padding: 0 24px;
                font-size: 16px; }
            .widget-operate-title {
                line-height: 40px; }
            .ant-input-search {
                .ant-input {
                    border-left: 0;
                    border-right: 0;
                    border-radius: 0; } }
            .ant-tabs {
                .widget-list {
                    margin: 8px 0;
                    height: calc( 100vh - 238px);
                    overflow: auto;
                    .widget-item {
                        display: flex;
                        padding: 0 24px;
                        line-height: 28px;
                        cursor: pointer;
                        font-size: 12px;
                        &:hover {
                            background-color: $second-color; }
                        .iconfont {
                            display: block;
                            margin-right: 8px;
                            color: $main-color;
                            font-size: 18px; }
                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 0; } } } } }
        .combine-setting {
            flex: 11;
            overflow: auto;
            border-right: 1px solid #e8e8e8;
            .combine-setting-title {
                line-height: 40px;
                font-size: 16px;
                padding: 0 16px; }
            .combine-layout-setting {
                padding: 0 16px;
                h5 {
                    line-height: 32px;
                    margin-right: 4px;
                    span {
                        color: #f5222d;
                        margin-left: 4px; } }
                .style-item {
                    margin-bottom: 16px;
                    text-align: center;
                    font-size: 12px;
                    &:hover {
                        .style-example {
                            border: 1px solid $hover-color;
                            box-shadow: 0px 0px 10px #DBDFE5;
                            .style-area {
                                background-color: $hover-color; } } }
                    &:active {
                        .style-example {
                            border: 1px solid $click-color;
                            .style-area {
                                background-color: $click-color; } } }

                    .ant-radio-wrapper {
                        font-size: 12px;
                        color: #999999;
                        &.ant-radio-wrapper-checked {
                            color: #050a13; } }
                    .style-example {
                        display: flex;
                        width: 100%;
                        height: 70px;
                        margin-bottom: 8px;
                        padding: 8px;
                        background-color: #f0f0f0;
                        border: 1px solid #f0f0f0;
                        cursor: pointer;
                        &.active {
                            background-color: $active-half-color;
                            border: 1px solid $active-half-color;
                            .style-area {
                                background-color: $active-color;
                                border: 1px solid #ffffff; } }
                        .style-area {
                            background-color: #dcdcdc;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: content-box;
                            border-radius: 4px;
                            &:first-child {
                                margin-right: 8px; }
                            .serial-number {
                                font-size: 12px;
                                color: #ffffff;
                                border-radius: 50%; } }
                        &.style-example-column {
                            flex-direction: column;
                            .style-area {
                                &:first-child {
                                    margin-right: 0;
                                    margin-bottom: 8px; } } }
                        &.style-example-1,
                        &.style-example-4 {
                            .style-area {
                                flex: 1 1; } }
                        &.style-example-2,
                        &.style-example-5 {
                            .first-style-area {
                                flex: 1 1; }
                            .second-style-area {
                                flex: 2 2; } }
                        &.style-example-6,
                        &.style-example-3 {
                            .first-style-area {
                                flex: 2 2; }
                            .second-style-area {
                                flex: 1 1; } } } } }
            .combine-style {
                font-size: 12px;
                .style-item {
                    padding: 16px;
                    &:not(:last-child) {
                        border-bottom: 1px solid #e8e8e8; }
                    .style-item-title {
                        font-weight: bold;
                        font-size: 12px; }
                    .style-setting {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        color: #7a8493;
                        margin-top: 8px;
                        .ant-switch {
                            margin-right: 44px; }
                        .box-setting {
                            display: flex;
                            margin-bottom: 8px;
                            align-items: center;
                            .box-setting-text {
                                margin: 0 8px; }
                            .ant-input-number {
                                width: 72px; } } } } }

            .drop-box {
                padding: 16px;
                .drop-item {
                    margin-bottom: 16px;
                    h5 {
                        display: flex;
                        height: 32px;
                        margin-right: 4px;
                        margin-bottom: 8px;
                        align-items: center;
                        text-align: center;
                        font-size: 12px;
                        .warning {
                            color: #f5222d;
                            margin-left: 4px; } }
                    .drop-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        min-height: 62px;
                        padding: 16px 8px;
                        border: 1px dashed #e8e8e8;
                        border-radius: 8px;
                        text-align: center;
                        position: relative;
                        &.enter {
                            border-color: $main-color; }
                        .drop-placeholder {
                            color: #7a8493;
                            font-size: 12px;
                            .iconfont {
                                margin-right: 4px; } }
                        .drop-card {
                            height: 22px;
                            line-height: 22px;
                            font-size: 12px;
                            margin: 2px 0;
                            background-color: $active-color;
                            color: #ffffff;
                            border-radius: 4px;
                            padding: 0 36px 0 20px;
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                            &.dragging {
                                z-index: 1; }
                            &.preparing {
                                background-color: transparent;
                                border: 1px dashed #e8e8e8;
                                z-index: 0; }
                            &.leaving {
                                transform: translateX(-9999px); }
                            &:hover {
                                background-color: $hover-color;
                                .iconfont {
                                    display: block; } }
                            .iconfont {
                                display: none;
                                position: absolute;
                                top: 0;
                                font-size: 12px;
                                color: #fff; }
                            .icon-close {
                                right: 8px; }
                            .icon-bianji {
                                right: 24px; } }
                        .mask {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            display: none;
                            &.active {
                                display: block;
                                background-color: $second-color; } } } } } }
        .chart-display {
            width: 100%;
            height: 100%;
            padding-bottom: 72px;
            background-color: #f3f3f4;
            padding: 16px;
            .chart-display-content {
                height: 100%;
                background-color: #ffffff;
                padding: 45px 60px;
                .card-name {
                    line-height: 14px;
                    height: 14px;
                    margin-bottom: 32px; } } } } }




@include type-for-combine(#14C4A2, rgba(20, 196, 162, 0.1), #56E2CE, rgba(86,226,206,0.5), #38cbae, #40ad98);

