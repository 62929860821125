// table样式
@import '../../containers/CommonTable/index.sass';
// 桑基图样式
@import '../../component/PageHeader/PageHeader.sass';
@import '../../component/PageHeader/GlobalControl/index.sass';
@import '../../containers/AnalyseCombination/index.sass';
@import '../../containers/AnalyseSurvey/SurveyNewChart/index.sass';
@import './component-style-mixin.sass';
@import './antd-base-style-mixin.sass';
@import './antd-mobile-style-mixin.sass';
@import '../../containers/CommonTab/index.sass';
@import '../../component/Sidebar/Sidebar.sass';
@import '../../component/Loading/Loading.sass';
@import '../../component/OrderNumber/index.sass';
@import '../../component/DropBox/index.sass';
@import '../../containers/AnalyseAlgorithm/index.sass';
@import '../../containers/Worksheet/Detail/index.sass';
@import '../../component/CardSetUp/index.sass';

//天虹主题色
$rainbow-main: #e9a171;
$rainbow-main-hover: #2C90F1;
$rainbow-main-active: #1879D6;
// 一级色
$rainbow-text-first: #050A13;
// 二级色
$rainbow-text-second: #7A8493;
// 边框
$rainbow-border: #D9D9DE;
// 禁用状态
$rainbow-disabled: #F5F5F5;
$rainbow-disabled-bg: #F5F5F5;
$rainbow-disabled-text: #CFCFCF;
// 背景色
$rainbow-bg: #F3F3F4;
// 交互一级色
$rainbow-mutual-first: rgba(233,161,113,0.1);
$rainbow-mutual-second: #E8F2FC;
// 二级导航背景和卡片背景
$rainbow-card-bg: #ffffff;
$rainbow-menu-active : rgba(233,161,113,0.2);
$rainbow-menu-normal : #ffffff;
$rainbow-clander-sperate: #F3F3F4;
// 一级菜单以及modal以及表格头部的颜色
$rainbow-menu-modal: #ffffff;
$rainbow-tablehead: rgba(233,161,113,0.1);
//分割线
$rainbow-splitLine: #E7E7EB;
$default-dataRise: #4DD658;
$default-dataDown: #FF6B5C;
body {
    &#rainbow {
        a {
            color: $rainbow-main; }
        color: $rainbow-text-first;
        background-color: $rainbow-bg;
        .analyseLayout_title-back {
            a {
                color: #ffffff; } }

        .analyseLayout_title-analyse {
            a {
                color: #ffffff; } }
        .page-index {
            .account {
                a:hover {
                    color: $rainbow-main; } } }
        .color-link {
            color: $rainbow-main!important; }
        .infomation {
            a {
                color: $rainbow-main; } }
        .ant-steps-item-process {
            .ant-steps-item-icon {
                background: $rainbow-main;
                border-color: $rainbow-main; } }
        .has-success.has-feedback {
            .ant-form-item-children-icon {
                color: $rainbow-main; } }
        .childrenFilter {
            .childrenFilter_logic {
                color: $rainbow-main; } }

        .AnalysePathStep-item {
            .AnalysePathStep-item-plus {
                .green {
                    color: $rainbow-main; } } }
        .insight {
            .green-font {
                color: $default-dataDown; }
            .red-font {
                color: $default-dataRise; } }

        .ant-drawer-wrapper-body {
            .ant-drawer-close:hover {
                color: $rainbow-main; } }
        .filterItem_logic {
            color: $rainbow-main !important; }
        .childrenFilter_add {
            color: $rainbow-main; }
        .condition-delete {
            color: $rainbow-main !important; }
        .groupSymbol_box {
            background-color: $rainbow-main; }
        .customBoard-desc-richTextBox {
            h1,h2,h3,h4,h5,p,span {
                color: $rainbow-text-first; } }
        .uxs-breadcrumb li:first-child:hover {
            color: $rainbow-main; }
        .eventTable_toolbar {
            span {
                color: $rainbow-text-first; } }
        .eventChart_toolbar {
            label {
                span {
                    color: $rainbow-text-first; } } }
        .ant-input:focus,.ant-input:hover {
            border: 1px solid $rainbow-main !important; }
        .page-header,.analyseLayout {}
        .ant-btn:hover,.ant-btn:focus {
            color: $rainbow-main;
            border-color: $rainbow-main; }
        .select {
            background-color: $rainbow-main!important; }
        .uxs-pointer {
            &:hover {
                color: $rainbow-main!important; } }
        .main-color-icon {
            color: $rainbow-main!important; }
        .commonSummary {
            .summary-col {
                .ant-card {
                    &.selected-item {
                        border: 1px solid $rainbow-main; } } } }
        .ant-select-dropdown {
            .field-type-icon {
                color: $rainbow-main; } }
        .widget-content {
            .widget {
                .ant-table-wrapper {
                    .ant-table-fixed-header {
                        th {
                            border-color: $rainbow-main; } } } } }
        @include color-for-ant-tab(#e9a171,#e9a171,#D9D9DE);
        // @include type-for-pageheader-dropdown($rainbow-menu-modal,$rainbow-mutual-first,$rainbow-text-second,$rainbow-text-first)
        @include type-for-pageheader-dropdown($rainbow-menu-normal,$rainbow-mutual-first,$rainbow-text-second,$rainbow-text-first);
        // $bg,$hover-bg,$text,$hover-text,$main,$border,$disabled
        @include type-for-calendar($rainbow-menu-modal,$rainbow-mutual-first,$rainbow-text-second,$rainbow-text-first,$rainbow-main,$rainbow-border,$rainbow-disabled-bg,$rainbow-text-second,$rainbow-clander-sperate,$rainbow-disabled-text);
        @include type-for-modal($rainbow-menu-modal,$rainbow-mutual-first,$rainbow-text-second,$rainbow-text-first,$rainbow-main,$rainbow-border,$rainbow-disabled,$rainbow-text-second,$rainbow-bg);
        @include style-for-commontable($rainbow-text-second,$rainbow-tablehead,$rainbow-text-first,$rainbow-mutual-first,$rainbow-splitLine,$rainbow-main);
        @include type-for-pagination($rainbow-text-second,$rainbow-text-first,$rainbow-disabled-text,$rainbow-main,$rainbow-border);
        @include style-for-surveySentiment($rainbow-text-second,#EEF6F9,$rainbow-text-second,$rainbow-main);
        @include style-for-sidebar($rainbow-main,$rainbow-menu-active);
        @include spinner-color($rainbow-main);
        @include type-for-orderNumber($rainbow-main,$rainbow-border,$rainbow-text-first);
        @include type-for-checkbox($rainbow-main);
        @include type-for-btn($rainbow-main);
        @include type-for-menu($rainbow-main, $rainbow-mutual-first, $rainbow-mutual-first, $rainbow-main);
        @include type-for-combine($rainbow-main, $rainbow-mutual-first, $rainbow-main, 	rgba(233,161,113,0.5), $rainbow-main, #1879d6);
        @include type-for-iconSelect(#2c90f1);
        @include style-for-dropbox($rainbow-main, $rainbow-mutual-first);
        @include type-for-worksheet($rainbow-main);
        .ant-select-sm {
            &.ant-select-enabled {
                .ant-select-selection {
                    border: none;
                    color: $rainbow-text-first; } } }
        .ant-select-enabled {
            .ant-select-selection {
                color: $rainbow-text-first; } }
        .ant-empty-description {
            color: $rainbow-border; }
        .ant-skeleton {
            .ant-skeleton-content {
                .ant-skeleton-paragraph {
                    li {
                        background: $rainbow-bg; } } } }
        .sankey-chart {
            .path-level-wrapper {
                .path-level {
                    span {
                        color: $rainbow-text-first; } } } }
        .footer {
            a {
                color: $rainbow-text-second; } }
        .setting {
            a {
                color: $rainbow-main; } }
        .funnel-table {
            .entry-list {
                .info-tips {
                    color: $rainbow-main; } } }
        .realtime-description-item {
            .description-item-title {
                a {
                    color: $rainbow-main; } } }
        .ant-select-tree-checkbox-wrapper {
            &:hover {
                .ant-select-tree-checkbox-inner {
                    border-color: $rainbow-main; } } }
        .ant-select-tree-checkbox {
            &:hover {
                .ant-select-tree-checkbox-inner {
                    border-color: $rainbow-main; } } }
        .ant-select-tree-checkbox-input {
            &:hover {
                .ant-select-tree-checkbox-inner {
                    border-color: $rainbow-main; } } }
        .board-control-item-icon {
            color: $rainbow-main; }
        .secondFieldsContent {
            border: 1px solid $rainbow-main;
            background-color: #EEF6F9; } } }

