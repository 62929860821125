@import "../../../static/sass/variables.sass";

@mixin type-for-worksheet($main-color) {
    .worksheet-detail {
        &>.spinnerBox {
            height: 102px; }
        .worksheet-detail-card {
            overflow: hidden;
            .worksheet-data {
                .tool-bar {
                    display: flex;
                    .btn-content {
                        .ant-btn {
                            &:not(:first-child) {
                                margin-left: 16px; } } }
                    .operate-tips {
                        margin-left: 16px;
                        line-height: 32px;
                        color: #7A8493;
                        font-size: 12px; }
                    .data-overview {
                        line-height: 32px;
                        display: flex;
                        .total {
                            margin-right: 16px;
                            .num {
                                color: #FF6B5C;
                                padding: 0 4px;
                                &.filter-trigger {
                                    cursor: pointer; } } } } } }
            .table-null-data {
                margin-bottom: 32px; }
            .clean-filter-trigger {
                color: $main-color;
                cursor: pointer; }
            .card {
                &:hover {
                    box-shadow: none; } }
            .long-string {
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis; } } }
    .fields-display-modal {
        .num {
            color: #ff6b5c; }
        .ant-row {
            margin-top: 16px;
            .field-item {
                margin-bottom: 16px;
                .ant-checkbox-wrapper {
                    &>span {
                        &:last-child {
                            display: inline-block;
                            max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: -5px; } } } } } }
    .customKeyModal {
        .operateCardContain {
            &:not(:last-child) {
                margin-bottom: 16px; }
            .operateLabel {
                color: rgba(0, 0, 0, 0.85);
                margin-right: 8px;
                padding-left: 11px; }
            .operateCard {
                border: 1px solid #d9d9de;
                &:last-child {
                    border-left: none; }
                .title {
                    margin: 11px 16px; }
                .operateContent {
                    .input {
                        width: calc(100% - 16px);
                        margin: 8px; }

                    .selectList {
                        overflow-y: auto;
                        overflow-x: hidden;
                        padding-top: 8px;
                        height: 240px;
                        .item {
                            width: 200px;
                            padding: 0 8px;
                            line-height: 24px;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 12px;
                            &:hover {
                                background: rgba(0, 0, 0, 0.06); } } }
                    .ant-tree-treenode-switcher-open {
                        .ant-tree-node-content-wrapper {
                            &:hover {
                                background-color: transparent; }
                            .ant-tree-child-tree {}
                            .ant-tree-switcher {
                                width: auto; } } } } } }
        .explain {
            height: 300px;
            overflow: auto;
            border: 1px solid #d9d9de;
            padding: 16px;
            .popoverContent {
                width: auto; }
            h5 {
                color: rgba(0, 0, 0, 0.85); }
            .detail {
                width: auto; } }
        .displayRemark {
            textarea {
                border-radius: 0;
                border-left: none; } } }
    .functionTip {
        max-width: none!important;
        .ant-tooltip-inner {
            background-color: rgba(0, 0, 0, 0.75) !important; } }
    .function-popoverContent {
        width: 280px;
        padding: 16px;
        height: 300px;
        overflow: auto;
        h5 {
            font-weight: 700;
            color: #fff; }
        .description {
            display: flex;
            margin-top: 8px;
            .title {
                color: #b2b3b9;
                width: 32px;
                flex: 0 0 auto; }
            .detail {
                margin-left: 8px;
                width: 230px;
                color: #fff; } } }

    .function-popoverContent::-webkit-scrollbar {
        display: none; }
    .worksheet-search-header {
        display: flex;
        padding-top: 8px;
        .search-dropdown {
            margin-right: 8px; } } }

@include type-for-worksheet(#14C4A2);
