
.analyseDistribute_config {
    .analyseDistribute_config-childrenFilter {
        .childrenFilter {
            .childrenFilter_add {
                position: absolute;
                top: -23px;
                visibility: visible;
                left: 72px; } } }
    .analyseDistribute_config-customSection {
        .ant-btn-addButton {
            margin-top: 8px; }
        .analyseDistribute_config-customSection-item {
            display: flex;
            align-items: center;
            margin: 8px 0px 0px 0px;
            &:hover {
                i {
                    visibility: visible; } }
            i {
                visibility: hidden; } } } }

.analyseDistribute_content {
    padding-top: 16px; }
