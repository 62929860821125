.survey-statistics {
    .commonchart {
        .uxs-chart {
            .chart-content {
                height: 300px; } } }
    .customBoard-dashBoard-hide {
        display: none; } }
.surveyName-remark-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 16px; }
