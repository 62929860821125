@import "./../../static/sass/variables";

.search-header {
    padding-top: 16px;
    padding-left: 0;
    .head-left {
        // float: left
        display: flex;
        .search-header-btn {
            margin-right: 8px; } } }
@media screen and (max-width: 768px) {
    .search-header {
        .head-left {
            .search-header-btn {
                margin-right: 0; }
            .ant-input-search {
                width: 100%!important; } } } }
