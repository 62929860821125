@import "../../static/sass/mixin";
@import "../../static/sass/variables";
.ant-pagination {
    margin-top: 10px;
    text-align: center;
    .ant-select-sm {
        .ant-select-selection__rendered {
            margin-right: 24px; }
        .ant-select-arrow {
            right: 7px; } } }
