.commonMixChart {
    padding-top: 16px;
    .commonMixChart_toggle {
        position: relative;
        white-space: nowrap;
        line-height: 24px;
        border: none;
        .ant-select {
            font-weight: 400 !important; }
        .ant-select-selection {
            border: none;
            box-shadow: none; }
        .ant-select-selection-selected-value {
            color: #050A13 !important; }
        .ant-select-arrow-icon {
            color: #050A13 !important; } } }
