@import "../../../static/sass/variables";
@import "../../../static/sass/mixin";

.warning-setting-modal {
    .warning-form {
        max-width: 1000px;
        margin: 0 auto;
        max-height: calc( 100vh - 108px);
        & > div {
            @include clearfix;
            margin-bottom: 20px;
            & > div {
                @include clearfix;
                width: 808px;
                float: left; }
            .input-name {
                float: left;
                line-height: 35px;
                padding-right: 28px;
                width: 130px;
                text-align: right; }
            #warning-filter {
                float: left;
                padding: 30px; }
            .warning-date-picker {
                float: left; } }
        .warning-type {
            & > div {
                width: auto;
                max-width: 808px; }
            .uxs-input-tag {
                float: left; } }
        .warning-frequency {
            .input-validator {
                width: 400px;
                float: left; }
            .uxs-select-wrapper {
                float: left; }
            & > div > span {
                height: 35px;
                line-height: 35px;
                float: left;
                padding: 0 12px; } }
        .modal-body {
            // height: calc( 100vh - 108px)
            overflow: auto; } } }
