@import "./../../static/sass/variables";

.highcharts-container {
  cursor: grab;
  overflow: visible !important; }
.highcharts-tooltip-box {
  fill: #050A13;
  // fill-opacity: 0.2
  stroke-width: 0;
  text {
    fill: white;
    text-shadow: 0 0 3px black; } }
@mixin type-for-sankey($nodeColor...) {
  $newNodeColor: $nodeColor;
  @each $color in $newNodeColor {
    $i: index($newNodeColor,$color);
    .highcharts-color-#{$i - 1} {
      fill: $color !important; } } }
.sankey-chart.sams_style,.sankey-chart.ipsos_style {
  .highcharts-sankey-series {
    cursor: pointer;
    .highcharts-sankey-other {
      opacity: 0.9; }
    .highcharts-sankey-exit {
      display: none; }
    @include type-for-sankey(#0B54B2, #3D76E9, #53ABFC, #64CEFC, #90E4F2, #958FEB, #F2AA62, #EBC15F, #9FCA5D);
    .highcharts-point.highcharts-node {
      opacity: 1; }
    .highcharts-point {
      opacity: 0.4; } } }
.highcharts-sankey-series {
  cursor: pointer;
  .highcharts-sankey-other {
    opacity: 0.9; }
  .highcharts-sankey-exit {
    display: none; }
  @include type-for-sankey(#14c4a2,#71EB8E,#C3FFB1,#238bdb,#2AC5FF,#85D9FF,#13a9c2,#23D6DC,#62EDF2,#F37623,#F9A360,#FEDA6E);
  .highcharts-point.highcharts-node {
    opacity: 1; }
  .highcharts-point {
    opacity: 0.4; } }
// .highcharts-color-101
//   fill: $sankey-node-static-color !important

// .highcharts-color-110
//   fill: #FFE3D0 !important

// .highcharts-color-101.highcharts-link
//   fill: $sankey-line-static-color !important
//   cursor: grab

// .highcharts-background
//   fill: white !important
// .highcharts-color-102
//   fill: transparent !important

// .highcharts-color-102.highcharts-link
//   fill: transparent !important
//   cursor: grab

.node-exit {
  display: none; }

// .highcharts-color-103
//   fill: $sankey-node-highlight-color !important

// .highcharts-color-103.highcharts-link
//   fill: $sankey-line-highlight-color !important
//   cursor: grab

// .highcharts-color-104
//   fill: $sankey-node-current-highlight-color !important

.node-format-data-label-exit-border {
  margin-left: 100px;
  border-right: 6px solid #FD6080;
  position: absolute;
  // -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75)
  // -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75)
  // box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75)
  border-radius: 0px 11px 0px 0px;
  -moz-border-radius: 0px 11px 0px 0px;
  -webkit-border-radius: 0px 11px 0px 0px; }

// 控制data-label样式
.node-format-data-label {
  span {
    font-size: 12px !important;
    top: 0px !important;
    font-weight: normal !important;
    .node-format-data-label-for-node {
      width: 72px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      height: 16px;
      line-height: normal;
      text-align: center;
      color: #7A8493; }
    .node-format-data-label-for-point {
      position: absolute;
      left: 50px;
      top: 50px; } } }

.highcharts-tooltip {

  > span {
    color: white!important;
    div.sankey-tooltip {
      min-width: 160px;
      // padding: 12px 16px
      .sankey-tooltip-content {
        display: flex;
        justify-content: space-between; } }
    div.sankey-tooltip-point {
      display: none;
      // word-wrap: break-word
      // table-layout: fixed
      // tr:first-child
      //   margin-bottom: 5px
      // td
      //   padding: 3px 6px
      //   i
 } } }      //     margin-right: 10px

.highcharts-background {
  fill: transparent !important; }
.sankey-chart-wrapper {
  height: 100%;
  padding: 0 0 20px 0;
  // overflow: auto
  .sankey-chart-header {
    padding: 12px 15px 12px 15px;
    font-size: $font-size-small;
    label {
      font-weight: normal;
      margin-right: 5px; }
    .ant-select {
      margin-right: 20px; }
    .dropdown {
      height: auto;
      line-height: inherit;
      &:not(:last-child) {
        margin-right: 45px; } }
    .path-breach-num, .path-datalabel {
      margin-right: 30px; } }

  .sankey-chart-content {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    display: flex;
    height: 100%;
    .add-length-before, .add-length-after {
      position: relative;
      // color: $gray
      text-align: center;
      width: 90px;

      p {
        position: relative;
        top: 0;
        font-size: $font-size-small;
        white-space: nowrap;
        &:not(:last-child) {
          margin: 0 0 12px; }
        > i {
          font-size: 30px; } } }


    .sankey-chart {
      // margin: 0 5px
      // padding: 10px 0 20px 0
      overflow-y: auto;
      overflow-x: hidden;
      .path-level-content {
        padding-top: 15px; }
      .path-level-wrapper {
        position: relative;
        padding: 12px 18px;


        .path-level {
          display: flex;
          justify-content: space-between;
          .path-level-step {
            font-size: 14px;
            display: flex;
            align-items: center;
            .step-num {
              width: 18px;
              height: 18px;
              line-height: 18px;
              display: inline-block;
              text-align: center;
              color: #050A13;
              background-color: transparent;
              border-radius: 50%;
              border: 1px solid #D9D9DE;
              margin: 0 4px; } } }

        .path-length-add {
          position: absolute;
          right: 0;
          top: 24px;
          font-size: 20px;
          color: $green-base; }

        & i {
          cursor: pointer;
          padding-left: 20px;
          font-size: 14px; } } } }


  table.summary {
    margin: 20px 0;
    tr:first-child {
      td {
        border-top: none !important; } }
    tr:last-child {
      td {
        border-bottom: none; } }
    td.string {
      i {
        font-size: 16px;
        margin-right: 12px; } } } }
