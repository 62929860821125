.widget-content-hideScrollBar {
    ::-webkit-scrollbar {
      display:none !important {} } }
.un-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.bi-chart-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  .widget-content {
    height: 100%;
    &.no-data-widget {
      height: 22px!important; }
    .widget {
      height: calc(100% - 20px);
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      position: relative;
      .widget-content {
        width: 100%;
        height: 100%; }
      .text-table {
        overflow: auto;
        .ant-radio-button-wrapper {
          padding: 0 8px; } }
      .ant-table-wrapper {
        padding-bottom: 4px;
        padding-top: 8px;
        &.text-table {
          padding-top: 0!important; }
        th {
          min-width: 34px; }
        .ant-table-fixed-header {
          th {
            border-color: #14c4a2; } }
        .disabled-btn {
          color: #CFCFCF; } }
      .cancel-linking {
        width: 100%;
        height: 100%;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        .iconfont {
          font-size: 20px;
          margin-right: 4px;
          line-height: 33px; } } }
    .drill-list {
      display: flex;
      color: #7A8493;
      margin-bottom: 0;
      .drill-item {
        display: flex;
        margin-right: 8px;
        .drill-item-name {
          margin-left: 8px;
          cursor: pointer; } } } } }
.linking-widget {
  &:hover {
    .cancel-linking {
      display: flex; } } }
.cemyun-block {
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 0;
  .leftSide {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    .corner {
      flex-shrink: 0;
      border: 0;
      border-bottom: 1px; }
    .rowHeader {
      flex: 1;
      display: flex;
      flex-direction: row;
      min-height: 0;
      .rowTitle {
        flex-shrink: 0;
        max-height: 100%;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        @extend .ellipsis;
        p {
          transform-origin: 0 center; } }
      .rowBody {
        flex-shrink: 0;
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        .pivot {
          tr {
            &:first-child {
              th {
                height: 36px!important; } }
            th {
              text-align: center; } } } } }
    .rowFooter {
      flex-shrink: 0;
      height: 50px; } }
  .rightSide {
    min-width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .columnTitle {
      max-width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 27px;
      @extend .ellipsis; }
    .columnHeader {
      flex-shrink: 0;
      overflow-x: auto;
      @extend .hideScrollbar;
      .pivot {
        th {
          border-left: 0;
          border-right: 0;
          padding: 0 6px; } } }
    .columnBody {
      flex: 1;
      overflow: auto;
      &.bodyCollapsed {
        flex: none; } }
    .columnFooter {
      flex-shrink: 0;
      height: 50px;
      overflow-x: auto; } }
  .rowFooter.hideFooter, .columnFooter.hideFooter {
    height: 0; }
  .xAxis {
    height: 100%;
    display: flex;
    flex-direction: row; }

  .yAxis {
    flex-shrink: 0;
    width: 64px; }

  .legend {
    flex-shrink: 0;
    max-width: 180px;
    margin-bottom: 50px;
    overflow-y: auto;
    display: none;
    &.shown {
      display: block; } }
  .ant-table-wrapper {
    margin-bottom: 0; } }
.widget-compare-info {
  color: #7a8493;
  display: flex;
  line-height: 24px;
  cursor: pointer;
  .widget-compare-text-content {
    display: flex;
    .widget-compare-text {
      font-size: 12px;
      &.widget-compare-fixed-text {
        color: #050A13; } }
    >div {
      margin-right: 8px; } }
  .iconfont {
    visibility: hidden; }
  &:hover {
    .iconfont {
      visibility: visible; } } }
.pivot {
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    &:nth-child(even) > td {
      background-color: fade(#000, 5); } }
  th {
    border: 0;
    line-height: 42px;
    p {
      max-width: 200px;
      margin: 0 auto;// 单th 100%
      line-height: 36px;
      @extend .ellipsis;
      &.colContent {
        height: 42px;
        line-height: 42px;
        text-align: right;
        padding: 0 6px; }
      &.hasAuxiliaryLine {
        position: relative;
        overflow: visible;
        .line {
          width: 64px;
          border: 0;
          border-top: 1px;
          position: absolute;
          top: -1px;
          left: 100%;
          z-index: 1; } } } }
  td {
    padding: 6px;
    line-height: 24px; }

  .cellContent {
    text-align: right;
    margin-bottom: 0; }
  .topBorder {
    border-top: 1px; }
  .bottomBorder {
    border-top: 1px; }
  .leftBorder {
    border-left: 1px; }
  .rightBorder {
    border-right: 1px; } }
.chartContainer {
  display: flex;
  flex-direction: column;
  .chartRow {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    .chartColumn {
      flex-shrink: 0; } } }
.columnHeader,
.rowBody,
.columnBody {
  &.raw {
    th, td {
      border-bottom: 1px;
      border-left: 0;
      border-top: 0;
      border-right: 0; } } }
.columnHeader {
  &.raw {
    position: relative;
    top: 1px; } }
.rowBody {
  &.raw {
    position: relative;
    left: 1px; } }
.legendBox {
  padding: 8px 0 8px 16px;
  h3 {
    padding-left: 6px;
    line-height: 2.5em;
    @extend .ellipsis; }
  .list {
    li {
      height: 2.25em;
      padding: 0 4px 0 28px;
      line-height: 2.25em;
      position: relative;
      cursor: pointer;
      @extend .ellipsis;
      &:hover {
        background-color: fade(#000, 5); }
      &.disabled {
        color: #D9D9DE !important;
        span {
          background-color: #D9D9DE !important; } }
      span {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        position: absolute;
        top: 6px;
        left: 6px; } } } }
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
.hideScrollbar {
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; } }
.widget-tooltip-circle {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-bottom: 2px;
  margin-right: 5px;
  border-radius: 50%; }
@media screen and(max-width: 768px) {
  .widget {
    flex: none;
    &.summary {
      height: 80px; }
    .ant-table-wrapper {
      th, td {
        font-size: 0.24rem!important; } } } }
