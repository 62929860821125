@import "../../static/sass/mixin";
@import "../../static/sass/variables";
.commonSummary {
    &.top-summary {
        margin-bottom: 8px; }
    &.clickable {
        .summary-col {
            cursor: pointer; } }
    &.analyseEvent,&.analyseSurvey {
        min-height: 57px;
        .summary-col {
            min-width: 25%; } }
    .summaryRow {
        overflow: hidden;
        &:hover {
            box-shadow: none; }
        .col {
            box-sizing: border-box;
            div {
                font-size: 28px;
                color: #050A13;
                font-weight: 400; }
            >span {
                font-size: $font-size-small; }

            >.spanMes {
                overflow: hidden;
                max-width: 200px;
                text-overflow: ellipsis;
                font-size: $font-size-small;
                color: #7A8493 !important;
                display: inline-block;
                white-space: nowrap;
                &:hover {
                    cursor: pointer; } } } }
    .summary-col {
        min-width: 120px;
        .ant-card {
            border: 1px solid #ffffff;
            &.selected-item {
                border: 1px solid #14c4a2; }
            .ant-card-body {
                    padding: 16px !important; } } }

    // 该summary为单卡片大指标数据的显示
    .summaryCard {
        .summary-col {
            padding: 0 4px;
            margin-bottom: 8px;
            .ant-card {
                border-radius: 0;

                &:hover {
                    box-shadow: 0 0 10px #dad5d5; } } } }


    .summaryTitle {
        &.analyseEvent {
            display: none; }
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px !important;
        color: #7A8493 !important;
        line-height: 16px;
        &:hover {
            cursor: pointer; } }
    .summary-content {
        display: flex;
        align-items: flex-end;
        .comparision {
            margin-right: 0;
            margin-left: 16px;
            margin-bottom: 3px; } }
    .summaryValue {
        font-size: 28px;
        font-weight: 400;
        color: #050A13;
        line-height: 36px;
        &:hover {
            cursor: pointer; }
        >span {
            width: auto;
            display: flex;
            align-items: baseline;
            .summary-item {
                font-size: 28px;
                color: rgba(5,10,19,1);
                opacity: 1; }
            .summary-unit {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: rgba(122,132,147,1); } } } }
@media screen and (max-width: 768px) {
    .commonSummary {
        .summaryCard {
            height: auto;
            padding: 0.1rem 0;
            .summary-col {
                min-width: 1.60rem;
                padding: 0.1rem;
                .ant-card-body {
                    padding: 0.3rem 0 0.34rem; } } }
        .summaryRow {
            overflow: hidden;
            .col {
                min-width: 0rem !important;
                padding: 0.3rem 0.05rem 0.3rem 0.09rem !important; } }
        .summaryTitle,.comparision,.summaryValue {
            line-height: initial; }
        .summaryTitle,.comparision {
            margin: 0; }
        .summaryValue {
            margin: 0; } } }
