
.analyseRemain_content {
    height: 100%; }

.eventAndFilterSelect {
    .ant-cascader-picker {
        margin-bottom: 8px; }
    .childrenFilter-empty {
        border-left: 1px solid #D9D9DE; }
    .childrenFilter {
        .childrenFilter_add {
            position: absolute;
            visibility: visible;
            top: -25px;
            left: 155px; } } }
