//自定义变量
//color
$blue-darker: #2a3141;
$blue-dark: #18243f;
$blue-base: #01a7ff;
$blue-lighter: #b5c2d4;
$blue-light: #25d0df;
$blue: #31b8ff;

// 主要字体颜色
$gray-dark: #050A13;
$gray-base: #5b616c;
$gray: #7a8493;
$gray-light: #b2b3b9;

//主色
$main-color: #14C4A2;

//
$gray-nav-item: #D9D9DE;
$gray-nav-item-hover-bg: #4A5465;
// $gray-base: #78818e
// $gray: #a8a8a8
// $gray-light: #e9ecee
$gray-lighter: #f3f3f4;
$gray-1: #f3f6fc;
$green-dark: #12a491;
$green-base: #1bb09d;
$green-light: #26e0ae;
$green-lighter: #6bf5ca;

//交--互--颜--色--规--范

// ?问号icon的交互规范
$question-icon-init: #7A8493;
$question-icon-hover: #38cbae;
$question-icon-active: #40ad98;

//绿色按钮规范

//按钮点下去的颜色
$green-active: #40ad98;
//按钮hover状态的颜色
$green-hover: #38cbae;
//按钮初始状态
$green-init: #14c4a2;

// 橙色按钮规范

//点下去的颜色
$orange-active: #eb7626;
// hover状态的颜色
$orange-hover: #ff9226;
// 按钮初始值
$orange-init: #ff7e26;

$red-base: #ff7ca7;

$orange-base: #ff7e26;

$border-color: #d9dde9;

//弹窗按钮的规范
// 确定按钮的规范与绿色按钮规范一样!!!!

// 取消按钮的规范
$delay-init: #545454;
$delay-init-border: #D9D9DE;
$delay-hover: $green-hover;
$delay-active: $green-init;

//base-layout-color
$body-bg-color: $gray-lighter;
$text-color: $gray-dark;

$navbar-top-bg-color: rgba(5,10,19,1);
$navbar-top-text-color: black;
$navbar-top-icon-color: white;

$sidebar-bg-color: white;
$sidebar-text-color: $blue-lighter;
$sidebar-text-active-color: $green-base;
$sidebar-active-item-text-color: $gray-base;
//pageheader里面的下拉菜单的初始颜色样式
$page-header-bg-color: #F3F3F4;
$page-header-text-color: #000000;
$page-header-filter-bg-color: #F3F3F4;
//pageheader里面的下拉菜单的操作时颜色样式
$page-header-filter-dropdown-list-text-color: #7A8493;
$page-header-filter-dropdown-list-text-hover-color: #050A13;
$page-header-filter-dropdown-list-text-hover-bg-color: #eff6fa;

$card-bg-color: #ffffff;
$card-title-text-color: $gray;

$sankey-node-static-color: #94c4ff;
$sankey-node-exit-color: #ffd39c;
$sankey-node-highlight-color: #2dcfbb;
$sankey-line-static-color: #ebf3f3;
$sankey-line-highlight-color: #94d0c8;
$sankey-node-current-highlight-color: #008f98;

//width & height
$sidebar-width: 160px; //sideBar展开的宽度
$tools-sidebar-width: 462px;
$page-header-height: 56px;
$page-header-filter-height: 32px;
$navbar-top-height: 56px;
$footer-height: 210px;

//font
$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
//主要字号
$font-size-small: 12px !default;
$font-size-base: 14px !default;
$font-size-large: 16px !default;
$font-size-xlarge: 28px !default;

$font-size-h3: 32px !default;
$font-size-h4: 16px !default;
$font-size-h5: 14px !default;
$font-size-h6: 12px !default;
//font-weight
$font-weight-base: 400;
$font-weight-large: 500;

//padding
$padding-md: 15px;

$navbar-top-padding: 16px 0 16px 0;
$navbar-top-header-padding: 21px 38px 21px 38px;
$navbar-top-collapse-nav-padding: 0 5px 0 18px;

$page-header-padding: 16px 20px 8px;

$page-content-padding: 0px 16px 0 16px;

$card-title-padding: 20px 20px 0px 20px;

$card-summary-padding: 10px 0;
$card-content-padding: 0 20px;
$card-padding-bottom: 20px;

//margin
$main-margin: 0 0 0 $sidebar-width;
$main-margin-lg: 0 0 0 $tools-sidebar-width;

$page-header-label-margin: 0 16px 0 0;
$page-header-filter-margin: 0 8px 0 0;

$page-content-margin: $navbar-top-height+$page-header-height 0 0 0;

$card-margin: 0 0 7px 7px;
$card-summary-icon-margin: 0 5px;
$card-summary-li-margin-bottom: 10px;
$card-summary-li-margin-right: 40px;

//button
$button-disabled-color: #D9D9DE;
$button-disabled-background: #e9ecee;
$button-background: $blue-darker;
$button-height: 27px;
$button-width: 84px;
$button-width-lg: 96px;
$button-width-sm: 71px;
$button-height-lg: 32px;
$button-height-sm: 23px;

//navbar-top
$navbar-top-icon-margin: 0 30px 0 0;
$navbar-top-collapse-filter-margin: 0 80px 0 0;
$navbar-top-height: 56px;

//input
$input-height: 35px; //减去上下border
$input-padding: 0 18px;

//switch
$switch-color: $orange-base;
$switch-width: 34px;
$switch-height: 20px;
$switch-round: 16px;
