@import "../../static/sass/mixin";
@import "../../static/sass/variables";

.uxs-chart {
    position: relative;
    .ant-select-sm .ant-select-selection__rendered {
        margin: 0 7px 0 0; }
    .chart-content {
        padding-top: 0;
        &.horizontal-style {
            // overflow-y: auto
 } }            // padding: 0 4px 16px 0px
    .data-block {
        margin-bottom: 16px;
        .chart-title {
            color: #050A13;
            font-size: 14px;
            font-weight: bold; } }
    .data-toggle {
        position: relative;
        margin-bottom: 16px;
        white-space: nowrap;
        line-height: 24px;
        .ant-select {
            font-weight: 400 !important; }
        .ant-select-selection {
            border: none;
            box-shadow: none; }
        .ant-select-selection-selected-value {
            color: #050A13 !important; }
        .ant-select-arrow-icon {
            color: #050A13 !important; } } }
.multipleChartItem {
    font-size: 12px;
    line-height: 20px; }
.data-toggle-active {
    display: inline-block;
    margin-left: 16px;
    color: $green-base;
    vertical-align: middle; }
.uxs-chart-dropdown {
    .ant-select-dropdown-menu {
        .ant-select-dropdown-menu-item-active {
            color: #1BB09D;
            background: #ffffff; }
        .ant-select-dropdown-menu-item-selected {
            background: #fff;
            color: #1BB09Dx; } } }
@media screen and ( max-width: 768px ) {
 .uxs-chart {
    .chart-content {
        &.horizontal-style {
            padding: 0; } } } }




