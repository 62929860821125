@import "./../../static/sass/variables";
@import "./../../static/sass/mixin";

.horizontal-card {
  overflow-x: auto; }
.card {
  width: 100%;
  margin-top: 8px;
  background-color: $card-bg-color;
  &:hover {
    box-shadow: 0px 0px 10px #DBDFE5;
    .card-tools {
      opacity: 0.2 !important; } }
  & .card-title {

    padding: 16px 20px 16px 20px !important;
    border-bottom: 1px solid #f3f3f4;
    font-size: $font-size-large;
    color: $gray;
    position: relative;
    & h5 {
      font-size: 16px;
      font-weight: 700;
      color: #050A13; }
    & .card-tools {
      text-align: right;
      position: relative;
      opacity: 0;
      i {
        padding-right: 12px;
        cursor: pointer;
        color: #c4c4c4;
        &:hover {
          color: #000; } }
      i:last-child {
        padding-right: 0; } }
    .collapse-tool {
      position: absolute;
      top: 16px;
      right: 20px; }
    .customBoard-card-title {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    .edit-card-title {
      display: flex;
      justify-content: space-between; } }
  & .collapsed-title {
     cursor: pointer; }
  & .line-content {
    padding: 0 !important;
    background-color: rgb(243, 243, 244);
    position: relative; }
  & .card-content {
    padding: 0 20px 20px 20px;
    width: 100%;
    height: auto;
    transition: .3s;
    & .sankey-chart-wrapper {
      padding-bottom: 0px; } }
  .card-content-collapsed {
    height: 0;
    overflow: hidden;
    padding-bottom: 0; }
  & .card-footer {
    border-color: #e7eaec;
    border-style: solid solid none;
    border-width: 1px 0 0;
    margin-bottom: 0;
    padding: 15px 15px 7px;
    min-height: 48px; }
  &.layout-card {
    margin-top: 72px;
    .card-content {
      padding-top: 16px; } }
  &.group-card {
    margin-top: 8px; }

  &.no-shadow {
    &:hover {
      box-shadow: none; } }
  &.old-layout-card {
    .card-content {
      padding-top: 16px; } } }
@media screen and (max-width: 768px) {
  .card {
    & .card-content {
      padding: 0rem 0.3rem 0.3rem 0.3rem; }
    .card-title {
      height: 1.08rem;
      padding: 0.32rem 0.4rem!important;
      h5 {
        font-size: 0.32rem; } } } }
