.AnalysePathStep {
    .AnalysePathStep-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: 0;
        margin-bottom: 8px;
        >div {
            height: 36px; }
        .AnalysePathStep-item-number {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            width: 18px;
            height: 18px;
            border: 1px solid #D9D9DE;
            color: #050A13;
            border-radius: 50%;
            text-align: center; }
        .AnalysePathStep-item-icon,.AnalysePathStep-item-plus {
            .black {
                color: #050A13; }
            .gray {
                color: #B2B3B9; }
            .green {
                color: #14C4A2; } }
        .AnalysePathStep-item-icon {
            margin-right: 5px;
            display: flex;
            align-items: center;
            i {
                margin-left: 5px; } }
        .AnalysePathStep-item-tag,.AnalysePathStep-item-tag-shadow {
            display: flex;
            align-items: center; }
        .AnalysePathStep-item-tag-skip {
            margin-left: 15px; }
        .AnalysePathStep-item-plus {
            display: flex;
            align-items: center;
            margin: 0px 5px;
            i {
                font-size: 12px; }
            .grey {
                color: #7A8493; } } }
    .AnalysePathStep-addButton {
        margin-top: 12px;
        padding: 0px;
        width: 72px;
        height: 24px;
        font-size: 12px;
        background-color: #fff;
        color: #14C4A2;
        border-radius: 4px;
        border: 1px solid #D9D9DE;
        &:hover {
            color: #38CBAE;
            border: 1px solid #38CBAE; }
        &:focus {
            color: #40AD98;
            border: 1px solid #40AD98; } } }
